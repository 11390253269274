<template>
    <div id="compare-pdf-page-99">
        <h1>COMPARE PDF FONT TEST</h1>
        <!-- // hack for pdf fonts. mercury does not work -->
        <!-- #compare-pdf-page { font-family: MercuryTextG1; } -->
        <!-- @font-face { font-family: MercuryTextG1; src:url(data:font/otf;base64,{{ mercuryOtfB64 }}); } -->
        <component :is="'style'">
            #compare-pdf-page-99 { font-family: BrandonGrotesque; } .mercury {
            font-family: MercuryTextG1; font-weight:normal; }
        </component>

        <div style="font-size: 40px; padding-top: 100px">
            Default regular <b>bold</b> <i>italic</i>
            <b><i>BoldItalic</i></b> dot.bang!colon:comma,etc...
            numbers1234567890poo
            <!-- <div style="font-family: BrandonGrotesque">
                Brandon regular <b>bold</b> <i>italic</i> <b><i>BoldItalic</i></b>
            </div> -->
            <div class="georgia">
                Georgia regular <b>bold</b> <i>italic</i>
                <b><i>BoldItalic</i></b>[dot.bang!colon:dash-comma,etc...numbers1234567890poo]<br />
                reg&reg;copy&copy;trade&trade;superscriptreg<sup>&reg;</sup>copy<sup>&copy;</sup>trade<sup>&trade;</sup>
            </div>
            <div class="times">
                Times regular <b>bold</b> <i>italic</i>
                <b><i>BoldItalic</i></b>[dot.bang!colon:dash-comma,etc...numbers1234567890poo]<br />
                reg&reg;copy&copy;trade&trade;superscriptreg<sup>&reg;</sup>copy<sup>&copy;</sup>trade<sup>&trade;</sup>
            </div>
        </div>
    </div>
</template>

<script>
import mercuryOtfB64 from "@/assets/fonts/b64.js/MercuryTextG1Roman.otf.b64";

export default {
    name: "ComparePDFPage99",
    data() {
        return {
            // mercuryOtfB64,
            mercuryOtfB64: mercuryOtfB64.replace(/\n/g, ""),
        };
    },
};
</script>
<style lang="scss">
#compare-pdf-page-99 {
    .georgia {
        font-family: Georgia;
    }
    .times {
        font-family: Times-Roman;
    }
}
</style>
