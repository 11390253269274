export default {
    shouldProvideLocationRationaleCallbackVar: null,
    checkLocationPermissionsCallbackVar: null,
    /**
     * Function to check if a rationale should be provided to the user prior to requesting permissions; e.g. if they previously declined perms.  This allows you to show something custom on your screen before the un-changeable location request popup appears.
     * @method shouldProvideLocationRationale
     * @param callBack {Function} callBack - (required), the call back function to execute; should only accept one argument which will be a boolean
     */
    shouldProvideLocationRationale(callBack) {
        this.shouldProvideLocationRationaleCallbackVar = callBack;
        if (this.isiOS) {
            window.webkit.messageHandlers.shouldProvideLocationRationale.postMessage(
                ""
            );
        } else if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.shouldProvideLocationRationale();
        }
    },

    /**
     * Function to dispatch call back
     */
    shouldProvideLocationRationaleCallback(response) {
        this.shouldProvideLocationRationaleCallbackVar(response);
        this.shouldProvideLocationRationaleCallbackVar = null;
    },

    /**
     * Function to check if Location permissions have been granted to this app
     * @method checkLocationPermissions
     * @param callBack {Function} callBack - (required), the call back function to execute; should only accept one argument which will be a boolean
     */
    checkLocationPermissions(callBack) {
        this.checkLocationPermissionsCallbackVar = callBack;
        this.genericCallbackHolder = callBack ? callBack : null;
        if (this.isiOS) {
            window.webkit.messageHandlers.checkLocationPermissions.postMessage(
                ""
            );
        } else if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.checkLocationPermissions();
        }
    },

    /**
     * Function to dispatch call back
     */
    checkLocationPermissionsCallback(response) {
        this.checkLocationPermissionsCallbackVar(response);
        this.checkLocationPermissionsCallbackVar = null;
    },

    /**
     * Function to ask if location is enabled on phone. This is different
     *  from asking if permissions are set. This is about global location settings
     *  @param callback -- Callback that receives true or false
     */
    isLocationEnabled(callback) {
        this.isLocationCallback = callback;
        if (this.isiOS) {
            window.webkit.messageHandlers.isLocationEnabled.postMessage("");
        } else if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.isLocationEnabled();
        }
    },
    /**
     * Function to dispatch callback
     */
    isLocationEnabledCallback(response) {
        if (this.isLocationCallback != null) {
            this.isLocationCallback(response);
        }
        this.isLocationCallback = null;
    },

    /**
     * Function to request that location permissions are granted to this app
     * @method requestLocationPermissions
     * Update: Optional callback function to allow dev to work with permission responses
     */
    requestLocationPermissions(callback) {
        this.permissionCallBack = callback;
        if (this.isiOS) {
            window.webkit.messageHandlers.requestLocationPermissions.postMessage(
                ""
            );
        } else if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.requestLocationPermissions();
        }
    },

    requestLocationPermissionsCallback(response) {
        if (this.permissionCallBack != null) {
            this.permissionCallBack(response);
        }
        this.permissionCallBack = null;
    },
    /**
     * Function to get the current latitude and longitude in to the app
     * Returns a two value array containing current Latitude and Longitude [lat, long]
     */
    getLatLong(CallBack) {
        this.llCallBack = CallBack;
        this.genericCallbackHolder = CallBack ? CallBack : null;
        if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.getLatLong();
        } else if (this.isiOS) {
            window.webkit.messageHandlers.getLatLong.postMessage("");
        }
    },

    latlongreturned(result) {
        var locationArray = result.split(",");
        this.log(locationArray[0]);
        if (this.llCallBack) {
            this.llCallBack(locationArray);
        }
    },
    /**
     * Function to open the default iOS directions view for locations
     * @method getDirections
     * @param lat {Number} lat - (required), latitude of the destination
     * @param lon {Number} lon - (required), longitude of the destination
     */
    getDirections(lat, lon) {
        if (this.isiOS) {
            window.webkit.messageHandlers.getdirections.postMessage({
                lat: lat,
                lon: lon,
            });
            //window.location = "getdirections://foo/bar?sid="+Math.random()+"&lat="+this.Base64.encode(lat+"")+"&lon="+this.Base64.encode(lon+"");
        }
    },
};
