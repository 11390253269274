// ScreenshotHelper.js
import html2canvas from "html2canvas";
import { injectFont } from "@/helpers/PdfSvgFontHelper";

// image of current page rendered in specified resolution
// returns data url
// data-html2canvas-ignore attribute excludes elements from rendering
export async function screenshot(width, height, scale=2) {
    // noscript tag renders so remove it.
    var noscript = document.getElementsByTagName("noscript")[0];
    if (noscript) noscript.remove();

    injectFont(document.querySelector("svg.graph"));
    
    var options = {
        windowWidth: width,
        windowHeight: height,
        width: width,
        height: height,
        scale: scale, // default is 2 when unspecified
        useCORS: true,
    };
    // html2canvas does not do img tag src by css "content:".  must set <img src= /> in html
    const canvas = await html2canvas(document.body, options);
    // iOS requires no svg references in css for this to work
    return canvas.toDataURL("image/png", 1.0);
    // either move them to html img src or convert to raster
    // for the latter used rsvg-convert and pngcrush to create pngs at 4x the svg default res
    // eg rsvg-convert -w 400 image.svg > image.png
    // pngcrush infile outfile
}