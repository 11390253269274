<template>
    <div id="compare-pdf-view" class="modal"></div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
    methods: {
        ...mapActions(["generatePdf"]),
    },
    computed: {
        ...mapState(["compareWireIds"]),
        ...mapGetters(["compareCount", "getResourcesById"]),
    },
    mounted() {
        this.generatePdf();
    },
};
</script>

<style lang="scss">
#compare-pdf-view {
    background-color: white;
}
</style>
