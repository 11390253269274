<template>
    <div id="modal-menu-user">
        <div class="user">
            <div class="user-name">{{ user.name }}</div>
            <div class="user-type">{{ user.type }}</div>
        </div>
        <button v-if="hasWires" @click="modalReviewOpen">REVIEW OPTIONS</button>
        <button @click="logout">
            LOG OUT
            <img class="logout-icon" src="@/assets/images/Icon_Logout.svg" />
        </button>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
    methods: {
        ...mapActions(["logout", "modalReviewOpen"]),
    },
    computed: {
        ...mapState(["user"]),
        ...mapGetters(["hasWires"]),
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
#modal-menu-user {
    margin: vw(20px);
    border: vw(1px) solid $table-lines;
    border-radius: vw(5px);
    background-color: $faint-grey;
    padding: vw(10px);
    display: flex;
    flex-direction: column;
    @include gap-compat-1(vw(5px), column);
    .user {
        padding: vw(10px);
        margin-bottom: vw(5px);
        display: flex;
        flex-direction: column;
        @include gap-compat-1(vw(2px), column);
        .user-name {
            color: $abbott-dark-grey;
            // todo medium weight
            // font: normal normal medium vw(16px) BrandonGrotesque;
            font: normal normal bold vw(16px) BrandonGrotesque;
            line-height: vw(23px);
        }
        .user-type {
            color: $abbott-blue;
            font: normal normal bold vw(14px) Calibri;
            line-height: vw(17px);
        }
    }
    img.logout-icon {
        width: vw(15px);
    }
    button {
        border: vw(1px) solid #f2f2f2;
        border-radius: vw(2px);
        background-color: white;
        color: $dark-grey;
        height: vw(40px);
        text-align: left;
        font: normal normal bold vw(11px) BrandonGrotesque;
        line-height: vw(12px);
        padding-left: vw(15px);
        padding-right: vw(10px);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
</style>
