import { createStore } from "vuex";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import tbaWrapper from "@/helpers/tbaWrapper";
import pdf from "./modules/pdf";

// todo modularize
export default createStore({
    modules: { pdf },
    state: {
        revision: null,
        isReviewApp: null,
        environment: null,
        isMenuOpen: false,
        isReviewOpen: false,
        // todo change to getter !state.user
        isLoggedIn: false, // could be changed to existence of user
        currentRegionId: 1, // US -- old api
        currentRegion: "US",
        // currentWireType: "coronary", // old category
        // todo remove
        currentBrand: "other", // old maker
        // todo make these getters by currentMat
        currentMaker: "Abbott",
        currentCategory: "Coronary",
        currentStatusViewing: "Published",
        // prettier-ignore todo remove if not used
        // options: {
        //     mode: "normal",
        //     region: { id: "1", name: "USA" },
        //     language: { id: "1", name: "ENG" },
        // },
        apiData: null,
        apiDataRegion: null, // clear comparison when fetch different region.
        compareWireIds: [], // up to 8 wire ids
        comparisonById: {}, // {wireId:{isShowing, colorIndex:[1..8]}
        comparisonHighlightId: 0,
        comparisonSortAscending: true,
        // used for current view and during pdf creation. could be split to avoid having to save/restore
        comparisonProperty: "tipLoad",
        isSyncing: false,
        currentSection: "", // BottomBar: products,compare,resources
        modalConfirm: {
            isVisible: false,
            title: null,
            message: null,
            okButton: null,
            okBtnStyle: "",
            cancelButton: null,
            // probably would not work https://forum.vuejs.org/t/how-to-manage-objects-not-just-data/105148
            // resolvePromise: null,
        },
        popupInfoBar: {
            isVisible: false,
            message: null,
            type: "success",
            timeoutId: 0,
        },
        testComponentIsOpen: false,
        testComponentIsShowing: false,
        user: false, // logged in user info
        currentRoute: {
            fullPath: "/lobby",
            meta: {
                template: "Lobby",
                templateHasNoRegion: true,
            },
        },
        isAuthenticating: false,
        isRecordingSession: false,
        // recordedSession: [], // not storing everything.  only tally stats
        sessionWiresById: {}, // {`${id}`: {select wire info}}
        sessionWireIdViewCount: {}, // {id: count} -- incremented on product page views (product,graph)
        sessionWireIdCompareCount: {}, // {id: count} -- incremented on comparison page views
        sessionUniqueComparisonCount: {}, // {"sorted json array": count} -- incremented on comparison page views
        // these two are used by getFileBasePath getter.
        isWeb: !tbaWrapper.platform,
        localFileBasePath: null, // gotten async from tbaWrapper.
        isReviewingMat: false,
        isGAEnabled: false,
    },
    getters,
    mutations,
    actions,
});
