<template>
    <!-- todo change style to match now that it fits in 16:9 aspect ratio -->
    <div id="modal-review-mat-select" class="scroll-y" v-dragscroll="$isWeb">
        <template v-if="haveContentTypeMATs">
            <label class="mat-option">
                <div class="mat"><b>Select All</b></div>
                <input
                    type="checkbox"
                    value="all"
                    v-model="allSelected"
                    @change="setOrClearAll"
                    :disabled="isDisabled"
                />
                <!-- perhaps @change should be @input ? -->
                <div class="checkbox" :class="{ disabled: isDisabled }"></div>
            </label>
            <template v-for="(mats, type) in contentTypeMATs" :key="type">
                <div
                    class="content-type"
                    v-text="contentTypes[type].label"
                ></div>
                <label class="mat-option" v-for="(mat, i) in mats" :key="i">
                    <div class="mat status" :class="mat.statusClass">
                        <div v-if="mat.subLabel" class="sub-label">
                            {{ mat.subLabel }}
                        </div>
                        <div class="mat-number">
                            {{ mat.mat }}
                        </div>
                    </div>
                    <input
                        type="checkbox"
                        :value="mat.id"
                        v-model="selectedMatIds"
                        :disabled="isDisabled"
                    />
                    <div
                        class="checkbox"
                        :class="{ disabled: isDisabled }"
                    ></div>
                </label>
            </template>
        </template>
        <label v-else class="mat-option">
            <div class="mat">No MAT Numbers</div>
        </label>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import util from "@/helpers/util";
import { CONTENT_TYPES} from "@/constants";
export default {
    props: [
        "status",
        // when no v-model given, all are selected and disabled
        "modelValue",
    ],
    emits: ["update:modelValue"],
    data() {
        return {
            allSelected: false,
            contentTypes: CONTENT_TYPES,
            // contentTypes: {
            //     lobby: {
            //         label: "Lobby",
            //         matCategories: ["Lobby"],
            //     },
            //     coronary: {
            //         label: "Coronary Pages",
            //         subLabel: "maker",
            //         matCategories: ["Coronary"],
            //     },
            //     peripheral: {
            //         label: "Peripheral Pages",
            //         subLabel: "maker",
            //         matCategories: ["Peripheral"],
            //     },
            //     landingOther: {
            //         label: "Landing & Other Pages",
            //         subLabel: "category",
            //         matCategories: ["Landing", "Compare", "Resource"],
            //     },
            //     comparisonPrintout: {
            //         label: "Comparison Printout",
            //         matCategories: ["Compare PDF"],
            //     },
            //     // *** if other categories exist they won't be shown currently
            // },
            // hardcoded sample of dynamic stuff.  Now includes id.
            // contentTypeMATs: {
            //     lobby: [{ mat: "MAT-NEW1 v2.0" }],
            //     coronary: [
            //         { subLabel: "ABBOTT", mat: "MAT-2100532 v2.0" },
            //         { subLabel: "OTHER", mat: "MAT-2100533 v2.0" },
            //     ],
            //     peripheral: [
            //         { subLabel: "ABBOTT", mat: "MAT-2100175 v2.0" },
            //         { subLabel: "OTHER", mat: "MAT-2100176 v2.0" },
            //     ],
            //     landingOther: [
            //         { subLabel: "LANDING", mat: "MAT-2100111 v2.0" },
            //         { subLabel: "COMPARE", mat: "MAT-2100112 v2.0" },
            //         { subLabel: "RESOURCES", mat: "MAT-2100113 v2.0" },
            //     ],
            //     comparisonPrintout: [{ mat: "MAT-2011091 v2.0" }],
            // },
            // selectedMatIds:[],
        };
    },
    methods: {
        setOrClearAll() {
            console.log("setOrClearAll()");
            // called when input changes not when allSelected changes
            this.selectedMatIds = this.allSelected
                ? this.currentMatIdOptions
                : [];
        },
    },
    computed: {
        ...mapState(["currentRegion", "apiData"]),
        ...mapGetters(["getCurrentRegionMATs"]),
        isDisabled() {
            return !this.modelValue;
        },
        // Two way binding with v-model prop.
        // All selected when none provided
        selectedMatIds: {
            get() {
                // nothing given select all
                return this.modelValue ?? this.currentMatIdOptions;
            },
            set(ids) {
                if (this.isDisabled) return; // nothing to set
                this.allSelected =
                    util.arrayDiff(this.currentMatIdOptions, ids).length == 0;
                this.$emit("update:modelValue", ids);
            },
        },
        currentMatIdOptions() {
            let ret = [];
            // not from getCurrentRegionMATs because there could be more that aren't shown
            Object.values(this.contentTypeMATs).forEach((mats) => {
                mats.forEach((m) => ret.push(m.id));
            });
            return ret;
        },
        contentTypeMATs() {
            const ret = {};
            var mats;
            for (const [type, meta] of Object.entries(this.contentTypes)) {
                mats = [];
                for (const category of meta.matCategories) {
                    mats = [
                        ...mats,
                        ...this.getCurrentRegionMATs
                            .filter(
                                (m) =>
                                    m.category == category &&
                                    (this.status == "ALL" ||
                                        m.status == this.status)
                            )
                            .map((m) => ({
                                subLabel: this.contentTypes[type].subLabel
                                    ? m[this.contentTypes[type].subLabel]
                                    : undefined,
                                mat: `${m.code} v${m.version.toFixed(1)}`,
                                statusClass:
                                    m.status
                                        ?.replace(/\s+/g, "-")
                                        .toLowerCase() ?? "",
                                id: m.id,
                            })),
                    ];
                }
                ret[type] = mats;
            }
            // remove empty categories
            Object.keys(ret).forEach((k) => ret[k].length || delete ret[k]);
            return ret;
        },
        haveContentTypeMATs() {
            return Object.keys(this.contentTypeMATs).length > 0;
        },
    },
    beforeMount() {
        this.allSelected =
            util.arrayDiff(this.currentMatIdOptions, this.selectedMatIds)
                .length == 0;
    },
    watch: {
        currentMatIdOptions(options) {
            this.selectedMatIds = util.arrayIntersect(
                this.selectedMatIds,
                options
            );
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
#modal-review-mat-select {
    border: vw(1px) solid $light-grey;
    .content-type {
        background-color: #053f88;
        color: white;
        font: normal normal bold vw(12px) BrandonGrotesque;
        line-height: vw(25px);
        padding-left: vw(20px);
        text-transform: uppercase;
        border-bottom: vw(1px) solid $light-grey;
    }
    .mat-option {
        height: vw(50px);
        border-bottom: vw(1px) solid $light-grey;
        padding-left: vw(20px);
        padding-right: vw(15px);
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:last-of-type {
            border-bottom: none;
        }
        .mat {
            height: 100%;
            display: flex;
            font-size: vw(16px);
            align-content: flex-start;
            justify-content: center;
            flex-direction: column;
            position: relative;
            &.status {
                padding-left: vw(18px);
                &::before {
                    // dot
                    @include round(vw(8px));
                    display: block;
                    background-color: grey;
                    content: "\200b";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &.published::before {
                    background-color: $color-success;
                }
                &.for-review::before {
                    background-color: $color-alert;
                }
            }

            .sub-label {
                font-size: vw(12px);
                font-weight: bold;
                text-transform: uppercase;
            }
            .mat-number {
                font-size: (vw(16px));
            }
        }
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
        input:checked ~ .checkbox {
            background-image: url(@/assets/images/Checkbox_Blue.svg);
        }
        input:checked:disabled ~ .checkbox {
            background-image: url(@/assets/images/Checkbox_LightGrey.svg);
        }
        .checkbox {
            width: vw(30px);
            height: vw(30px);
            border: vw(1px) solid $lightblue-border;
            background-image: none;
            background-repeat: no-repeat;
            background-position: center;
            background-size: vw(16px);
            &.disabled {
                border: vw(1px) solid $light-grey;
            }
        }
    }
}
</style>
