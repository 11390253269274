<template>
    <transition name="fade">
        <div
            class="modal"
            v-if="modalConfirm.isVisible"
            @click.self="modalConfirmResolve(false)"
        >
            <div class="popup">
                <h2 class="title">{{ modalConfirm.title }}</h2>
                <p class="message">{{ modalConfirm.message }}</p>
                <hr />
                <div class="btns">
                    <button class="ok" @click="modalConfirmResolve(true)" :style="modalConfirm.okBtnStyle??''">
                        {{ modalConfirm.okButton }}
                    </button>
                    <button class="cancel" @click="modalConfirmResolve(false)">
                        {{ modalConfirm.cancelButton }}
                    </button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
    computed: mapState(["modalConfirm"]),
    methods: mapActions(["modalConfirmResolve"]),
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/base/variables";
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
.modal {
    display: flex;
    align-items: center;
    justify-content: center;
}
.popup {
    background: #fff;
    border-radius: vw(5px);
    box-shadow: vw(2px) vw(4px) vw(8px) rgba(0, 0, 0, 0.2);
    text-align: center;
    width: vw(470px);
    display: flex;
    flex-direction: column;
    @include gap-compat-1(vw(20px), column);
}

.title {
    font: normal normal bold vw(24px) BrandonGrotesque;
    line-height: vw(34px);
    padding: vw(40px) vw(20px) 0 vw(20px);
}
.message {
    font: normal normal normal vw(16px) MercuryTextG1;
    line-height: vw(20px);
    padding: 0 vw(40px);
}
hr {
    margin: 0;
    border: vw(1px) solid #f2f2f2;
}
.btns {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 vw(20px) vw(20px);
    @include gap-compat-1(vw(20px));
    button{
        padding: 0 vw(36px);
    }
    .ok-btn {
        color: red;
        text-decoration: underline;
        line-height: 2.5rem;
        cursor: pointer;
    }
    .cancel-btn {
        background-color: $color-alert;
        color: white;
        border: vw(2px) solid #0ec5a4;
        border-radius: vw(5px);
        font-weight: bold;
        font-size: vw(16px);
        text-transform: uppercase;
        cursor: pointer;
    }
}
button {
    cursor: pointer;
    height: vw(45px);
    min-width: vw(130px);
    font: normal normal bold vw(14px) BrandonGrotesque;
    &.ok {
        border: none;
        background-color: $color-alert;
        color: white;
    }
    &.cancel {
        border: vw(2px) solid $light-grey;
        color: $abbott-dark-grey;
    }
}
</style>
