<template>
    <div class="compare-pdf-graph-legend">
        <div
            v-for="wire in wires"
            v-show="isShowing(wire.id)"
            :key="wire.id"
            class="item"
            @click="setHighlight(wire.id)"
            :class="{ highlight: isHighlighted(wire.id) }"
        >
            <div :class="`color-square bgcolor-${colorIndex(wire.id)}`"></div>
            <div class="name" v-html="wire.name"></div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
    computed: {
        ...mapState(["comparisonById", "comparisonHighlightId"]),
        ...mapGetters({
            wires: "comparisonWires",
        }),
    },
    methods: {
        ...mapMutations([
            "comparisonIsShowingToggle",
            "comparisonHighlightIdSet",
        ]),
        isShowing(id) {
            return this.comparisonById[id].isShowing;
        },
        colorIndex(id) {
            return this.comparisonById[id].colorIndex;
        },
        isHighlighted(id) {
            return this.comparisonHighlightId == id;
        },
        setHighlight(id) {
            this.comparisonHighlightIdSet(this.isHighlighted(id) ? 0 : id);
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
.compare-pdf-graph-legend {
    background-color: #f9f9f9;
    color: $abbott-dark-grey;
    border: vwp(1px) solid #dfdfdf;
    border-radius: vwp(10px);
    padding: vwp(15.1728px) vwp(20.5584px);
    width: 91.6vw;
    display: flex;
    flex-wrap: wrap;
    // row-gap: vw(17px);
    @include gap-compat-n(3, 0, vwp(20px));
    h3 {
        font: normal normal bold vw(11px) BrandonGrotesque;
        line-height: vw(16px);
    }
    hr {
        border: vw(1px) solid $table-lines;
        margin-top: vw(8px);
        margin-bottom: vw(6px);
    }
    .item {
        flex: 0 0 31.3%;
        // min-height: vw(40px);
        min-height: vw(34.73px);
        display: flex;
        @include gap-compat-1(vwp(12.5693px));
        align-items: center;
        &.highlight {
            background-color: #efefef;
            border-radius: vw(5px);
        }
    }
    .color-square {
        flex: 0 0 auto;
        @include round(vwp(8px));
    }
    .name {
        font-size: vw(12px);
        line-height: vw(14px);
        // padding-right: vw(7px);
    }
}
</style>
