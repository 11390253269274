<template>
    <div class="product-view">
        <header>
            <hr class="gradient" :class="gradientClass" />
            <div
                class="heading-brandtype-segment"
                v-html="headingBrandTypeSegment"
            ></div>
            <h1>
                <img
                    class="back-link"
                    @click="$router.push(backPath)"
                    src="@/assets/images/Icon_Arrow_Back.svg"
                />
                <span v-html="wire.name"></span>
            </h1>

            <div class="buttons">
                <button class="compare">
                    <label
                        >{{ isSelected ? "REMOVE FROM" : "ADD TO" }}<br />
                        COMPARE LIST</label
                    >
                    <div
                        class="btn add"
                        :class="{ selected: isSelected }"
                        @click="toggleCompare"
                    ></div>
                </button>
                <!-- <button class="pdf"></button> -->
            </div>
        </header>
        <div class="spacer"></div>
        <main v-dragscroll="$isWeb" class="screenshot-expand scroll-y">
            <div class="image-area">
                <div class="left">
                    <div class="heading">IMAGE</div>
                    <hr class="gradient" :class="gradientClass" />
                </div>
                <div class="wire-frame">
                    <img
                        class="wire"
                        :src="imageSrc"
                        alt=""
                        onerror="this.onerror=null; this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='"
                    />
                    <!-- <button @click="download(imageSrc)">DOWNLOAD</button> -->
                </div>
            </div>
            <div class="sides">
                <div class="attributes">
                    <div class="heading">ATTRIBUTES</div>
                    <hr class="gradient" :class="gradientClass" />
                    <template
                        v-for="(value, label) in formattedAttributes"
                        :key="label"
                    >
                        <h3>{{ label }}</h3>
                        <p v-html="value"></p>
                    </template>
                </div>
                <div class="device-support-level">
                    <div class="heading">DEVICE SUPPORT LEVEL</div>
                    <hr class="gradient" :class="gradientClass" />
                    <button
                        v-if="hasGraph"
                        class="enlarge"
                        @click="$router.push(`/graph/${wireId}`)"
                    ></button>
                    <div class="graph-area">
                        <WireGraph
                            :wires="[wire]"
                            width="568"
                            height="380"
                            v-if="hasGraph"
                        />
                        <h2 v-else>Not Available</h2>
                    </div>
                </div>
            </div>
            <div
                v-if="wire.disclaimer"
                class="disclaimer"
                v-html="wire.disclaimer"
            ></div>
            <div
                class="footnote"
                v-html="getFooter"
                @click.prevent="$root.linkNative($event)"
            ></div>
            <div class="similar-wires" v-if="similarWires.length">
                <div class="heading">SIMILAR WIRES</div>
                <div v-dragscroll="$isWeb" class="tiles scroll-x">
                    <WireTile
                        v-for="wire in similarWires"
                        :key="wire.id"
                        :guideWire="wire"
                        :similar="true"
                    />
                </div>
            </div>
        </main>
        <div class="main-scrollfade"></div>
    </div>
</template>

<script>
import WireTile from "@/components/WireTile.vue";
import WireGraph from "@/components/WireGraph.vue";
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import { OLD_API, WIRE_PROPERTY_UNITS } from "@/constants";
import util from "@/helpers/util.js";
import tbaWrapper from "@/helpers/tbaWrapper";
import strtolower from "locutus/php/strings/strtolower";
import basename from "locutus/php/filesystem/basename";
import * as file from "@/helpers/FileHelper";

export default {
    components: { WireTile, WireGraph },
    props: ["wireId"],
    data() {
        return {
            brandType: "Brand Type",
            segment: "Segment",
            type: "coronary", // unused
        };
    },
    computed: {
        ...mapGetters(["guideWiresById", "brandsById", "getFooter"]),
        ...mapState(["compareWireIds", "currentMaker", "currentCategory"]),
        wire() {
            return this.guideWiresById[this.wireId];
        },
        imageSrc() {
            return file.wireImgSrc(this.wire);
        },
        isSelected() {
            return this.compareWireIds.includes(parseInt(this.wireId));
        },
        wireSegment() {
            // No need to alter apiData / waste space by adding segment to all wires.
            return OLD_API
                ? this.wire.segment
                : this.brandsById[this.wire.brand_id].name;
        },
        gradientClass() {
            return strtolower(this.wire?.category ?? "");
        },
        /*
        isEmptyGraph() {
            return Array.isArray(this.wire.graph);
        },
        */
        similarWires() {
            let ids = this.wire.related_guidewires;
            // let ids = this.getTestWireIds(); // todo disable when done testing
            const ret = [];
            var w;
            for (const id of ids)
                if ((w = this.guideWiresById[id])) ret.push(w);
            return ret;
        },
        hasGraph() {
            return !!this.wire.graph && !!Object.keys(this.wire.graph).length;
        },
        formattedAttributes() {
            return {
                "Core Material": this.notAvailable(this.wire.core_material),
                Cover: this.notAvailable(this.wire.cover),
                "Distal Coating": this.notAvailable(this.wire.coating),
                "Tip Load": this.propWithUnit("tip_load"),
                Radiopacity: this.notAvailable(this.wire.radiopacity),
                "tip diameter": this.propWithUnit("tip_diameter"),
                "tip style": this.notAvailable(this.wire.tip_style),
                "penetration power": this.propWithUnit("penetration_power"),
            };
        },
        // productFooter() {
        //     return (
        //         this.getFooter +
        //         (this.wire.disclaimer
        //             ? `<br /><br />${this.wire.disclaimer}`
        //             : "")
        //     );
        // },
        listPath() {
            if (!this.wire) return "";
            let category = this.wire.category?.toLowerCase() ?? "";
            let maker = this.wire.maker ?? "";
            if (!category || !maker) return "";
            return `/products/${category}/${maker}`;
        },
        backPath() {
            // AVGW-119 history might not exist
            return this.listPath ? this.listPath : "/product-types";
        },
        headingBrandTypeSegment() {
            return `${this.wire.maker} ${this.wire.category} / ${this.wireSegment}`;
        },
    },
    methods: {
        ...mapGetters(["getTestWireIds"]),
        ...mapMutations([
            "setCurrentSection",
            "setCurrentMaker",
            "setCurrentCategory",
        ]),
        ...mapActions(["compareWireIdsPush", "compareWireIdsRemove"]),
        strtolower,
        toggleCompare() {
            let action = this.isSelected
                ? "compareWireIdsRemove"
                : "compareWireIdsPush";
            this.$store.dispatch(action, this.wireId);
        },
        download(url) {
            util.downloadBase64(url, (b64Data) => {
                let filename = basename(url);
                tbaWrapper.filePutContents(filename, b64Data, this.dldone);
            });
        },
        dldone(error) {
            if (error) console.log(error);
            else console.log("downloaded OK");
        },
        init() {
            this.setCurrentMaker(this.wire.maker);
            this.setCurrentCategory(this.wire.category);
        },
        notAvailable(value) {
            return value ? value : "Not Available";
        },
        propWithUnit(property) {
            return this.wire[property]
                ? this.wire[property] + WIRE_PROPERTY_UNITS[property]
                : "Not Avaliable";
        },
    },
    mounted() {
        this.setCurrentSection("products");
        this.init();
    },
    watch: {
        wireId() {
            this.init();
            document.querySelector("main").scrollTop = 0;
        },
    },
};
</script>

<style></style>
