<template>
    <!-- SSO return url.  -->
    <div id="sso-view"></div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {};
    },
    methods: {
        ...mapActions(["login", "popupInfoBar"]),
        jwtPayload(jwt) {
            if (!jwt) return;
            // eslint-disable-next-line no-unused-vars
            const [_header, payload64, _sig] = jwt.split(".");
            if (!payload64) return;
            const payloadJson = window.tbaWrapper.Base64.decode(
                payload64.replace(/-/g, "+").replace(/_/g, "/")
            );
            if (!payloadJson) return;
            try {
                var payload = JSON.parse(payloadJson);
            } catch (e) {
                console.error("invalid jwt", e);
            }
            return payload;
        },
        formatName(name) {
            const [last, first] = name.split(",").map((s) => s.trim());
            return `${first} ${last}`;
        },
        userFromQuery(query) {
            let idtoken = query.idtoken ?? "";
            let p = this.jwtPayload(idtoken);
            return {
                idtoken,
                name: p?.name ? this.formatName(p.name) : undefined,
                type: query.user_type ?? "",
            };
        },
    },
    created() {
        // inputs from query in hash route
        let ssoResult = this.$route.query.result ?? "";
        let ssoError = this.$route.query.error ?? "";
        console.log("this.$route.query", this.$route.query);
        switch (ssoResult + ssoError) {
            case "authorized":
                this.login(this.userFromQuery(this.$route.query));
                break;
            case "noauth":
                console.log("sso NOT Authorized");
                this.popupInfoBar({
                    type: "warning",
                    message:
                        "Your SSO login does not currently have any Guide Wire Data Center admin or review privileges",
                });
                this.$router.push("/lobby");
                break;
            case "cancelled":
                // todo put back where they were. need to restore from localStorage because redirected back in non-ios case
                this.$router.push("/lobby");
                break;
            default:
                this.popupInfoBar({
                    type: "warning",
                    message: `Login Error: ${ssoResult + ssoError}`,
                });
                this.$router.push("/lobby");
                break;
        }
    },
};
</script>
