<template>
    <div id="modal-review-mat-radios" class="scroll-y" v-dragscroll="$isWeb">
        <template v-if="haveContentTypeMATs">
            <template v-for="(mats, type) in contentTypeMATs" :key="type">
                <div
                    class="content-type"
                    v-text="contentTypes[type].label"
                ></div>
                <label class="mat-option" v-for="(mat, i) in mats" :key="i">
                    <div class="mat status" :class="mat.statusClass">
                        <div v-if="mat.subLabel" class="sub-label">
                            {{ mat.subLabel }}
                        </div>
                        <div class="mat-number">
                            {{ mat.mat }}
                        </div>
                    </div>
                    <AppRadio
                        v-show="status == 'For Review'"
                        :value="String(mat.id)"
                        v-model="selectedMatId"
                    />
                </label>
            </template>
        </template>
        <label v-else class="mat-option">
            <div class="mat">No MAT Numbers</div>
        </label>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { CONTENT_TYPES } from "@/constants";
import AppRadio from "./AppRadio.vue";
export default {
    components: { AppRadio },
    props: [
        "status",
        // when no v-model given, all are selected and disabled
        "modelValue",
    ],
    emits: ["update:modelValue"],
    data() {
        return {
            allSelected: false,
            contentTypes: CONTENT_TYPES,
            test: "0",
        };
    },
    methods: {},
    computed: {
        ...mapState(["currentRegion", "apiData"]),
        ...mapGetters(["getCurrentRegionMATs"]),
        isDisabled() {
            return !this.modelValue;
        },
        // Two way binding with v-model prop.
        selectedMatId: {
            get() {
                return String(this.modelValue);
            },
            set(id) {
                if (this.status == "For Review") this.$emit("update:modelValue", parseInt(id));
            },
        },
        currentMatIdOptions() {
            let ret = [];
            // not from getCurrentRegionMATs because there could be more that aren't shown
            Object.values(this.contentTypeMATs).forEach((mats) => {
                mats.forEach((m) => ret.push(m.id));
            });
            return ret;
        },
        contentTypeMATs() {
            const ret = {};
            var mats;
            for (const [type, meta] of Object.entries(this.contentTypes)) {
                mats = [];
                for (const category of meta.matCategories) {
                    mats = [
                        ...mats,
                        ...this.getCurrentRegionMATs
                            .filter(
                                (m) =>
                                    m.category == category &&
                                    (this.status == "ALL" ||
                                        m.status == this.status)
                            )
                            .map((m) => ({
                                subLabel: this.contentTypes[type].subLabel
                                    ? m[this.contentTypes[type].subLabel]
                                    : undefined,
                                mat: `${m.code} v${m.version.toFixed(1)}`,
                                statusClass:
                                    m.status
                                        ?.replace(/\s+/g, "-")
                                        .toLowerCase() ?? "",
                                id: m.id,
                            })),
                    ];
                }
                ret[type] = mats;
            }
            // remove empty categories
            Object.keys(ret).forEach((k) => ret[k].length || delete ret[k]);
            return ret;
        },
        haveContentTypeMATs() {
            return Object.keys(this.contentTypeMATs).length > 0;
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
#modal-review-mat-radios {
    border: vw(1px) solid $light-grey;
    .content-type {
        background-color: #053f88;
        color: white;
        font: normal normal bold vw(12px) BrandonGrotesque;
        line-height: vw(25px);
        padding-left: vw(20px);
        text-transform: uppercase;
        border-bottom: vw(1px) solid $light-grey;
    }
    .mat-option {
        height: vw(50px);
        border-bottom: vw(1px) solid $light-grey;
        padding-left: vw(20px);
        padding-right: vw(15px);
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:last-of-type {
            border-bottom: none;
        }
        .mat {
            height: 100%;
            display: flex;
            font-size: vw(16px);
            align-content: flex-start;
            justify-content: center;
            flex-direction: column;
            position: relative;
            &.status {
                padding-left: vw(18px);
                &::before {
                    // dot
                    @include round(vw(8px));
                    display: block;
                    background-color: grey;
                    content: "\200b";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &.published::before {
                    background-color: $color-success;
                }
                &.for-review::before {
                    background-color: $color-alert;
                }
            }

            .sub-label {
                font-size: vw(12px);
                font-weight: bold;
                text-transform: uppercase;
            }
            .mat-number {
                font-size: (vw(16px));
            }
        }
        .app-radio {
            margin-bottom: 0;
            .label {
                display: none;
            }
        }
    }
}
</style>
