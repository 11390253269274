<template>
    <div class="compare-list">
        <div class="header" v-if="!isEmpty">
            <div class="name" @click="comparisonSortDirectionToggle">
                NAME<img
                    :class="
                        'sort ' + (comparisonSortAscending ? 'asc' : 'desc')
                    "
                    src="@/assets/images/Icon_ArrowSmallV_Blue.svg"
                />
            </div>
            <div class="view">VIEW</div>
        </div>
        <CompareListItem
            v-for="wire in wires"
            :key="wire.id"
            :wire="wire"
            @remove="removeWire(wire.id)"
        />
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import CompareListItem from "./CompareListItem.vue";
export default {
    components: { CompareListItem },
    computed: {
        ...mapState(["comparisonSortAscending"]),
        ...mapGetters({
            isEmpty: "comparisonIsEmpty",
            wires: "comparisonWires",
        }),
    },
    methods: {
        ...mapActions(["comparisonSortDirectionToggle", "modalConfirm"]),
        async removeWire(wireId) {
            const ok = await this.modalConfirm({
                title: "REMOVE GUIDE WIRE",
                message:
                    "Are you sure want to remove this guide wire from the comparison list?",
                okButton: "REMOVE",
            });
            if (ok) this.$store.dispatch("compareWireIdsRemove", wireId);
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
.compare-list {
    .header {
        display: flex;
        font: normal normal bold vw(11px) BrandonGrotesque;
        line-height: vw(16px);
        margin-left: auto;
        color: $abbott-dark-grey;
        padding-top: vw(17px);
        padding-bottom: vw(8px);
        border-bottom: vw(1px) solid $table-lines;
        width: vw(227px);
        margin-bottom: vw(10px);
    }
    .name {
        flex: 1;
    }
    img.sort {
        margin-left: vw(5px);
        width: vw(9px);
        &.desc {
            transform: scaleY(-1);
        }
    }
    .view {
        width: vw(57px);
    }
}
</style>
