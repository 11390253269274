<template>
    <div class="app-tabs">
        <div
            v-for="(v, k) in _options"
            :key="k"
            :class="selected == k ? 'active' : ''"
            @click="$emit('change', k)"
        >
            {{ v }}
        </div>
    </div>
</template>

<script>
import array_combine from "locutus/php/array/array_combine";
export default {
    // options can be array or key value object
    props: ["options", "selected"],
    computed: {
        _options() {
            let options = this.options;
            return Array.isArray(options)
                ? array_combine(options, options)
                : options;
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
.app-tabs {
    border-bottom: vw(1px) solid $light-grey;
    padding-left: vw(47.5px);
    margin-top: vw(10px);
    height: vw(50px);
    > div {
        height: 100%;
        width: vw(300px);
        float: left;
        border: vw(1px) solid $light-grey;
        border-bottom: vw(1px) solid transparent;
        margin-right: vw(14px);
        background-color: black;
        color: white;
        font: normal normal bold vw(14px) BrandonGrotesque;
        text-transform: uppercase;
        line-height: vw(48px);
        text-align: center;
        &.active {
            height: vw(51px);
            background-color: white;
            color: $abbott-blue;
        }
    }
    &.greyed {
        > div {
            background-color: $light-grey;
            &.active {
                color: $light-grey;
                background-color: white;
            }
        }
    }
}
</style>
