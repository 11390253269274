// fileutils.js
import { base64DecToArr } from "./MozillaBase64";
export default {
    /* eslint-disable */
    isListingDirectory: false,
    isCheckDirectory: false,
    isFilePutting: false,
    /**
     * Callback to signal that filePutContents() finished
     *
     * @callback filePutContentsCallback
     * @param {string} error - error message or '' on Success
     */
    filePutContentsCallback: null,
    /**
     * Write data to local file. Overwrites if exists.
     * works on ios.
     * @param {*} filename
     * @param {*} data
     * @param {Function} callBack - done function that receives an error string
     */
    filePutContents(filename, b64Data, callback) {
        if (this.isFilePutting) {
            let error = "filePutContents() is busy";
            callback(error);
            return;
        }
        if (this.isiOS) {
            this.isFilePutting = true;
            this.filePutContentsCallback = callback;
            window.webkit.messageHandlers.filePutContents.postMessage({
                filename: filename,
                b64Data: b64Data,
            });
            return;
        }
        if (this.isAndroid) {
            this.isFilePutting = true;
            this.filePutContentsCallback = callback;
            tbaWrapperJ.filePutContents(filename, b64Data);
            return;
        }
        callback("filePutContents() not implemented for current platform.");
    },
    filePutContentsReturned(error) {
        this.isFilePutting = false;
        if (this.filePutContentsCallback) this.filePutContentsCallback(error);
    },
    // promise version
    pFilePutContents(filename, base64Data) {
        return new Promise((resolve) => {
            tbaWrapper.filePutContents(filename, base64Data, (error) => {
                if (error) console.error(error);
                resolve(error);
            });
        });
    },

    isfileGettingContents: false,
    /**
     * Callback with binary contents or error
     *
     * @callback fileGetContentsCallback
     * @param {Uint8Array} data - true binary data. (use data.buffer for ArrayBuffer)
     * @param {string} error - error message or '' on Success
     */
    fileGetContentsCallback: null,
    /**
     * Get local file data
     * required on Android because can't fetch() file protocol
     * @param {*} filename
     * @param {Function} callBack - receives data or error string
     */
    fileGetContents(filename, callback) {
        if (this.isfileGettingContents) {
            let error = "fileGetContents() is busy";
            callback(error);
            return;
        }
        if (this.isAndroid) {
            this.isfileGettingContents = true;
            this.fileGetContentsCallback = callback;
            tbaWrapperJ.fileGetContents(filename);
            return;
        }
        callback("", "fileGetContents() not implemented for current platform.");
    },
    fileGetContentsReturned(base64Data, error) {
        this.isfileGettingContents = false;
        let data = base64DecToArr(base64Data);
        if (this.fileGetContentsCallback)
            this.fileGetContentsCallback(data, error);
    },
    // promise version
    pFileGetContents(fileName) {
        return new Promise((resolve) => {
            tbaWrapper.fileGetContents(fileName, (data, error) => {
                if (error) console.error(error);
                resolve(data);
            });
        });
    },

    // should this use an already saved file?
    /**
     * Save To files dialog. *** Android only ***
     * for iOS use cpToDownloads(). works in spite of deprecation notices. exportFile() has same issue as this
     * this was originally meant to be saveAs/print/share https://stackoverflow.com/questions/50128462/how-to-save-document-to-files-app-in-swift
     * @param {*} fileName -
     * @param {*} base64Data - encoded file data
     * @param {Function} callback - done function that receives an error string
     */
    fileSaveTo(fileName, base64Data, callback) {
        if (this.isFileSavingTo) {
            let error = "fileSaveTo() is busy";
            callback(error);
            return;
        }
        // todo finish iOS implementation.  maybe it's just a permissions thing.
        // if (this.isiOS) {
        //     this.isFileSavingTo = true;
        //     this.fileSaveToCallback = callback;
        //     window.webkit.messageHandlers.fileSaveTo.postMessage({
        //         fileName,
        //         base64Data,
        //     });
        //     return;
        // }
        if (this.isAndroid) {
            this.isFileSavingTo = true;
            this.fileSaveToCallback = callback;
            tbaWrapperJ.fileSaveTo(fileName, base64Data);
            return;
        }
        callback("fileSaveTo() not implemented on current platform.");
    },
    /**
     * Callback to signal that filePutContents() finished
     *
     * @callback fileSaveToCallback
     * @param {string} error - error message or '' on Success
     */
    fileSaveToCallback: null,
    // internal mutex
    isFileSavingTo: false,
    // internal callback
    fileSaveToReturned(error) {
        this.isFileSavingTo = false;
        if (this.fileSaveToCallback) this.fileSaveToCallback(error);
    },
    // only android
    pFileSaveTo(fileName, base64Data) {
        return new Promise((resolve) => {
            tbaWrapper.fileSaveTo(fileName, base64Data, (error) => {
                if (error) console.error(error);
                resolve(error);
            });
        });
    },

    /**
     * Android only - Print a pdf
     * for iOS use cpToDownloads()
     * @param {*} filePath - relative path to saved file (does not require leading '/')
     * @param {Function} callback - done function that receives an error string
     */
    printPdf(filePath, callback) {
        if (this.isPrintingPdf) {
            let error = "printPdf() is busy";
            callback(error);
            return;
        }
        if (this.isAndroid) {
            this.isPrintingPdf = true;
            this.printPdfCallback = callback;
            tbaWrapperJ.printPdf(filePath);
            return;
        }
        callback("printPdf() not implemented on current platform.");
    },
    /**
     * Callback to signal that printPdf() finished
     *
     * @callback printPdfCallback
     * @param {string} error - error message or '' on Success
     */
    printPdfCallback: null,
    // printPdf() internals
    isPrintingPdf: false,
    printPdfReturned(error) {
        console.log("printPdfReturned()", error);
        this.isPrintingPdf = false;
        if (this.printPdfCallback) this.printPdfCallback(error);
    },
    // promise version
    pPrintPdf(filePath) {
        console.log("pPrintPdf()");
        return new Promise((resolve) => {
            tbaWrapper.printPdf(filePath, (error) => {
                if (error) console.error(error);
                // console.log("pPrintPdf() resolving");
                resolve(error);
            });
        });
    },

    /**
     * List directory contents
     * @method ls
     * @param {String} path - (required), the path relative to the documents directory for this app; '/' is the path for the root of the docs dir; everything else is relative to that.  Path cannot be an empty string; must be '/' at the very list in order to list the root; or for example /mydir/  to list the contents of a directory called 'mydir' within the documents directory.  All paths must contain a trailing /  (e.g. /mydir/ rather than /mydir)
     * @param {Function} callBack - (optional), a function to call that accepts an array of JSON objects as its only argument containing the file names, as well as a URL that can be used to access the file from within the HTML view. e.g. [{"name" : "file.jpg", "url" : "file://docsdir/file.jpg"},...]
     */
    ls(path, callBack) {
        if (!this.isListingDirectory) {
            this.isListingDirectory = true;
            this.lsCallBack = callBack;
            if (this.isDesktop || this.isAndroid) {
                // eslint-disable-next-line no-undef
                tbaWrapperJ.ls(this.Base64.encode(path));
            } else if (this.isiOS) {
                window.webkit.messageHandlers.ls.postMessage(path);
                //window.location = "ls://foo/bar?sid="+Math.random()+"&path="+this.Base64.encode(path);
            } else {
                // this could be considered an error. other platforms shouldn't call this method.
                console.error(
                    "tbaWrapper.ls() is not implemented on this platform"
                );
                callBack([]);
            }
        }
    },

    /**
     * Returns the result from the currently executing directory listing
     * @param result - String, JSON formatted string in JSON array format with each file or dir name as well as a URL as to how you can access it from your html code; e.g. [{"name" : "file.jpg", "url" : "file://docsdir/file.jpg"},...]
     */
    lsreturned(result) {
        this.isListingDirectory = false;
        if (this.lsCallBack) {
            this.lsCallBack(JSON.parse(result));
        }
    },
    /**
     * check directory contents
     * @method chk
     * @param {String} filepath - (required), the file's path relative to the documents directory for this app; '/' is the path for the root of the docs dir; everything else is relative to that.  Path cannot be an empty string, and do not
     * @param {Function} callBack - (optional), a callback funciton to indicate if the file exists
     */
    checkfile(path, callBack) {
        if (!this.isCheckDirectory) {
            this.isCheckDirectory = true;
            this.chkCallBack = callBack;
            if (this.isDesktop || this.isAndroid) {
                // eslint-disable-next-line no-undef
                tbaWrapperJ.chkfle(this.Base64.encode(path));
            } else {
                window.webkit.messageHandlers.chkfile.postMessage(path);
                //window.location = "chkfile://foo/bar?sid="+Math.random()+"&path="+this.Base64.encode(path);
            }
        }
    },
    /**
     * Returns the result from the file check as true or false
     */
    checkreturned(result) {
        this.isCheckDirectory = false;
        if (this.chkCallBack) {
            this.chkCallBack(result);
        }
    },

    // mutex for getroot()
    isGettingRoot: false,
    /**
     * Get the app's root directory
     * @method chk
     * @param {Function} callBack - receives the result
     */
    getroot(callBack) {
        if (!this.isGettingRoot) {
            this.isGettingRoot = true;
            this.rootCallback = callBack;
            if (this.isDesktop || this.isAndroid) {
                // eslint-disable-next-line no-undef
                tbaWrapperJ.getroot();
            } else if (this.isiOS) {
                window.webkit.messageHandlers.getroot.postMessage("Get Root");
                // window.location = "getroot://foo/bar?sid="+Math.random();
            } else callBack("");
        }
    },
    /**
     * internal function
     */
    getRootReturned(result) {
        this.isGettingRoot = false;
        if (this.rootCallback) this.rootCallback(result);
    },
    /**
     *
     * @returns promise that resolves to result of getroot()
     */
    pLocalFileBasePath() {
        return new Promise((resolve) => {
            this.getroot((path) => {
                resolve(path);
            });
        });
    },
    /**
     * Move a file from source to dest.  All files are stored within the documents directory of app; which in this case equates to '/'; src and dest paths must begin with '/'
     * @method mv
     * @param {String} src - (required), The path to the file that resides within the documents directory where '/' is the root of the documents directory
     * @param {String} dest - (required), The path to the file that resides within the documents directory where '/' is the root of the documents directory
     */
    mv(src, dest, callBack) {
        if (callBack) {
            this.genericCallbackHolder = callBack;
        } else {
            this.genericCallbackHolder = null;
        }
        if (this.isDesktop || this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.mv(this.Base64.encode(src), this.Base64.encode(dest));
        } else {
            window.webkit.messageHandlers.mv.postMessage({
                src: src,
                dest: dest,
            });
            // window.location = "mv://foo/bar?sid="+Math.random()+"&src="+this.Base64.encode(src)+"&dest="+this.Base64.encode(dest);
        }
    },

    /**
     * Copy a file from source to dest.  All files are stored within the documents directory of app; which in this case equates to '/'; src and dest paths must begin with '/'
     * @method cp
     * @param {String} src - (required), The path to the file that resides within the documents directory where '/' is the root of the documents directory
     * @param {String} dest - (required), The path to the file that resides within the documents directory where '/' is the root of the documents directory
     */
    cp(src, dest, callBack) {
        if (callBack) {
            this.genericCallbackHolder = callBack;
        } else {
            this.genericCallbackHolder = null;
        }
        if (this.isDesktop || this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.cp(this.Base64.encode(src), this.Base64.encode(dest));
        } else {
            window.webkit.messageHandlers.cp.postMessage({
                src: src,
                dest: dest,
            });
            //window.location = "cp://foo/bar?sid="+Math.random()+"&src="+this.Base64.encode(src)+"&dest="+this.Base64.encode(dest);
        }
    },

    /**
     * Delete a file.  All files are stored within the documents directory of app; which in this case equates to '/'; path must begin with '/'
     * @method rm
     * @param {String} path - (required), The path to the file that resides within the documents directory where '/' is the root of the documents directory
     */
    rm(path, callBack) {
        if (callBack) {
            this.genericCallbackHolder = callBack;
        } else {
            this.genericCallbackHolder = null;
        }
        if (this.isDesktop || this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.rm(this.Base64.encode(path));
        } else {
            window.webkit.messageHandlers.rm.postMessage(path);
            //window.location = "rm://foo/bar?sid="+Math.random()+"&path="+this.Base64.encode(path);
        }
    },
    // promise version adds a '/' at start of path if there is none
    pRm(path) {
        path = (path.charAt(0) == "/" ? "" : "/") + path;
        return new Promise((resolve, reject) => {
            tbaWrapper.rm(path, (result) => {
                if (result == "deleted") resolve();
                else reject(new Error(result));
            });
        });
    },

    /**
     * Make a directory. All files are stored within the documents directory of app; which in this case equates to '/'; path must begin with '/'; this method will also create intermediate directoryies; so you can call mkdir('/some_parent/some_sub'); and if '/some_parent' doesn't exist, it will be created for you
     * @method mkdir
     * @param {String} path - (required), The path to the directory that resides within the documents directory where '/' is the root of the documents directory
     */
    mkdir(path, callBack) {
        if (callBack) {
            this.genericCallbackHolder = callBack;
        } else {
            this.genericCallbackHolder = null;
        }
        if (this.isDesktop || this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.mkdir(this.Base64.encode(path));
        } else {
            window.webkit.messageHandlers.mkdir.postMessage(path);
            //window.location = "mkdir://foo/bar?sid="+Math.random()+"&path="+this.Base64.encode(path);
        }
    },
};
