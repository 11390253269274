// dataFormat.js -sj
import util from "./util.js";
import { OLD_API } from "../constants";
import ucfirst from "locutus/php/strings/ucfirst";
export default {
    translateOldApiInPlace(apiData) {
        if (!OLD_API) return;
        apiData.guide_wires.forEach(this.translateOldWireInPlace, this);
    },
    translateOldWireInPlace(wire) {
        if (!OLD_API) return;
        wire.maker = this.brandTypeId_to_maker(wire.brand_type_id);
        wire.category = this.brandTypeId_to_category(wire.brand_type_id);
    },
    brandTypes(apiData) {
        if (!OLD_API) return {};
        var brandtypes = {};
        var brandtype_guidewires;
        var segments_by_id;
        var segments;
        for (var type_id of [1, 2, 3, 4]) {
            brandtype_guidewires = apiData.guide_wires.filter(
                (w) => w.brand_type_id == type_id
            );

            // segments for this type
            segments_by_id = this.extract_segments_by_id(brandtype_guidewires);
            segments = util.array_values(segments_by_id);
            segments.sort(util.by_column("segment"));

            var segment_wires = {};
            for (var segment of segments) {
                segment_wires[segment.id] = brandtype_guidewires.filter(
                    (w) => w.clinical_segment_id == segment.id
                );
                //.sort(util.by_column('name'));
            }
            //console.log([...segments]);
            //console.log({...segment_wires});
            // this might be useful later outside of here
            var categories_by_id = util.index_by(
                apiData.categories,
                "brand_type_id"
            );
            brandtypes[type_id] = {
                segments,
                segment_wires: { ...segment_wires },
                // old not used. sample value "Abbott Endovascular"
                brand_type_name: categories_by_id[type_id].category,
            };
        }
        return brandtypes;
    },
    index_by_segment(guide_wires) {
        // unused
        let segment_ids = util.distinct("clinical_segment_id", guide_wires);
        console.log("segments_ids: ", segment_ids);
    },
    extract_segments_by_id(guide_wires) {
        // reconstruct clinical_segment data from redundant info
        // don't need by id but keys help get distinct
        var segments_by_id = {};
        for (var row of guide_wires) {
            // could save time by checking if set.
            segments_by_id[row.clinical_segment_id] = {
                id: row["clinical_segment_id"],
                // segment: row['segment'],
                name: row["segment"],
                colour: row["colour"],
            };
        }
        return segments_by_id;
    },
    // Translation while using old api
    // to old api
    brand_type_to_brandTypeId(brand, type) {
        return (
            {
                abbott_coronary: 1,
                other_coronary: 2,
                abbott_peripheral: 3,
                other_peripheral: 4,
            }[`${brand}_${type}`] ?? 0
        );
    },
    // from old api
    brandTypeId_to_brand(brandTypeId) {
        return (
            { 1: "abbott", 2: "other", 3: "abbott", 4: "other" }[brandTypeId] ??
            ""
        );
    },
    brandTypeId_to_type(brandTypeId) {
        return (
            { 1: "coronary", 2: "coronary", 3: "peripheral", 4: "peripheral" }[
                brandTypeId
            ] ?? ""
        );
    },
    brandTypeId_to_maker(brandTypeId) {
        return ucfirst(this.brandTypeId_to_brand(brandTypeId));
    },
    brandTypeId_to_category(brandTypeId) {
        return ucfirst(this.brandTypeId_to_type(brandTypeId));
    },
    // clones to keep apiData untouched
    // no matter now because using new api
    // old_to_new_wire(oldWire) {
    //     // shallow clone
    //     let wire = { ...oldWire };
    //     wire.brand = this.brandTypeId_to_brand(wire.brand_type_id);
    //     wire.type = this.brandTypeId_to_type(wire.brand_type_id);
    //     return wire;
    // }
};
