<template>
    <div class="app-checkboxes">
        <template v-for="(label, value) in valueLabels" :key="value">
            <label class="app-checkbox">
                <input type="checkbox" :value="value" v-model="localModel" />
                <span class="checkbox"></span>
                <span class="label">{{ label }}</span>
            </label>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: { default: [] },
        valueLabels: { default: { value: "valueLabel" } },
    },
    emits: ["update:modelValue"],
    data() {
        return {
            localModel: [],
        };
    },
    created() {
        this.localModel = this.modelValue;
    },
    watch: {
        localModel() {
            this.$emit("update:modelValue", this.localModel);
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
.app-checkboxes {
    display: flex;
    flex-direction: column;
    @include gap-compat-1(vw(10px), column);
    .app-checkbox {
        display: block;
        cursor: pointer;
        font-size: vw(16px);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: flex;
        align-items: center;
        @include gap-compat-1(vw(10px));
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
        input:checked ~ .checkbox {
            background-image: url(@/assets/images/Checkbox_Blue.svg);
        }
        .checkbox {
            width: vw(30px);
            height: vw(30px);
            border: vw(1px) solid $lightblue-border;
            background-image: none;
            background-repeat: no-repeat;
            background-position: center;
            background-size: vw(16px);
        }
        .label {
            font: normal normal normal vw(16px) Calibri;
            line-height: vw(18px);
            vertical-align: middle;
        }
    }
}
</style>
