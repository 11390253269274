<template>
    <div class="products-view">
        <header>
            <hr class="gradient" :class="gradientClass" />
            <h1>
                <img
                    class="back-link"
                    src="@/assets/images/Icon_Arrow_Back.svg"
                    @click="$router.push('/product-types')"
                />{{ type }}
            </h1>
            <AppTabs
                :options="tabOptions"
                :selected="selectedTab"
                @change="$router.push(`/products/${type}/${ucfirst($event)}`)"
            />
            <div class="segments-dropdown">
                <label>SHOWING</label>
                <AppDropdown
                    :options="segmentOptions"
                    :selected="selectedSegment"
                    @change="setSelectedSegment"
                />
            </div>
        </header>
        <div class="segments screenshot-expand scroll-y" v-dragscroll="$isWeb">
            <template v-for="segment in showSegments" :key="segment">
                <ProductSegment
                    :segment="segment"
                    :segmentWires="segmentWires[segment]"
                />
            </template>
            <div
                class="footnote"
                v-html="getFooter"
                v-show="hasSegmentWires"
                @click.prevent="$root.linkNative($event)"
            ></div>
            <h2 v-show="!hasSegmentWires">No Guidewires</h2>
        </div>
        <div class="segments-scrollfade"></div>
    </div>
</template>

<script>
import AppDropdown from "@/components/AppDropdown.vue";
import ProductSegment from "@/components/ProductSegment.vue";
import AppTabs from "@/components/AppTabs.vue";
import { mapState, mapMutations, mapGetters } from "vuex";
import { OLD_API } from "../constants";
import ucfirst from "locutus/php/strings/ucfirst";
import strtolower from "locutus/php/strings/strtolower";

const ALL = "All Guide Wires";

export default {
    props: ["type", "maker"],
    components: {
        AppDropdown,
        ProductSegment,
        AppTabs,
    },
    data() {
        return {
            tabOptions: ["abbott", "other"],
            selectedSegment: ALL,
        };
    },
    methods: {
        // todo record selectedSegment
        setSelectedSegment(segment) {
            this.selectedSegment = segment;
        },
        ...mapMutations([
            "setCurrentWireType", // old category
            "setCurrentCategory",
            "setCurrentSection",
        ]),
        ...mapMutations(["setCurrentBrand", "setCurrentMaker"]),
        setCurrentTab(tab) {
            this.selectedSegment = ALL;
            if (OLD_API) {
                this.setCurrentBrand(strtolower(tab));
            } else {
                // todo route instead
                this.setCurrentMaker(ucfirst(tab));
            }
        },
        ucfirst,
    },
    computed: {
        ...mapState([
            "currentBrand", // old maker
            // "currentWireType", // old category
            "currentMaker",
            "currentCategory",
        ]),
        ...mapGetters([
            // "currentBrandType", // old api
            "makerCategorySegmentWires",
            "getFooter",
        ]),
        segments() {
            return Object.keys(this.segmentWires).sort((a, b) =>
                a <= b ? -1 : 1
            );
        },
        segmentOptions() {
            return [ALL, ...this.segments];
        },
        // {segment:[wires]}
        segmentWires() {
            return (
                this.makerCategorySegmentWires?.[this.currentMaker]?.[
                    this.currentCategory
                ] ?? {}
            );
            // if (OLD_API) {
            //     const segmentIdWires =
            //         this.currentBrandType.segment_wires ?? {};
            //     const segmentsById =
            //         util.index_by_id(this.currentBrandType.segments) ?? {};
            //     let ret = {};
            //     for (const [id, wires] of Object.entries(segmentIdWires)) {
            //         ret[segmentsById[id].name] = wires;
            //     }
            //     return ret;
            // } else {
            //     return (
            //         this.makerCategorySegmentWires?.[this.currentMaker]?.[
            //             this.currentCategory
            //         ] ?? {}
            //     );
            // }
        },
        hasSegmentWires() {
            return !!Object.keys(this.segmentWires).length;
        },
        showSegments() {
            return this.selectedSegment == ALL
                ? this.segments
                : [this.selectedSegment];
        },
        // category is the new type
        category() {
            return ucfirst(this.type);
        },
        gradientClass() {
            return OLD_API ? this.type : strtolower(this.category);
        },
        selectedTab() {
            return OLD_API ? this.currentBrand : strtolower(this.currentMaker);
        },
    },
    beforeMount() {
        // type is from path props
        if (OLD_API) {
            this.setCurrentWireType(this.type);
        } else {
            this.setCurrentCategory(this.category);
        }
        this.setCurrentTab(this.maker);
    },
    watch: {
        // todo simplify/combine these
        type() {
            // old
            // because navigating does not remount
            // see also beforeRouteUpdate - https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards
            // todo trigger page_view
            this.selectedSegment = ALL;
            this.setCurrentWireType(this.type);
            // if (OLD_API) this.setCurrentWireType(this.type);
            // else this.setCurrentCategory(this.category);
        },
        category() {
            // new
            // because navigating does not remount
            // see also beforeRouteUpdate - https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards
            // todo trigger page_view
            this.selectedSegment = ALL;
            this.setCurrentCategory(this.category);
        },
        maker() {
            this.selectedSegment = ALL;
            this.setCurrentMaker(this.maker); // prop to store
        },
    },
};
</script>

<style></style>
