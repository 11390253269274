<template>
    <select
        class="resolution-picker"
        v-model="selected"
        @change="open"
    >
        <option value="-1">- test resolution -</option>
        <option v-for="(res, i) in resolutions" :key="i" :value="i">
            {{ `${res.aspect} ${res.w} x ${res.h} (${res.comment})` }}
        </option>
    </select>
</template>

<script>
export default {
    name: "ResolutionPicker",
    data() {
        return {
            selected: -1,
            resolutions: [
                {
                    aspect: "4:3",
                    w: 1024,
                    h: 768,
                    comment: "iPad",
                },
                {
                    aspect: "16:10",
                    w: 960,
                    h: 600,
                    comment: "nexus-7",
                },
                {
                    aspect: "16:9",
                    w: 960,
                    h: 540,
                    comment: "qHD",
                },
                {
                    aspect: "16:9",
                    w: 1280,
                    h: 720,
                    comment: "HD",
                },
                {
                    aspect: "16:9",
                    w: 1920,
                    h: 1080,
                    comment: "Full HD",
                },
            ],
        };
    },
    methods: {
        open(){
            var i = this.selected;
            if (i == -1) return;
            var r = this.resolutions[i];
            window.open('/', '', `width=${r.w},height=${r.h}`);
            this.selected = -1;
        },
    }
};
</script>

<style lang="scss" scoped>
.resolution-picker {
    // position: absolute;
    // top: 20px;
    // right:20px;
    // z-index:2;
}
</style>