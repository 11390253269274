<template>
    <div class="resources-list-item">
        <div class="image">
            <img class="image" :src="resourceThumbImgSrc" />
            <div class="overlay"></div>
        </div>
        <div class="name" v-html="resource.name"></div>
        <button class="view" @click="$emit('viewPdf', resource)">
            VIEW DOCUMENT<img src="@/assets/images/Icon_Arrow_White.svg" />
        </button>
    </div>
</template>

<script>
import * as file from "@/helpers/FileHelper";
// import { mapState } from "vuex";
export default {
    props: ["resource"],
    computed: {
        // ...mapState(["fileBasePath"]),
        resourceThumbImgSrc() {
            return file.src(this.resource.thumbnail);
            // turns out we don't have to be reactive because store already has fileBasePath
            // and no downloaded images are seen on before it has fileBasePath
            // return `${this.fileBasePath}/${file.relativeBasePath(this.resource.thumbnail)}`;
        },
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/base/variables";
@import "@/assets/scss/base/fonts";
.resources-list-item {
    width: vw(458px);
    height: vw(230px);
    padding: vw(20px);
    border: vw(1px) solid $table-lines;
    border-radius: vw(5px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
    flex-wrap: wrap;
    // gap: vw(20px);
    // old ios compat
    & > * {
        &:not(:first-child) {
            margin-left: vw(20px);
        }
        &:last-child {
            margin-top: vw(20px);
        }
    }
    .image {
        position: relative;
        box-shadow: 0px vw(3px) vw(6px) 0 rgba(0, 0, 0, 0.1);
        width: vw(146px);
        height: vw(190px);
        > img {
            width: vw(140px);
            height: vw(184px);
            position: absolute;
            top: vw(3px);
            left: vw(3px);
        }
        .overlay {
            content: " ";
            background-color: #cecece40;
            position: absolute;
            top: vw(3px);
            right: vw(3px);
            bottom: vw(3px);
            left: vw(3px);
        }
    }
    .name {
        font: normal normal normal vw(18px) MercuryTextG1;
        line-height: vw(24px);
        color: $abbott-blue;
    }
    button.view {
        width: vw(190px);
        height: vw(45px);
        font: normal normal bold vw(14px) BrandonGrotesque;
        line-height: vw(45px);
        color: white;
        background-color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        // gap: vw(10px);
        img {
            // iOS 14 gap broken
            margin-left: vw(10px);
            width: vw(16px);
        }
    }
}
</style>
