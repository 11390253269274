// not a getter because supposedly bad form https://stackoverflow.com/questions/43258793/accessing-getters-within-vuex-mutations
// maybe create action to call this as a getter
function nextAvailableColorIndex(state) {
    for (let i = 1; i <= 8; i++) {
        // todo COMPARISON_MAX
        if (
            !Object.values(state.comparisonById).filter(
                (o) => o.colorIndex == i
            ).length
        )
            return i;
    }
    console.log("should never get here");
    return 0;
}

export default {
    // best practices https://gist.github.com/DawidMyslak/2b046cca5959427e8fb5c1da45ef7748
    // tldr: Vue.set() also Vue.delete()
    SET_REVISION: (state, revision) => (state.revision = revision),
    menuToggle: (state) => (state.isMenuOpen = !state.isMenuOpen),
    menuOpen: (state) => (state.isMenuOpen = true),
    menuClose: (state) => (state.isMenuOpen = false),
    modalReviewOpen: (state) => (state.isReviewOpen = true),
    modalReviewClose: (state) => (state.isReviewOpen = false),
    setIsLoggedIn: (state, is) => (state.isLoggedIn = is),
    setApiData: (state, payload) => (state.apiData = payload),
    setCurrentRegion: (state, region) => {
        if (state.currentRegion != region) state.currentRegion = region;
    },
    setCurrentRegionId: (state, id) => (state.currentRegionId = id), // old
    // setCurrentWireType: (state, type) => (state.currentWireType = type), // old
    setCurrentBrand: (state, brand) => (state.currentBrand = brand), // old
    // tiny bit of logic here could go in actions. simple for now.
    // this avoids triggering getters
    setCurrentCategory(state, category) {
        if (state.currentCategory != category) state.currentCategory = category;
    },
    setCurrentMaker(state, maker) {
        if (state.currentMaker != maker) state.currentMaker = maker;
    },
    setCurrentSection: (state, section) => (state.currentSection = section),
    compareWireIdsPush(state, wireId) {
        if (state.compareWireIds.length < 8) state.compareWireIds.push(wireId);
    },
    compareWireIdsRemove(state, wireId) {
        state.compareWireIds = state.compareWireIds.filter((w) => w != wireId);
    },
    comparisonByIdAdd(state, wireId) {
        state.comparisonById[wireId] = {
            isShowing: true,
            colorIndex: nextAvailableColorIndex(state),
        };
    },
    comparisonByIdRemove(state, wireId) {
        delete state.comparisonById[wireId];
    },
    comparisonIsShowingToggle(state, wireId) {
        state.comparisonById[wireId].isShowing ^= true;
    },
    comparisonHighlightIdSet(state, wireId) {
        state.comparisonHighlightId = wireId;
    },
    comparisonRemoveAll(state) {
        // use the action
        state.compareWireIds = [];
        state.comparisonById = {};
        state.comparisonHighlightId = 0;
    },
    comparisonSortDirectionToggle(state) {
        state.comparisonSortAscending ^= true;
    },
    modalConfirmPopulate(state, opts) {
        state.modalConfirm = {
            isVisible: state.modalConfirm.isVisible,
            title: opts.title,
            message: opts.message,
            okButton: opts.okButton ?? "OK",
            okBtnStyle: opts.okBtnStyle ?? "",
            cancelButton: opts.cancelButton ?? "CANCEL",
        };
    },
    modalConfirmShow(state) {
        state.modalConfirm.isVisible = true;
    },
    modalConfirmHide(state) {
        state.modalConfirm.isVisible = false;
    },
    setPopupInfoBarMessage(state, message) {
        state.popupInfoBar.message = message;
    },
    setPopupInfoBarType(state, type) {
        state.popupInfoBar.type = ["success", "warning"].includes(type)
            ? type
            : "success";
    },
    setPopupInfoBarIsVisible(state, is) {
        state.popupInfoBar.isVisible = is;
    },
    setPopupInfoBarTimeoutId(state, id) {
        state.popupInfoBar.timeoutId = id;
    },
    setTestComponentIsOpen: (state, isOpen) =>
        (state.testComponentIsOpen = isOpen),
    setTestComponentIsShowing: (state, is) =>
        (state.testComponentIsShowing = is),
    setIsSyncing: (state, is) => (state.isSyncing = is),
    setUser: (state, user) => (state.user = user),
    setcurrentRoute: (state, path) => (state.currentRoute = path),
    setCurrentRoute(state, route) {
        // probably should be in an action
        if (state.currentRoute.fullPath != route.fullPath)
            state.currentRoute.fullPath = route.fullPath;
        if (state.currentRoute.meta?.template != route.meta?.template)
            state.currentRoute.meta = route.meta;
    },
    // these two not used yet
    setCurrentRouteFullPath: (state, path) =>
        (state.currentRoute.fullPath = path),
    setCurrentRouteMeta: (state, meta) => (state.currentRoute.meta = meta),
    setIsAuthenticating: (state, is) => (state.isAuthenticating = is),
    // removeWires: (state) =>
    //     // Uncaught (in promise) TypeError: Cannot create property 'guide_wires' on boolean 'false'
    //     // Vue.set(state.apiData, 'guide_wires', [])
    //     (state.apiData.guide_wires = []),
    setApiDataRegion: (state, region) => (state.apiDataRegion = region),
    setIsRecordingSession: (state, is) => (state.isRecordingSession = is),
    // recordedSessionPush: (state, item) => state.recordedSession.push(item),
    // recordedSessionClear: (state) => (state.recordedSession = []),
    setComparisonProperty: (state, property) =>
        (state.comparisonProperty = property),
    // recorded session stuff
    setSessionWireById: (state, wire) =>
        (state.sessionWiresById[wire.id] = wire), // todo Vue.set()

    incrementSessionWireIdViewCount: (state, id) =>
        (state.sessionWireIdViewCount[id] =
            ++state.sessionWireIdViewCount[id] || 1),
    incrementSessionWireIdCompareCount: (state, id) =>
        (state.sessionWireIdCompareCount[id] =
            ++state.sessionWireIdCompareCount[id] || 1),
    incrementSessionUniqueComparisonCount: (state, jsonIds) =>
        (state.sessionUniqueComparisonCount[jsonIds] =
            ++state.sessionUniqueComparisonCount[jsonIds] || 1),
    clearSessionWiresById: (state) => (state.sessionWiresById = {}),
    clearSessionWireIdViewCount: (state) => (state.sessionWireIdViewCount = {}),
    clearSessionWireIdCompareCount: (state) =>
        (state.sessionWireIdCompareCount = {}),
    clearSessionUniqueComparisonCount: (state) =>
        (state.sessionUniqueComparisonCount = {}),
    setCurrentStatusViewing: (state, status) => {
        // don't trigger getters if no need.
        if (state.currentStatusViewing != status)
            state.currentStatusViewing = status;
    },
    setEnvironment: (state, environment) => (state.environment = environment),
    setLocalFileBasePath: (state, path) => {
        console.log("setLocalFileBasePath", path);
        state.localFileBasePath = path;
    },
    setIsReviewingMat: (state, is) => (state.isReviewingMat = is),
    setIsReviewApp: (state, is) => (state.isReviewApp = is),
    setIsGAEnabled: (state, is) => (state.isGAEnabled = is),
};
