<template>
    <div id="test" v-if="testComponentIsShowing" data-html2canvas-ignore>
        <div class="button" @click="testComponentToggle">[Test]</div>
        <div class="tests" :class="testComponentIsOpen ? 'open' : ''">
            <ResolutionPicker v-if="!tbaWrapper.platform" />
            <ScreensNav />

            <select class="env-picker" v-model="env">
                <option v-for="e in environments" :key="e" :value="e">
                    {{ e }}
                </option>
            </select>
            <br />
            <select v-model="isReview">
                <option value="">Sales App</option>
                <option value="1">Reviewer App</option>
            </select>
            <br />
            <select v-model="loggedIn">
                <option value="">Not Logged In</option>
                <option value="1">Logged In</option>
            </select>
            <br />
            <button @click="reset">FACTORY RESET</button><br />
            <button @click="hide">HIDE</button><br />
            <!-- <button @click="saveState">SAVE STATE TO FILE</button><br /> -->
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import ResolutionPicker from "./Test/ResolutionPicker.vue";
import ScreensNav from "./Test/ScreensNav.vue";
import tbaWrapper from "@/helpers/tbaWrapper";
export default {
    components: { ResolutionPicker, ScreensNav },
    data() {
        return {
            isOpen: true,
            environments: ["DEV", "QA", "UAT", "PEN", "PROD"], // todo constants
            tbaWrapper,
        };
    },
    computed: {
        ...mapState([
            "testComponentIsShowing",
            "testComponentIsOpen",
            "environment",
            "isReviewApp",
            "isLoggedIn",
        ]),

        env: {
            get() {
                return this.environment;
            },
            set(value) {
                this.changeEnvironment(value);
            },
        },
        isReview: {
            get() {
                return this.isReviewApp ? "1" : "";
            },
            set(is) {
                this.changeIsReviewApp(!!is);
            },
        },
        loggedIn: {
            get() {
                return this.isLoggedIn ? "1" : "";
            },
            set(is) {
                this.setIsLoggedIn(!!is);
            },
        },

        // isReviewSelectValue() {
        //     return this.isReviewApp ? "1" : "";
        // },
    },
    methods: {
        ...mapActions([
            "testComponentToggle",
            "changeEnvironment",
            "changeIsReviewApp",
        ]),
        ...mapMutations(["setTestComponentIsShowing", "setIsLoggedIn"]),
        envChange(e) {
            console.log(e);
            this.changeEnvironment(e.target.value);
            this.reset();
        },
        reset() {
            window.localStorage.clear();
            window.location.href = tbaWrapper.platform ? "./index.html" : "./";
        },
        hide() {
            this.setTestComponentIsShowing(false);
        },
        // async saveState() {
        //     await tbaWrapper.pFilePutContents(
        //         "state.json",
        //         tbaWrapper.Base64.encode(JSON.stringify(this.$store.state))
        //     );
        //     console.log("saved state.json");
        // },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
#test {
    position: absolute;
    top: vw(24px);
    right: 0;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.7);
    padding-top: 30px;
    .button {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: vw(16px);
        font-weight: bold;
        cursor: pointer;
    }
    .tests {
        display: none;
        &.open {
            display: block;
        }
    }
    select {
        display: block;
        color: black;
    }
}
</style>
