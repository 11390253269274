// apiquery.js
export default {
    apiIsExecuting: false,
    queryCallBack: null,
    lsCallBack: null,
    apiCallBack: null,
    apiErrorCallBack: null,
    /**
     * don't use this. it can be done asynchronously in js without issue. -stan
     * Queries a web based api to retrieve the response from that API; using native code to do this solves the crossdomain origin issue with JS.  Queries are synchronous, hence this method can't be called again if the previous call hasn't returned yet.
     * @method queryAPI
     * @param {String} url - (required), the absolute address of the external API to query
     * @param {String} key - (required), a private key that can be sent to the remote server via POST - hence if its an https connection it will be secure; use 'none' if you don't want this feature
     * @param {String} value - (required), the value that will accompany the key; use 'none' if you don't want this feature
     * @param {Function} completeCallBack - (optional), a function that accepts one string argument to execute when API lookup is complete
     * @param {Function} errorCallBack - (optional), a function that accepts one integer argument containing the status code returned; a code of 0 means that the operation failed after the initial connection response
     */
    queryAPI(url, key, value, completeCallBack, errorCallBack) {
        if (!this.apiIsExecuting) {
            this.apiIsExecuting = true;
            this.apiCallBack = completeCallBack;
            this.apiErrorCallBack = errorCallBack;
            if (this.isAndroid) {
                // eslint-disable-next-line no-undef
                tbaWrapperJ.queryAPI(
                    this.Base64.encode(url),
                    this.Base64.encode(key),
                    this.Base64.encode(value)
                );
            } else if (this.isiOS) {
                window.webkit.messageHandlers.queryapi.postMessage({
                    key: key,
                    value: value,
                    url: url,
                });
                // window.location = "queryapi://foo/bar?sid="+Math.random()+"&url="+this.Base64.encode(url)+"&key="+this.Base64.encode(key)+"&value="+this.Base64.encode(value);
            }
        }
    },

    /**
     * Returns the result from the current API call that is underway
     * @param result - String, the code response from the api call (e.g. same as choosing 'view source' in your browser window)
     */
    apiReturned(result) {
        this.apiIsExecuting = false;
        if (this.apiCallBack) {
            this.apiCallBack(result);
        }
    },

    /**
     * A listener to report on failed API querries
     * @param status - int, the HTTP status code if available; otherwise 0  zero would probably denote that the datastream failed mid connection, rather than after the initial response from the server.
     */
    apiQueryFailed(status) {
        this.apiIsExecuting = false;
        if (this.apiErrorCallBack) {
            this.apiErrorCallBack(status);
        }
    },
};

