<template>
    <div id="modal-startup-white" class="modal" data-html2canvas-ignore></div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@keyframes fadeOutHide {
    0% {
        opacity: 1;
        visibility: visible;
    }
    99% {
        opacity: 0;
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}
#modal-startup-white {
    background-color: white;
    animation: 1s ease-in 500ms 1 fadeOutHide;
    animation-fill-mode: forwards;
}
</style>
