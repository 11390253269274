<template>
    <div class="relative-for-popup" @click="clickX()" @mouseover="hoverX()">
        <svg
            v-if="hasData"
            class="graph"
            :viewBox="viewBox"
            @click="clickX()"
            @mouseover="hoverX()"
            xmlns="http://www.w3.org/2000/svg"
        >
            <!-- version and xmlns not needed inline https://stackoverflow.com/questions/18467982/are-svg-parameters-such-as-xmlns-and-version-needed -->
            <!-- don't need below cause it's inline -->
            <!-- https://vecta.io/blog/using-fonts-in-svg -->
            <!-- <defs>
                <svg:style>
                    @import url("/fonts.css");
                </svg:style>
            </defs>
            <svg:style>
                <![CDATA[svg text{stroke:none}]]>
            </svg:style> -->

            <g id="graph">
                <g class="axis-lines" stroke="#E5E5E5">
                    <line
                        :x1="mapX(0)"
                        :y1="mapY(0)"
                        :x2="mapX(0)"
                        :y2="padding.top"
                    ></line>
                    <line
                        :x1="mapX(0)"
                        :y1="mapY(0)"
                        :x2="width - padding.right"
                        :y2="mapY(0)"
                    ></line>
                </g>
                <g class="graduation" stroke="#E5E5E5">
                    <line
                        v-for="y in yGraduations"
                        :key="'ygl' + y"
                        :x1="mapX(0)"
                        :x2="width - padding.right"
                        :y1="mapY(y)"
                        :y2="mapY(y)"
                    ></line>
                </g>
                <g class="labels x">
                    <text
                        v-for="x in xGraduations"
                        :key="'xlt' + x"
                        :x="mapX(x)"
                        :y="mapY(0) + 20"
                    >
                        {{ x }}
                    </text>
                </g>
                <g class="labels y">
                    <!-- https://vanseodesign.com/web-design/svg-text-baseline-alignment/ -->
                    <!-- dominant-baseline middle is too high and mathematical is too low for numbers in this font -->
                    <text
                        v-for="y in yGraduations"
                        :key="'ygt' + y"
                        :x="padding.left - 16"
                        :y="mapY(y) + 2"
                        text-anchor="end"
                        dominant-baseline="middle"
                    >
                        {{ y }}
                    </text>
                </g>
                <text
                    class="axis-label x"
                    :x="mapX(0) + graphWidth / 2"
                    :y="mapY(0) + padding.bottom"
                    text-anchor="middle"
                >
                    <!-- https://stackoverflow.com/questions/35565063/svg-dominant-baseline-not-working-in-safari -->
                    <!-- dominant-baseline="text-after-edge" -->
                    DISTANCE FROM TIP ( mm )
                </text>
                <text
                    class="axis-label y"
                    :x="0"
                    :y="0"
                    text-anchor="middle"
                    dominant-baseline="text-before-edge"
                    :transform="transformYAxisLabel"
                >
                    SUPPORT ( g )
                </text>

                <defs>
                    <template
                        v-for="(color, i) in comparisonColors"
                        :key="'cc' + i"
                    >
                        <linearGradient
                            :id="`gradient-${i}`"
                            x1="0"
                            x2="0"
                            y1="0"
                            y2="1"
                        >
                            <stop
                                offset="0%"
                                :stop-color="color"
                                stop-opacity="80%"
                            />
                            <stop
                                offset="100%"
                                :stop-color="color"
                                stop-opacity="0%"
                            />
                        </linearGradient>
                    </template>
                </defs>
                <g class="polygons">
                    <polygon
                        v-for="(points, i) in polygons"
                        :key="'pg' + i"
                        v-show="pathIsShowing(i) && pathIsHighlighted(i)"
                        :points="points"
                        :fill="`url('#gradient-${colorIndex(i)}')`"
                    ></polygon>
                </g>
                <g class="paths">
                    <path
                        v-for="(path, i) in paths"
                        :key="'p' + i"
                        v-show="pathIsShowing(i)"
                        :d="path"
                        :class="pathClass(i)"
                        fill="none"
                    ></path>
                </g>
                <g v-if="!isGeneratingPdf && popup">
                    <line
                        :x1="mapX(popup.x)"
                        :y1="mapY(0)"
                        :x2="mapX(popup.x)"
                        :y2="padding.top"
                        stroke-width="1"
                        stroke="black"
                    ></line>
                    <template
                        v-for="(point, i) in popup.points"
                        :key="'pp' + i"
                    >
                        <circle
                            :cx="mapX(popup.x)"
                            :cy="mapY(point.y)"
                            :r="clickPointRadius"
                        />
                    </template>
                </g>

                <g class="click-areas" v-if="!isGeneratingPdf">
                    <template v-for="(b, i) in clickBoxes" :key="'cb' + i">
                        <rect
                            @click.stop="clickX(b.x)"
                            @mouseover.stop="hoverX(b.x)"
                            class="click-box"
                            :x="b.xPosition"
                            :y="b.yPosition"
                            :width="b.width"
                            :height="b.height"
                            stroke-width="0"
                            fill="transparent"
                        />
                    </template>
                </g>
            </g>
        </svg>
        <!-- <h4 v-else class="no-data">No Data</h4> -->
        <svg v-else class="graph" :viewBox="viewBox">
            <g id="graph">
                <g class="axis-lines" stroke="#E5E5E5">
                    <line
                        :x1="mapX(0)"
                        :y1="mapY(0)"
                        :x2="width - padding.right"
                        :y2="mapY(0)"
                    ></line>
                </g>
                <g class="graduation" stroke="#E5E5E5">
                    <line
                        v-for="y in yGraduations"
                        :key="'yg' + y"
                        :x1="mapX(0)"
                        :x2="width - padding.right"
                        :y1="mapY(y)"
                        :y2="mapY(y)"
                    ></line>
                </g>
            </g>
        </svg>
        <!-- todo fix hover jitter on single small graph on web due to scrollbars -->
        <div v-if="!isGeneratingPdf && popup" class="popup">
            <label>Distance from Tip (mm):</label>
            <span>{{ popup.x }}</span>
            <br />
            <template v-for="(point, i) in popup.points" :key="'ppl' + i">
                <label>Support (g):</label>
                <span :class="point.colorClass">{{
                    this.toFixed(point.y)
                }}</span>
                <br />
            </template>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
export default {
    name: "WireGraph",
    props: ["wires", "width", "height", "isComparison"],
    data() {
        return {
            // padding does not scale, contains ticks and labels
            padding: {
                top: 10,
                right: 10,
                bottom: 58,
                left: 65,
            },
            tickLength: 7,
            popupX: false,
            popupClick: false, // hover when false
            // duplicated in _variables.scss todo centralize
            comparisonColors: {
                1: "#99cc00",
                2: "#ff9900",
                3: "#cc0000",
                4: "#ccccff",
                5: "#0395d4",
                6: "#b924d5",
                7: "#00d6ef",
                8: "#e5b200",
            },
        };
    },
    computed: {
        // deal with wires with empty graph (GuideRight, HydroSteer)
        hasData() {
            for (const wire of this.wires) {
                // for some reason empties are arrays instead of objects
                if (!Array.isArray(wire.graph)) return true;
            }
            return false;
        },
        // todo don't recalculate when there are zero or calc only on create
        wiresMax() {
            // arbitrary when no data
            if (!this.hasData) return { x: 100, y: 100 };
            let maxX = 0;
            let maxY = 0;
            let x, y;
            this.wires.forEach(function (wire) {
                for ([x, y] of Object.entries(wire.graph)) {
                    x = parseInt(x);
                    y = parseFloat(y);
                    if (x > maxX) maxX = x;
                    if (y > maxY) maxY = y;
                }
            });
            return {
                x: maxX,
                y: maxY,
            };
        },
        graphRange() {
            return {
                x: Math.ceil(this.wiresMax.x / 10) * 10,
                y: Math.ceil(this.wiresMax.y / 10) * 10,
            };
        },
        viewBox() {
            return [0, 0, this.width, this.height].join(" ");
        },
        graphWidth() {
            return this.width - this.padding.left - this.padding.right;
        },
        graphHeight() {
            return this.height - this.padding.top - this.padding.bottom;
        },
        xScale() {
            return this.graphWidth / this.graphRange.x;
        },
        yScale() {
            return this.graphHeight / this.graphRange.y;
        },
        xGraduations() {
            let increment = this.graphRange.x / 10;
            let xg = [];
            for (let i = 0; i <= 10; i++) xg.push(i * increment);
            return xg;
        },
        yGraduations() {
            let increment = this.graphRange.y / 10;
            let yg = [];
            for (let i = 0; i <= 10; i++) yg.push(i * increment);
            return yg;
        },
        transformYAxisLabel() {
            // strange that transform operations are applied right to left
            return `translate(0, ${
                this.padding.top + this.graphHeight / 2
            }) rotate(-90) `;
        },
        paths() {
            let paths = [];
            this.wires.forEach(function (wire) {
                if (!Array.isArray(wire.graph)) {
                    // not empty - empties are array
                    paths.push(this.graphToPathData(wire.graph));
                }
            }, this);
            return paths;
        },
        polygons() {
            let polys = [];
            this.wires.forEach(function (wire) {
                if (!Array.isArray(wire.graph)) {
                    // not empty - empties are array
                    polys.push(this.graphToPolygonPoints(wire.graph));
                }
            }, this);
            return polys;
        },
        ...mapState(["comparisonById", "comparisonHighlightId"]),
        ...mapState({
            isGeneratingPdf: (state) => state.pdf.isGeneratingPdf,
        }),
        clickPointRadius() {
            // seen x values 2 units apart
            return 1 * this.xScale;
        },
        allXShowing() {
            let xKeys = {};
            this.wires.forEach(function (wire, wireIndex) {
                if (!this.pathIsShowing(wireIndex)) return; // continue
                for (let x in wire.graph) xKeys[x] = true;
            }, this);
            return Object.keys(xKeys);
        },
        clickBoxes() {
            let boxes = [],
                x,
                lastI = this.allXShowing.length - 1;
            for (let i = 0; i < this.allXShowing.length; i++) {
                x = parseInt(this.allXShowing[i]);
                let isFirst = !i;
                // left = first ? 0 : halfway from prev
                let left = isFirst
                    ? 0
                    : (parseInt(this.allXShowing[i - 1]) + x) / 2;
                // right = last ? end : halfway to next
                let right =
                    i == lastI
                        ? this.graphRange.x
                        : (x + parseInt(this.allXShowing[i + 1])) / 2;
                boxes.push({
                    x: x,
                    xPosition: this.mapX(left),
                    yPosition: this.padding.top,
                    width: this.xScale * (right - left),
                    height: this.graphHeight,
                });
            }
            return boxes;
        },
        popup() {
            if (this.popupX === false) return false;
            let popup = {
                x: this.popupX,
                points: [],
            };
            this.wires.forEach(function (wire, i) {
                if (!this.pathIsShowing(i)) return;
                if (!(this.popupX in wire.graph)) return;
                popup.points.push({
                    colorClass: this.isComparison
                        ? `color-${this.comparisonById[wire.id].colorIndex}`
                        : "color-single",
                    y: wire.graph[this.popupX],
                });
            }, this);
            popup.points.sort((a, b) => b.y - a.y);
            return popup;
        },
        cssHeight() {
            return this.cssVW(this.height);
        },
        cssWidth() {
            return this.cssVW(this.width);
        },
    },
    methods: {
        mapX(x) {
            return this.padding.left + this.xScale * x;
        },
        mapY(y) {
            return this.height - this.padding.bottom - this.yScale * y;
        },
        xCommaYArray(graph) {
            let a = [];
            for (const [x, y] of Object.entries(graph)) {
                a.push(`${this.mapX(x)},${this.mapY(y)}`);
            }
            return a;
        },
        graphToPathData(graph) {
            let points = this.xCommaYArray(graph);
            return "M" + points.join(" L");
        },
        graphToPolygonPoints(graph) {
            let points = this.xCommaYArray(graph);
            // add point at zero to both ends
            let y0 = this.mapY(0);
            points.unshift(points[0].replace(/,.*/, `,${y0}`));
            points.push(points.at(-1).replace(/,.*/, `,${y0}`));
            return points.join(" ");
        },
        colorIndex(i) {
            if (!this.isComparison) return "5"; // color for single
            return this.comparisonById[this.wires[i].id].colorIndex;
        },
        gradient(i) {
            if (!this.isComparison) return "gradient-1";
            return `gradient-${this.colorIndex(i)}`;
        },
        pathClass(i) {
            if (!this.isComparison) return "";
            return `path-${this.colorIndex(i)}`;
        },
        pathIsShowing(i) {
            if (!this.isComparison) return true;
            return this.comparisonById[this.wires[i].id].isShowing;
        },
        pathIsHighlighted(i) {
            if (!this.isComparison) return true;
            return this.comparisonHighlightId == this.wires[i].id;
        },
        // todo click anywhere else should close popup. not just this element.
        clickX(x = false) {
            // no argument/false un-clicks
            if (x === false) {
                this.popupClick = false;
                this.popupX = false;
            } else {
                this.popupClick = true;
                this.popupX = x;
            }
        },
        hoverX(x = false) {
            // no argument/false un-hovers
            // hover only when not clicked
            if (this.popupClick) return;
            this.popupX = x;
        },
        cssVW(px) {
            // js version of scss vw() because scss functions not processed after v-bind()
            return (px * 100) / 1024 + "vw";
        },
        toFixed(n) {
            // AVGW-222 limit to 3 decimal places remove trailing zeros
            return Number(Number(n).toFixed(3));
        },
    },
};
</script>
<style lang="scss">
@import "@/assets/scss/base/variables";
svg text {
    stroke: none;
}
svg.graph {
    width: v-bind(cssWidth);
    height: v-bind(cssHeight);
}
// svg style only.  html in scss file
// no vw here.  svg can be sized externally.
.graduations {
    stroke: black;
}
path {
    stroke-width: 2;
    // blue default for single
    stroke: #0697d6;
}
.color-single {
    color: #0697d6;
}
@each $i, $color in $comparison-colors {
    .color-#{$i} {
        color: $color;
    }
    .path-#{$i} {
        stroke: $color;
    }
}

.labels {
    font-size: 9px; // no vw units required inside svg. whole thing scales.
    fill: black;
    font: normal normal bold 11px BrandonGrotesque;
    // space in name does not work with font: in svg for html2canvas
    &.x {
        text-anchor: middle;
    }
}
.axis-label {
    fill: #0095da; // $abbott-blue
    font: normal normal bold 12px BrandonGrotesque;
    // text-transform: uppercase;
}
// animation
// nice to have animate again when data changes
// nice to have use exact path length for animation https://jakearchibald.com/2013/animated-line-drawing-svg/
path {
    stroke-dasharray: 1500;
    stroke-dashoffset: 1500;
    animation: dash 2s ease-out forwards;
    animation-iteration-count: 1;
    // animation-delay: 1s;
}
// todo noanimate for screenshots
// this doesn't work due to scoped
// tried removing scoped and wrapping all this in a class but that screwed up comparison graph
// continue later
.no-animate path {
    animation: none;
    stroke-dasharray: none;
}
@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}
</style>
