<template>
    <div class="compare-pdf-page2">
        <!-- WireGraph width and height here are relative to 1024 width -->
        <div class="spacer"></div>
        <WireGraph
            v-if="comparisonProperty == 'supportLevel'"
            :wires="comparisonWiresWithGraph"
            width="880"
            height="482"
            :isComparison="true"
            class="pdf-graph convert-to-jpg"
        />
        <BarGraph
            v-else
            :wires="comparisonWires"
            :property="comparisonProperty"
        />
        <div class="spacer"></div>
        <ComparePDFGraphLegend />
        <div
            v-if="getIsLastPdfComparisonProperty"
            class="disclaimer"
            @click.prevent="$root.linkNative($event)"
            v-html="disclaimer"
        ></div>
    </div>
</template>

<script>
import WireGraph from "@/components/WireGraph.vue";
import { mapGetters } from "vuex";
import ComparePDFGraphLegend from "./ComparePDFGraphLegend.vue";
import { COMPARISON_PROPERTY_NAMES } from "@/constants";
import BarGraph from "./BarGraph.vue";
export default {
    props: ["comparisonProperty"],
    components: {
        WireGraph,
        ComparePDFGraphLegend,
        BarGraph,
    },
    computed: {
        ...mapGetters([
            "comparisonWiresWithGraph",
            "comparisonWires",
            "getComparisonPdfTemplate",
            "getIsLastPdfComparisonProperty",
        ]),
        disclaimer() {
            return this.getComparisonPdfTemplate?.content?.disclaimer ?? "";
        },
        comparisonPropertyValue() {
            return COMPARISON_PROPERTY_NAMES[this.comparisonProperty];
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
.compare-pdf-page2 {
    .spacer {
        // avoid margins because they generate extra page
        // and avoid padding on wireGraph because it multiplies with htmlToPdf()
        padding-bottom: vwp(20px);
    }
    .bar-graph-container {
        margin-left: vwp(45px);
        width: vwp(693px);
        .graph {
            // gap between bars
            @include gap-compat-1(vwp(12px), column);
            .bar {
                height: vwp(25px);
            }
        }
    }
    .pdf-graph {
        margin-left: vwp(45px);
    }
    .compare-pdf-graph-legend {
        margin-left: vwp(45px);
    }
    .disclaimer {
        margin-left: vwp(45px);
        margin-right: vwp(140px);
        padding-top: vwp(40px);
        // font-family: MercuryTextG1;
        // font-family: Times-Roman;
        font-family: Georgia;
        font-weight: 400;
        font-size: vwp(9px);
        line-height: vwp(11px);
    }
}
</style>
