<template>
    <!-- https://stackoverflow.com/questions/61689445/vue-data-object-is-passed-as-a-reference-always-or-sometimes-it-is-copied#:~:text=on%20this%20post.-,Vue%20does%20not%20take%20a%20copy%20when%20you%20pass%20objects,also%20impact%20the%20other%20parent. -->
    <div class="modal-pdf-viewer modal" v-if="pdfSource">
        <!-- 
            requires to run @/../latest_vue-pdf-embed.sh
            fix for iOS: Canvas area exceeds the maximum limit (width * height > 16777216).
            read comments in script for more info
        -->
        <vue-pdf-embed
            :source="pdfSource"
            width="1024"
            :scale="scale"
            :page="currentPage"
            ref="pdfRef"
            v-dragscroll="$isWeb"
            class="scroll-y"
            @rendered="onRender"
        />
        <div class="toolbar">
            <div class="name">{{ name }}</div>
            <div class="page-control">
                <button
                    :disabled="currentPage <= 1"
                    @click="
                        currentPage--;
                        resetScroll();
                    "
                >
                    <!-- could minimize this with css. -->
                    <img
                        class="rot-180"
                        src="@/assets/images/Icon_Arrow_Blue.svg"
                    />
                    <img
                        class="rot-180 disabled"
                        src="@/assets/images/Icon_Arrow_Grey.svg"
                    />
                </button>
                <span>Page {{ currentPage }} of {{ pageCount }}</span>
                <button
                    :disabled="currentPage >= pageCount"
                    @click="
                        currentPage++;
                        resetScroll();
                    "
                >
                    <img src="@/assets/images/Icon_Arrow_Blue.svg" />
                    <img
                        class="disabled"
                        src="@/assets/images/Icon_Arrow_Grey.svg"
                    />
                </button>
            </div>
            <div class="right">
                <div v-if="isiOS" @click="iosSavePrint" class="share">
                    <div class="label">SHARE<br />OPTIONS</div>
                    <button>
                        <img src="@/assets/images/Icon_Share.svg" />
                    </button>
                </div>
                <button v-if="!isiOS" @click="save" class="save">
                    <img class="save" src="@/assets/images/Icon_Save.svg" />
                </button>
                <button v-if="!isiOS" @click="print" class="print">
                    <img class="print" src="@/assets/images/Icon_Print.svg" />
                </button>
                <button @click="close">
                    <img
                        class="icon-x"
                        src="@/assets/images/Icon_X_White.svg"
                    />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed";
import tbaWrapper from "@/helpers/tbaWrapper";
import { base64EncArr } from "@/helpers/tbaWrapper/MozillaBase64";
// import { downloadBlob } from "@/helpers/FileHelper";
// above import causes iOS to just fail. probably circular depencendy or timing issue
// copied the function here. todo sort out later
function downloadBlob(blobOrDataUri, name = "file.txt") {
    const blobUrl =
        blobOrDataUri instanceof Blob
            ? URL.createObjectURL(blobOrDataUri)
            : blobOrDataUri;
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = name;
    document.body.appendChild(link);
    link.dispatchEvent(
        new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
        })
    );
    document.body.removeChild(link);
}
//------------------------------------------------------------------------------

export default {
    props: [
        "pdfSource", // base64Uri or binary
        "name", // filename
        "scale", // optional. passed on to vue-pdf-embed
    ],
    components: {
        VuePdfEmbed,
    },
    data() {
        return {
            currentPage: 1,
            pageCount: 0,
            isAndroid: tbaWrapper.isAndroid,
            isiOS: tbaWrapper.isiOS,
        };
    },
    computed: {
        fileName() {
            // sanitize. Android removes spaces later
            return (
                this.name
                    ?.replace(/<\/?[^>]+(>|$)/g, "") // strip tags
                    .replace(/[^a-z0-9-_. ]/gi, "_") ?? "Saved-Document.pdf"
            );
        },
    },
    methods: {
        onRender() {
            console.log("onRender()");
            if (this.pdfSource) this.pageCount = this.$refs.pdfRef.pageCount; // todo don't use ref
            // document.querySelector(".vue-pdf-embed").pageCount;
        },
        close() {
            this.currentPage = 1;
            this.$emit("close");
        },
        save() {
            switch (tbaWrapper.platform) {
                case "ios":
                    this.iosSavePrint();
                    break;
                case "android":
                    this.androidSave();
                    break;
                default: // web
                    this.webSave();
                    break;
            }
        },
        print() {
            switch (tbaWrapper.platform) {
                case "ios":
                    this.iosSavePrint();
                    break;
                case "android":
                    this.androidPrint();
                    break;
                default: // web
                    break;
            }
        },
        webSave() {
            downloadBlob(this.pdfSource, this.fileName);
        },
        async androidSave() {
            // Android does not like spaces
            let filename = this.fileName.replace(/[^a-z0-9-_.]/gi, "_");
            console.log("androdSave():", filename);
            await tbaWrapper.pFileSaveTo(filename, this.b64PdfSource());
            console.log("pdf saved");
        },
        async androidPrint() {
            let error = await tbaWrapper.pFilePutContents(
                this.fileName,
                this.b64PdfSource()
            );
            if (error) return;
            tbaWrapper.pPrintPdf(this.fileName);
        },
        dataUriToB64(uri) {
            return uri.substr(uri.indexOf(",") + 1);
        },
        b64PdfSource() {
            return typeof this.pdfSource == "string"
                ? this.dataUriToB64(this.pdfSource)
                : base64EncArr(this.pdfSource);
        },
        async iosSavePrint() {
            // save to local file
            let tempfile = "/temp/temp.pdf";
            let error = await tbaWrapper.pFilePutContents(
                tempfile,
                this.b64PdfSource()
            );
            if (error) return;
            // open native dialog
            tbaWrapper.cpToDownloads(
                tempfile,
                this.fileName,
                console.log,
                console.error
            );
        },
        resetScroll() {
            // there's more than one instance and using ref is frowned upon
            document
                .querySelectorAll(".vue-pdf-embed")
                .forEach((el) => el.scrollTo(0, 0));
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
.modal-pdf-viewer {
    background-color: white;
    display: flex;
    flex-direction: column;
    .rot-180 {
        transform: rotate(180deg);
    }
    img.disabled {
        display: none;
    }
    .icon-pdf {
        width: vw(18px);
        height: vw(20px);
        margin-left: vw(20px);
    }
    .save,
    .print {
        img {
            width: vw(20px);
            height: vw(20px);
            filter: invert(1);
        }
    }
    .icon-x {
        width: vw(14px);
        height: vw(14px);
    }
    button:disabled {
        img {
            display: none;
        }
        img.disabled {
            display: inline;
        }
    }
    .page-control {
    }
    .toolbar {
        height: vw(60px);
        padding-left: vw(30px);
        padding-right: vw(20px);
        background-color: #333;
        color: white;
        box-shadow: 0 0 vw(8px) #000000dd;
        z-index: 1; // for box-shadow
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: vw(14px);
        .name {
            max-width: vw(400px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .page-control {
            display: flex;
            align-items: center;
            // @include gap-compat-1(vw(20px), row);
            button {
                height: vw(60px);
                min-width: vw(60px);
                img {
                    width: vw(16px);
                }
            }
        }
        .right {
            display: flex;
            @include gap-compat-1(vw(10px));
            .share {
                color: #adadad;
                font-size: vw(11px);
                line-height: vw(12px);
                display: flex;
                align-items: center;
                text-align: right;
                cursor: pointer;
                @include gap-compat-1(vw(10px));
                img {
                    width: vw(17px);
                    height: vw(18px);
                }
            }
            button {
                height: vw(40px);
                width: vw(40px);
                border-radius: vw(20px);
                background-color: $abbott-dark-grey;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .vue-pdf-embed {
        flex: 1;
        background-color: #777;
        position: relative; // for offsetTop
        > div {
            margin-bottom: vw(12px);
            box-shadow: 0 0 vw(7px) #000000dd;
        }
    }
}
</style>
