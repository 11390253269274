// sharedphoto.js
export default {
    getThumbsCallBack: null,
    getPhotoCallBack: null,
    choosePhotoCallback: null,
    /**
     * Function to get a list of all photos from the shared photos on the device.  The base64 data returned in the call back for all available photos can be inserted into the src attribute of an image tag like this:<br />&lt;img alt="Embedded Image" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIA..." /&gt;
     * @method getSharedPhotoThumbs
     * @param {Function} callBack - (required), the call back function whose only argument for iOS is an array of key value pair objects with format: {name: "foo.png", base64data : "+tI4ZEgpzQn7RhIXia4uoX5Pl9mVtap"}.  Note: Anadroid the full path to the full sized image is returned for the name.
     */
    getSharedPhotoThumbs(callBack) {
        this.getThumbsCallBack = callBack;
        if (this.isiOS) {
            window.webkit.messageHandlers.getsharedphotothumbs.postMessage(
                "Getting shared photos"
            );
            // window.location = "getsharedphotothumbs://foo/bar?sid="+Math.random();
        } else if (this.isAndroid) {
            // no android equivalent
        }
    },

    /**
     * photo upload interface for all platforms
     * use this if your app is to run on android because android file input doesn't work.
     * @param {*} callback - function to accept [File or Blob] as parameter
     *      File is a type of Blob
     *      both can be added to FormData for upload with XMLHttpRequest
     *      nice to have: only give array when multiple
     * @param {boolean} multiple - optional. use choosePhotos() instead
     */

    choosePhotoInput: null,
    choosePhoto(callback, multiple = false) {
        this.choosePhotoCallback = callback;
        if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.choosePhoto(multiple);
        } else {
            // generate hidden file input that calls back on change and trigger click
            var input = document.createElement("input");
            input.setAttribute("type", "file");
            if (multiple) input.setAttribute("multiple", "multiple");
            input.style.display = "none";
            input.onchange = this.choosePhotoInputChanged;
            document.body.appendChild(input);
            this.choosePhotoInput = input;
            input.dispatchEvent(
                new MouseEvent("click", {
                    view: window,
                    bubbles: true,
                    cancelable: false,
                })
            );
        }
    },
    /**
     * choose multiple Photos
     *      don't forget to name the upload input with square brackets eg myfilesinput[]
     * @param {*} callback - function to accept [File or Blob] as parameter
     */
    choosePhotos(callback) {
        this.choosePhoto(callback, true);
    },
    choosePhotoAndroidReturned() {
        if (!this.isAndroid) return;
        // extra step here because old android would destroy the app
        // app would set a non-volatile flag and call native back on restart
        // could be removed now -sj
        // eslint-disable-next-line no-undef
        var localUrls = JSON.parse(tbaWrapperJ.chosenPhotoUrls());
        var blobs = [];
        var _this = this;
        (function next() {
            if (localUrls.length > 0) {
                _this.getBlobFromUrl(localUrls.shift(), function (blob) {
                    blobs.push(blob);
                    next();
                });
            } else {
                _this.choosePhotoCallback(blobs);
                _this.choosePhotoCallback = null;
            }
        })();
    },
    choosePhotoInputChanged() {
        // removed use of tbaWrapper todo test
        this.choosePhotoCallback([...this.files]);
        this.choosePhotoCallback = null;
        this.choosePhotoInput.remove();
        this.choosePhotoInput = null;
    },
    getBlobFromUrl(url, callback, asArray = false) {
        // binary safe
        var xhr = new XMLHttpRequest();
        xhr.responseType = "arraybuffer";
        xhr.open("GET", url, true);
        xhr.onload = function () {
            // response is unsigned 8 bit integer
            var responseArray = new Uint8Array(this.response);
            var mimetype = this.getResponseHeader("content-type"); // expecting 'image/jpeg'|'image/png'
            var fileextension = mimetype.split("/").pop();

            //console.log("received data length: " + responseArray.length);
            var imgblob = new Blob([responseArray], { type: mimetype });
            imgblob.name = "image." + fileextension;
            callback(asArray ? [imgblob] : imgblob);
        };
        xhr.onerror = function (e) {
            console.log("fetch error", e);
        };
        xhr.send();
    },

    /**
     * Function to dispatch the call back for receiving the shared photo thumbs
     */
    gotSharedPhotoThumbs(dataString) {
        if (this.getThumbsCallBack) {
            this.getThumbsCallBack(JSON.parse(dataString));
            this.getThumbsCallBack = null;
        }
    },

    /**
     * Function to retrieve the base64 code for a full sized shared photo off of the device based off of the file name returned from the getSharedPhotoThumbs function
     * @method getSharedPhoto
     * @param photoName {String} photoName - (required), the name of the photo as returned from the getSharedPhotoThumbs function
     * @param callBack {Function} callBack - (required), the call back function to execute; should only accept one argument which will be a string of the base64 code to use for the full size image
     */
    getSharedPhoto(photoName, callBack) {
        this.getPhotoCallBack = callBack;
        if (this.isiOS) {
            window.webkit.messageHandlers.getsharedphoto.postMessage(photoName);
            // window.location = "getsharedphoto://foo/bar?sid="+Math.random()+"&name="+photoName;
        }
    },

    /**
     * Function to dispatch call back
     */
    gotSharedPhoto(base64Data) {
        this.getPhotoCallBack(base64Data);
        this.getPhotoCallBack = null;
    },
};
