// utils.js -sj
// todo don't encapsulate. export each.
export default {
    index_by_id(a) {
        var by_id = {};
        if (!Array.isArray(a)) return by_id;
        for (var i = 0; i < a.length; i++) {
            by_id[String(a[i].id)] = a[i];
        }
        return by_id;
    },
    index_by(a, index_column) {
        var by_id = {};
        for (var i = 0; i < a.length; i++) {
            by_id[String(a[i][index_column])] = a[i];
        }
        return by_id;
    },
    distinct(column_name, rows_array) {
        var keys = {};
        for (var row of rows_array) {
            keys[row[column_name]] = 1;
        }
        return Object.keys(keys);
    },
    array_values(input) {
        // eslint-disable-line camelcase
        //  discuss at: https://locutus.io/php/array_values/
        // original by: Kevin van Zonneveld (https://kvz.io)
        // improved by: Brett Zamir (https://brett-zamir.me)
        //   example 1: array_values( {firstname: 'Kevin', surname: 'van Zonneveld'} )
        //   returns 1: [ 'Kevin', 'van Zonneveld' ]
        const tmpArr = [];
        let key = "";
        for (key in input) {
            tmpArr[tmpArr.length] = input[key];
        }
        return tmpArr;
    },
    // returns function to be used in array.sort():w

    // eg rows.sort(by_column('first_name'))
    // or rows.sort(by_column('first_name',desc=true))
    // console logging the array in chrome will have all of them show the last order because they are references to same object
    by_column(column_name, descending = false, numeric = false) {
        if (numeric) return (a, b) => a[column_name] - b[column_name];
        // conditional inversion with xor
        // bitwise xor `^` does logical xor for booleans
        return (a, b) =>
            descending ^ (a[column_name] <= b[column_name]) ? -1 : 1;
    },
    /*  not required
        wishlist todo same as above but with multiple columns with this signature
        ([column, asc_desc, numeric=false] | column, ...)
        variable arguments where each is either a column name string or an array of column name and asc/desc specifier
        name string means sort ascending
        asc_desc specifier is ascending when == 'ASC' (ignoring case) all other values mean descending
    by_columns(...args) {
        var arg = args.shift();
        var column, asc_desc;
        if (Array.isArray(arg)) {
            [column, asc_desc] = arg;
            descending = typeof asc_desc != 'string' || asc_desc.toUpperCase() != 'ASC';
        } else {
            column = arg;
            descending = false;
        }
        return function(a,b){
            if (a.column == b.column) return -1;
        } 
     }
    */
    get_blob_from_url(url, callback, encapsulate) {
        // binary safe
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "arraybuffer";

        xhr.onload = function () {
            // response is unsigned 8 bit integer
            var responseArray = new Uint8Array(this.response);
            var mimetype = this.getResponseHeader("content-type"); // expecting 'image/jpeg'|'image/png'
            var fileextension = mimetype.split("/").pop();

            //console.log("received data length: " + responseArray.length);
            var imgblob = new Blob([responseArray], { type: mimetype });
            imgblob.name = "image." + fileextension;

            callback(typeof encapsulate != "undefined" ? [imgblob] : imgblob);
        };

        xhr.send();
    },
    blobToDataURL(blob, callback) {
        var a = new FileReader();
        a.onload = function (e) {
            callback(e.target.result);
        };
        a.readAsDataURL(blob);
    },
    urlToDataUrl(url, callback) {
        this.get_blob_from_url(url, (blob) => {
            this.blobToDataURL(blob, callback);
        });
    },
    downloadBase64(url, callback) {
        this.urlToDataUrl(url, (dataUrl) => {
            callback(dataUrl.substr(dataUrl.indexOf(",") + 1));
        });
    },
    pDownloadBase64(url) {
        return new Promise((resolve) => {
            this.downloadBase64(url, (error) => resolve(error));
        });
    },
    arrayDiff(a, b) {
        // O(n)
        var bSet = new Set(b);
        return a.filter((x) => !bSet.has(x));
    },
    arrayIntersect(a, b) {
        var bSet = new Set(b);
        return a.filter((x) => bSet.has(x));
    },
    pImageMeta(url){   
        return new Promise((resolve) => {
            // https://stackoverflow.com/questions/11442712/get-width-height-of-remote-image-from-url
            const img = new Image();
            img.addEventListener("load", function() {
                resolve({width: this.naturalWidth, height: this.naturalHeight });
            });
            img.src = url;
        });
    },
};
