// ThrottleInterval.js - sj
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export default class {
    constructor(minInterval) {
        this.minInterval = minInterval;
        this.totalWait = 0;
        this.timeUsed = 0;
    }
    startInterval() {
        this.startTime = new Date().getTime();
    }
    async endInterval() {
        let elapsed = new Date().getTime() - this.startTime;
        this.timeUsed += elapsed;
        let remaining = this.minInterval - elapsed;
        if (remaining > 0) {
            this.totalWait += remaining;
            await delay(remaining);
            console.log(`remaining ${remaining}`);
        } else console.log(`${remaining}ms remaining ----------------------`);
    }
    logStats() {
        console.log(`timeUsed ${this.timeUsed}`);
        console.log(`totalWait ${this.totalWait}`);
    }
}
