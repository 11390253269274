// SessionRecordingHelper.js
/*
The user’s default email client will open with the ‘to’ email addresses pre-populated, an editable boiler plate subject line, and an editable message body containing: 
The follow up pseudo English notes pertaining to the session
What categories were viewed 
What guidewires were viewed
What comparisons were made

Store variables
        sessionWiresById: {`${id}`: {select wire info}}
        sessionWireIdViewCount: {id: count} // incremented on product page views (product,graph)
        sessionWireIdCompareCount: {id: count} // incremented on comparison page views
        sessionUniqueComparisonCount: {"[1,2]": count} // incremented on comparison page views

*/
import vm from "@/main";
import tbaWrapper from "@/helpers/tbaWrapper";
import store from "@/store";

// record wires viewed and compared
export function pageView() {
    if (!sessionRecordConditionsMet()) return;

    switch (vm.$route.name) {
        case "product":
        case "graph":
            console.log(vm.$route.params.wireId);
            // save wire
            setSessionWireById(vm.$route.params.wireId);
            // track wire views
            store.commit(
                "incrementSessionWireIdViewCount",
                vm.$route.params.wireId
            );
            break;
        case "compare":
        case "compare-big":
        case "compare-pdf":
            trackComparisonView();
            break;
    }
}
var comparePagesSet = new Set(["compare", "compare-big", "compare-pdf"]);
export function removedComparisonWire() {
    if (!sessionRecordConditionsMet()) return;
    // if removed a wire on a compare page it's a new comparison view
    if (comparePagesSet.has(vm.$route.name)) trackComparisonView();
}
function sessionRecordConditionsMet() {
    return (
        !vm.$store.state.pdf.isGeneratingPdf && vm.$store.state.isRecordingSession
    );
}
function trackComparisonView() {
    var sortedIds = [...store.state.compareWireIds].sort();
    // skip if no comparison wires
    if (!sortedIds.length) return;
    // save and track each comparison wire
    for (const id of sortedIds) {
        setSessionWireById(id);
        store.commit("incrementSessionWireIdCompareCount", id);
    }
    // track unique comparison
    store.commit(
        "incrementSessionUniqueComparisonCount",
        JSON.stringify(sortedIds)
    );
}
function setSessionWireById(id) {
    store.commit(
        "setSessionWireById",
        wireProps(store.getters.guideWiresById[id])
    );
}
var wireProps = ({ id, name, category, maker }) => ({
    id,
    name,
    category,
    maker,
});

// No need to record every navigation.  tally stats instead.
// export function start() {
//     return {
//         name: "start",
//         params: {
//             page: vm.$route.name,
//         },
//     };
// }
// export function navigation() {
//     return {
//         name: "navigation",
//         params: {
//             page: vm.$route.name,
//         },
//     };
// }
// export function formattedSession() {
//     let ret = "";
//     for (const e of vm.$store.state.recordedSession) {
//         ret += `${e.name}\n`;
//         for (const [k, v] of Object.entries(e.params)) {
//             ret += `    ${k}: ${v}\n`;
//         }
//     }
//     return ret;
// }
function sessionNotes() {
    let ret = "";
    var wire;
    var categoryMakerViewCounts = {};
    var categoryMaker;
    var viewCountsStr = "";
    for (const [id, count] of Object.entries(
        store.state.sessionWireIdViewCount
    ).sort(descending)) {
        wire = store.state.sessionWiresById[id];
        // count category views
        categoryMaker = `${wire.category} - ${wire.maker}`;
        categoryMakerViewCounts[categoryMaker] =
            (categoryMakerViewCounts[categoryMaker] ?? 0) + count;
        // individual views to be displayed after
        viewCountsStr += `    Views: ${count} -  ${wire.category} - ${wire.maker} - ${wire.name}\n`;
    }

    ret += "What categories were viewed:\n";
    for (const [categoryMaker, count] of Object.entries(
        categoryMakerViewCounts
    ).sort(descending)) {
        ret += `    Views: ${count} -  ${categoryMaker}\n`;
    }

    ret += "What guidewires were viewed:\n";
    ret += viewCountsStr;

    ret += "What comparisons were made\n";
    var idUniqueComparisonCount = Object.entries(
        store.state.sessionUniqueComparisonCount
    ).sort(descending);
    for (const [jsonIds, count] of idUniqueComparisonCount) {
        ret += `    Views: ${count}\n`;
        for (const id of JSON.parse(jsonIds)) {
            wire = store.state.sessionWiresById[id];
            ret += `        ${wire.category} - ${wire.maker} - ${wire.name}\n`;
        }
    }

    ret += "Most Compared Wires\n";
    for (const [id, count] of Object.entries(
        store.state.sessionWireIdCompareCount
    ).sort(descending)) {
        wire = store.state.sessionWiresById[id];
        ret += `    Views: ${count} -  ${wire.category} - ${wire.maker} - ${wire.name}\n`;
    }

    // gmail app requires proper email format CRLF 0D0A
    ret = ret.replaceAll("\n", "\r\n");

    return ret;
}
// sorts Object.entries() values
var descending = (a, b) => b[1] - a[1];
export function openEmailClient() {
    let subject = "Guidewire Session Information";
    let message = sessionNotes();
    tbaWrapper.openEmailClient(
        subject,
        message,
        [], // attachments
        false, // isMainBundle
        [], // to
        [], // cc
        [], // bcc
        false // isHtml
    );
}
