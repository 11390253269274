// fileio.js
export default {
    /* eslint-disable */
    readStringCallback: null,
    notificationTrigger: null,
    readFileSuccess: null,
    readFileFailure: null,
    /**
     * Write a string to a file.  Assumes this is a new file (e.g. doesn't append).
     * @method writeStringToFile
     * @param {String} theString - (required), The string that is to be written to file
     * @param {String} path - (required), The path to the file that is to be created that resides within the documents directory where '/' is the root of the documents directory
     */
    writeStringToFile(theString, path, callBack) {
        if (callBack) {
            this.genericCallbackHolder = callBack;
        } else {
            this.genericCallbackHolder = null;
        }
        if (this.isiOS) {
            window.webkit.messageHandlers.writestring.postMessage({
                path: path,
                string: theString,
            });
            //window.location = "writestring://foo/bar?sid="+Math.random()+"&path="+this.Base64.encode(path)+"&string="+this.Base64.encode(theString);
        }
    },

    /**
     * Read a a file into a string.
     * @method readStringFromFile
     * @param {String} path - (required), The path to the file that is to be created that resides within the documents directory where '/' is the root of the documents directory
     * @param {Function} callBack - (required), The function to call when the string has been read.  Should have only one argument that accepts the return string.
     */
    readStringFromFile(path, callBack) {
        this.readStringCallback = callBack;
        if (this.isiOS) {
            window.webkit.messageHandlers.writestring.postMessage(path);
            //window.location = "readstring://foo/bar?sid="+Math.random()+"&path="+this.Base64.encode(path);
        }
    },

    /**
     * Event listener method to inform you that the current unzip process has completed.
     */
    readStringCompleted(theString) {
        if (this.readStringCallback) {
            this.readStringCallback(theString);
        }
    },
    /**
     * Function to create a screen shot of the currently display application window - currently only supported on desktop
     * @method writeScreenShotToPath
     * @param {String} path - (required ), path as to where to write the image within the documents directory.  '/' is considered the root of the docs dir.  Paths must end in .png; as the file written is PNG.
     */
    writeScreenShotToPath(path) {
        if (this.isDesktop) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.writeScreenShotToPath(this.Base64.encode(path));
        } else if (this.isiOS) {
            window.webkit.messageHandlers.writeScreenShotToPath.postMessage(
                path
            );
        }
    },

    /**
     * Function to write a folder full of PNGs into a PDF (one per page).  Only supported on desktop
     * @method writePNGSToPDF
     * @param {String} src - (required), the directory path containing the PNGs to write (only png files will be written); must be a directory
     * @param {String} dest - (required), the path to the PDF file name to be created and written to.
     */
    writePNGSToPDF(src, dest) {
        if (this.isDesktop) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.writePNGSToPDF(
                this.Base64.encode(src),
                this.Base64.encode(dest)
            );
        } else {
            //do nothing
        }
    },
    getImageFromFile(url, callBack) {
        if (this.isiOS) {
            if (callBack) {
                this.genericCallbackHolder = callBack;
            } else {
                this.genericCallbackHolder = null;
            }
            window.webkit.messageHandlers.getfiledata.postMessage(url);
            // window.location = "custom://foo/bar?sid="+Math.random()+"&query="+this.Base64.encode(query+"");
        } else {
            callBack(url);
        }
    },
    /**
     * iOS only - generate thumbnail (png) for specified local file
     * example returned url "file:///{some_path}/Documents/myImage.png.100x32.png"
     * @param {string} file - local file
     * @param {number} width
     * @param {number} height
     * @param {function} callback - callback to receive local url as parameter
     */
    getThumbnailUrl(file, width, height, callback) {
        if (!this.isiOS) return;

        // native calls this back directly
        this.getThumbnailUrlCallback = callback;
        window.webkit.messageHandlers.getThumbnailUrl.postMessage(
            JSON.stringify({ file: file, width: width, height: height })
        );
    },
    /**
     * Copy a file from source to downloads folder.  All files are stored within the documents directory of app; which in this case equates to '/'; src and dest paths must begin with '/'
     * iOS gets a dialog to save or print / share etc...
     * @method cpToDownloads
     * @param {String} path - (required), The path to the file that resides within the documents directory where '/' is the root of the documents directory
     * @param {String} dest - (required), Name of path in documents folder you want it to have
     */
    cpToDownloads(path, dest, success, error) {
        this.copyFileSuccess = success;
        this.copyFileError = error;
        if (this.isDesktop || this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.cpToDownloads(
                this.Base64.encode(path),
                this.Base64.encode(dest)
            );
        } else {
            window.webkit.messageHandlers.cpToDownloads.postMessage({
                path: path,
                dest: dest,
            });
        }
    },

    /**
     * use fileGetContents() for android, iOS doesn't need wrapper. just fetch()
     * this is missing android implementation
     * Reads a file and returns the contents as a string in success handler. Failure handler doesn't receive any arguments
     */
    readFile(path, success, failure) {
        this.readFileSuccess = success;
        this.readFileFailure = failure;
        if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.readFile(this.Base64.encode(path));
        }
        if (this.isiOS) {
            window.webkit.messageHandlers.readFile.postMessage(path);
        }
    },
    exportFile(filename, name) {
        if (this.isiOS) {
            window.webkit.messageHandlers.exportFile.postMessage({
                file: filename,
                name: name,
            });
        } else if (this.isAndroid) {
            tbaWrapperJ.exportFile(filename, name);
        }
    },
};
