// recording.js
export default {
    ttsCompleteCallback: null,
    isSpeakingCallBack: null,
    languageCodeCallBack: null,
    isRecordingCallBack: null,
    playRecordingCallBack: null,
    /**
     * Function for text to speech; only supported on iOS 7+ and Android 2.2+; speaks a text string only if a text string isn't already being spoken.
     * @method speak
     * @param {String} text - (required), the phrase to be spoken
     * @param {Number} pitch - (required), a decimal number that controls the pitch of speech.  1 is normal
     * @param {Number} rate - (required), a decimal number that controls the rate of speech; 1 is normal, 2 is twice as fast, and 0.5 is half as fast
     * @param {String} accent - (required), the country code representing the accent of the phrase to be spoken (see getLanguageCodes for a list of these that are available on the user's device)
     * @param {Function} completeCallback - (optional), to call when the speech utterance is completed
     * @return {Boolean} - Whether the utterance will be spoken or not based on whether it is supported by the current environment
     */
    speak(text, pitch, rate, accent, completeCallback) {
        if (completeCallback) {
            this.ttsCompleteCallback = completeCallback;
        }
        if (this.iOSVersion >= 7 || this.androidVersion >= 2.2) {
            if (this.isiOS) {
                window.webkit.messageHandlers.speak.postMessage({
                    text: text,
                    pitch: pitch,
                    rate: rate,
                    accent: accent,
                });
                // window.location = "speak://foo/bar?sid="+Math.random()+"&text="+this.Base64.encode(text)+"&pitch="+pitch+"&rate="+rate+"&accent="+accent;
            }
            return true;
        }
        return false;
    },

    /**
     * Function to derrive what language accents are available for the text to speech funcitonality; this list varies depending on the locale of the user and is determined at the OS level
     * @method getLanguageCodes
     * @param {Function} callBack - (required), Call back funciton that receives an array in the following format as its only argument: [{code : "US", display : "US English"}...];
     */
    getLanguageCodes(callBack) {
        this.languageCodeCallBack = callBack;
        if (this.iOSVersion >= 7 || this.androidVersion >= 2.2) {
            if (this.isiOS) {
                window.webkit.messageHandlers.getlanguagecodes.postMessage(
                    "getting codes"
                );
                // window.location = "getlanguagecodes://foo/bar?sid="+Math.random();
            }
        }
    },

    /**
     * Function to ask if the iOS or Android operating system is currently speaking
     * @method isSpeaking
     * @param {Function} callBack - (required), the call back function to process the response from the native hook; the function should receive one boolean argument to pass the result through
     * @return {Boolean} - Whether the answer could be given or not depending on whether the current execution environment supports this function
     */
    isSpeaking(callBack) {
        this.isSpeakingCallBack = callBack;
        if (this.iOSVersion >= 7 || this.androidVersion >= 2.2) {
            if (this.isiOS) {
                window.webkit.messageHandlers.isspeaking.postMessage(
                    "checking speech"
                );
                //window.location = "isspeaking://foo/bar?sid="+Math.random();
            }
            return true;
        }
        return false;
    },
    /**
     * Function to pause current speech utterance - has no effect if not speaking already
     * @method pauseSpeech
     */
    pauseSpeech() {
        if (this.iOSVersion >= 7 || this.androidVersion >= 2.2) {
            if (this.isiOS) {
                window.webkit.messageHandlers.pausespeech.postMessage(
                    "pausing speech"
                );
                // window.location = "pausespeech://foo/bar?sid="+Math.random();
            }
        }
    },

    /**
     * Function to resume current speech utterance - has no effect if not paused already
     * @method resumeSpeech
     */
    resumeSpeech() {
        if (this.iOSVersion >= 7 || this.androidVersion >= 2.2) {
            if (this.isiOS) {
                window.webkit.messageHandlers.resumespeech.postMessage(
                    "resuming speech"
                );
                //window.location = "resumespeech://foo/bar?sid="+Math.random();
            }
        }
    },

    /**
     * Function to stop current speech utterance - has no effect if not speaking already
     * @method stopSpeech
     */
    stopSpeech() {
        if (this.iOSVersion >= 7 || this.androidVersion >= 2.2) {
            if (this.isiOS) {
                window.webkit.messageHandlers.stopspeech.postMessage(
                    "stopping speech"
                );
                // window.location = "stopspeech://foo/bar?sid="+Math.random();
            }
        }
    },

    /**
     * Function that gets called when a speech utterance is completed
     */
    didFinishSpeaking() {
        this.ttsCompleteCallback();
        this.ttsCompleteCallback = null;
    },

    /**
     * Function to dispatch the call back for the isSpeaking funciton
     * @param answer - boolean, the answer received
     */
    isSpeakingAnswer(answer) {
        this.isSpeakingCallBack(answer);
        this.isSpeakingCallBack = null;
    },

    /**
     * Function to dispatch the call back for the language code funciton
     * @param answer - boolean, the answer received
     */
    languageCallBack(answer) {
        this.languageCodeCallBack(answer);
        this.languageCodeCallBack = null;
    },

    /**
     * Function to check if an audio recording is underway
     * @method isRecording
     * @param {Function} callBack - (required), a call back function whose only argument is a boolean
     */
    isRecording(callBack) {
        if (callBack != null) {
            this.isRecordingCallBack = callBack;
            if (this.isiOS) {
                window.webkit.messageHandlers.isrecording.postMessage(
                    "Checking is recording"
                );
            } else if (this.isAndroid) {
                // eslint-disable-next-line no-undef
                tbaWrapperJ.isRecording();
            }
        }
    },

    /**
     * dispatch the callback for isRecording()
     * @param {boolean} isRec - true while recording
     */
    isRecordingAnswer(isRec) {
        if (this.isRecordingCallBack) {
            this.isRecordingCallBack(isRec);
            this.isRecordingCallBack = null;
        }
    },

    /**
     * Function to record a audio file from the mobile device's microphone.  Won't do anything if a recording is already in progress - only supported on iOS
     * @method startRecording
     * @param {String} path - (required), The path to the file to record; it is stored in the documents directory, where '/' is the root of the documents directory.  For iOS; filenames must end in .m4a
     */
    startRecording(path) {
        if (this.isiOS) {
            window.webkit.messageHandlers.startrecording.postMessage(path);
            // window.location = "startrecording://foo/bar?sid="+Math.random()+"&path="+this.Base64.encode(path);
        } else if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.startRecording(this.Base64.encode(path));
        }
    },

    /**
     * extension of filename to provide startRecording
     * nice to have: wrapper accept only basename and return the full name
     */
    recordedAudioFileExtension() {
        if (this.isiOS) return "m4v";
        if (this.isAndroid) return "3gp";
        return "";
    },

    /**
     * Function to stop an audio recording from the mobile device's microphone. Won't do anything if a recording hasn't already been started.  Only supported on iOS
     * @method stopRecording
     */
    stopRecording() {
        if (this.isiOS) {
            window.webkit.messageHandlers.stoprecording.postMessage(
                "Stop recording"
            );
            // window.location = "stoprecording://foo/bar?sid="+Math.random();
        } else if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.stopRecording();
        }
    },

    /**
     * Function to play an audio file - presumably one that has been created from the recording funcitonality; but may work for other local file types.  Only supported on iOS
     * @method playRecording
     * @param {String} path - (required), a path to the file to play from the documents directory; where '/' is the root of the documents directory; for iOS should end in a .m4a extension
     * @param {Function} callBack - (optional), the call back funciton to call when the file has finished playing
     * @return {boolean} true if playing on android but always true on iOS. todo callback
     */
    playRecording(path, callBack) {
        if (callBack) {
            this.playRecordingCallBack = callBack;
        }
        if (this.isiOS) {
            return window.webkit.messageHandlers.playrecording.postMessage(
                path
            );
        } else if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            return tbaWrapperJ.playRecording(this.Base64.encode(path));
        }
    },

    /**
     * Function to stop the playback of an audio file.  Only supported on iOS
     * @method stopPlaying
     */
    stopPlaying() {
        if (this.isiOS) {
            window.webkit.messageHandlers.stopplaying.postMessage(
                "Stop Playing"
            );
            // window.location = "stopplaying://foo/bar?sid="+Math.random();
        } else if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.stopPlaying();
        }
        if (this.playRecordingCallBack) {
            this.playRecordingCallBack = null;
        }
    },

    /**
     * Function to dispatch the call back for the playRecording function
     */
    audioPlayerDidFinishPlaying() {
        if (this.playRecordingCallBack) {
            this.playRecordingCallBack();
            this.playRecordingCallBack = null;
        }
    },

};
