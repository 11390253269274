import fileio from "./fileio";
import fileutils from "./fileutils";
import sqlite from "./sqlite";
import apiquery from "./apiquery";
import filedownload from "./filedownload";
import geofencing from "./geofencing";
import location from "./location";
import ziparchive from "./ziparchive";
import recording from "./recording";
import uploadfile from "./uploadfile";
import orientation from "./orientation";
import sharedphoto from "./sharedphoto";
import createpdf from "./createpdf";
import cookies from "./cookies";
import misc from "./misc";
/** tbaWrapper -- js module
 * iOS only for now
 * --------------- perhaps this should be TBAWrapper.js - see https://arcticicestudio.github.io/styleguide-javascript/rules/naming_conventions.html#singleton-export
 * singleton for accessing native functionality;
 * sets tbaWrapper in global scope for native to call us back (if not already set)
 * exports the global tbaWrapper
 * desktop version of the JS bridge are tbaWrapper and tbaWrapperJ; so don't use these variable names elsewhere in your code
 * Don't try accessing the tbaWrapperJ variable directly; always use tbaWrapper.<br /><br />
 * This class works in conjunction with a config.json file that handles certain things that need to be setup prior to the DOM being initialized.  The config file has the following attributes:<br />
 * - window_title: The title to be given to the window; this is only applicable to the desktop version of the wrapper & will show in the window bar<br />
 * - open_full_screen: A boolean as to whether this app should open in full screen mode: only applicable to desktop version of the wrapper<br />
 * - background_color: A hex code string representing the color to show during loading of the window: only applicable to desktop verstion of the wrapper<br />
 * - preferred_width: A number representing the number of pixels in width this window should consume; is superseeded by open_full_screen if true: only applicable to desktop verstion of the wrapper<br />
 * - preferred_height: A number represenging the number of pixels in height this window should consume: is superseeded by open_full_screen if true: only applicable to desktop verstion of the wrapper<br />
 * - max_width: A number represenging the number of pixels in width this window is allowed to resize to: is superseeded by open_full_screen if true: only applicable to desktop verstion of the wrapper<br />
 * - max_height: A number represenging the number of pixels in height this window is allowed to resize to: is superseeded by open_full_screen if true: only applicable to desktop verstion of the wrapper<br />
 * - min_width: A number represenging the number of pixels in width this window is allowed to shrink to: is superseeded by open_full_screen if true: only applicable to desktop verstion of the wrapper<br />
 * - min_height: A number represenging the number of pixels in height this window is allowed to shrink to: is superseeded by open_full_screen if true: only applicable to desktop verstion of the wrapper<br />
 * - redirect_listeners: Array of Objects with the following string keys: listen_for, redir-params.  When a url change is caught; it is sent back to index.html with the redir-params attached as GET data<br />
 * - registerForPushNotifications: Boolean, if true the following pushData array is taken into consideration: Only Applicable to iOS<br />
 * - pushData: Array with the following keys: registrationURL (String), the URL to send device id registration requests to.  postDataString (string), url encoded post data to be sent along with the registration request.  The post key deviceid is appended to the string automatically.  pushRegistrationTypes (Array), array of strings representing the types of noticiations this device wants to receive.  Can be "Badge", "Alert", "Sound", "NewsStandContentAvailability".  Typically will just be Alert and Badge<br /><br />*Note: if you want to control the time signs up for push and send custom data with the device id (e.g. user id after they've logged in) set the registerForPushNotifications config to false, and use the registerForCustomPushNotifications function instead
 */
const tbaWrapper = {
    ...fileio,
    ...fileutils,
    ...sqlite,
    ...apiquery,
    ...filedownload,
    ...geofencing,
    ...location,
    ...ziparchive,
    ...recording,
    ...uploadfile,
    ...orientation,
    ...sharedphoto,
    ...createpdf,
    ...cookies,
    ...misc,
    /* eslint-disable */
    //-------------------------Private Member variables; not designed to be read only----------------------------//
    /**
     * This property can be used to discern if the wrapper is currently running as a desktop app
     * @property isDesktop
     * @type {Boolean}
     * @default "the current execution environment"
     */
    isDesktop: navigator.userAgent.indexOf("JavaFX") > -1,

    /* getters */
    /**
     * This property can be used to discern if the wrapper is currently running as an iOS app
     * @property isiOS
     * @type {Boolean}
     * @default "the current execution environment"
     */
    // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
    get isiOS() {
        return (
            [
                "iPad Simulator",
                "iPhone Simulator",
                "iPod Simulator",
                "iPad",
                "iPhone",
                "iPod",
            ].includes(navigator.platform) ||
            (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        );
    },
    /**
     * This property can be used to discern if the wrapper is currently running as an android app
     * @property isAndroid
     * @type {Boolean}
     * @default "the current execution environment"
     */
    get isAndroid() {
        // https://davidwalsh.name/detect-android
        return navigator.userAgent.toLowerCase().indexOf("android") > -1;
    },
    /**
     * current platform
     * use this in a switch statement instead of multiple calls to is* getters
     * to avoid unnecessary execution and improve readability.
     * returns "ios" | "android" | "desktop" | ""
     * "" === unsupported by this wrapper eg web
     * @property platform
     * @type {string}
     */
    get platform() {
        return (
            this._platform ??
            (this._platform = this.isiOS
                ? "ios"
                : this.isAndroid
                ? "android"
                : this.isDesktop
                ? "desktop"
                : "")
        );
    },
    /**
     * private precalc platform
     */
    _platform: null,
    /**
     * This property can be used to discern the version of iOS that is being run, 0 if not iOS
     * @property iOSVersion
     * @type {Number}
     * @default 0
     */
    get iOSVersion() {
        return this.isiOS
            ? parseFloat(
                  /(([0-9]){1,2}([_]){1}([0-9]){1}){1}/
                      .exec(navigator.userAgent)[0]
                      .replace("_", ".")
              )
            : 0;
    },
    /**
     * todo implement. where is implementation? -sj
     * This property can be used to discern the version of Android that is being run, 0 if not Android
     * @property androidVersion
     * @type {Number}
     * @default 0
     */
    androidVersion: 0,
    genericCallbackHolder: null,
    createPNGCallBack: null,

    /**
     *
     *  Base64 encode / decode
     *  http://www.webtoolkit.info/
     *
     */
    Base64: {
        // private property
        _keyStr:
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
        /**
         * Function to base64 encode a string; can be useful for transmitting formatted text as GET or POST data
         * @method Base64.encode
         * @param {String} input - The string to be encoded
         * @return {String} - The encoded string
         */
        encode: function (input) {
            var output = "";
            var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
            var i = 0;
            input = this._utf8_encode(input);
            while (i < input.length) {
                chr1 = input.charCodeAt(i++);
                chr2 = input.charCodeAt(i++);
                chr3 = input.charCodeAt(i++);
                enc1 = chr1 >> 2;
                enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                enc4 = chr3 & 63;
                if (isNaN(chr2)) {
                    enc3 = enc4 = 64;
                } else if (isNaN(chr3)) {
                    enc4 = 64;
                }
                output =
                    output +
                    this._keyStr.charAt(enc1) +
                    this._keyStr.charAt(enc2) +
                    this._keyStr.charAt(enc3) +
                    this._keyStr.charAt(enc4);
            }
            return output;
        },

        /**
         * Function to base64 decode a string; can be useful for transmitting formatted text as GET or POST data
         * @method Base64.decode
         * @param {String} input - The string to be decoded
         * @return {String} - The decoded string
         */
        decode: function (input) {
            var output = "";
            var chr1, chr2, chr3;
            var enc1, enc2, enc3, enc4;
            var i = 0;
            // eslint-disable-next-line no-useless-escape
            input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
            while (i < input.length) {
                enc1 = this._keyStr.indexOf(input.charAt(i++));
                enc2 = this._keyStr.indexOf(input.charAt(i++));
                enc3 = this._keyStr.indexOf(input.charAt(i++));
                enc4 = this._keyStr.indexOf(input.charAt(i++));
                chr1 = (enc1 << 2) | (enc2 >> 4);
                chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
                chr3 = ((enc3 & 3) << 6) | enc4;
                output = output + String.fromCharCode(chr1);
                if (enc3 != 64) {
                    output = output + String.fromCharCode(chr2);
                }
                if (enc4 != 64) {
                    output = output + String.fromCharCode(chr3);
                }
            }
            output = this._utf8_decode(output);
            return output;
        },
        // private method for UTF-8 encoding
        _utf8_encode: function (string) {
            string = "" + string; //in case a non string version is passed
            string = string.replace(/\r\n/g, "\n");
            var utftext = "";
            for (var n = 0; n < string.length; n++) {
                var c = string.charCodeAt(n);
                if (c < 128) {
                    utftext += String.fromCharCode(c);
                } else if (c > 127 && c < 2048) {
                    utftext += String.fromCharCode((c >> 6) | 192);
                    utftext += String.fromCharCode((c & 63) | 128);
                } else {
                    utftext += String.fromCharCode((c >> 12) | 224);
                    utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                    utftext += String.fromCharCode((c & 63) | 128);
                }
            }
            return utftext;
        },
        // private method for UTF-8 decoding
        _utf8_decode: function (utftext) {
            var string = "";
            var i = 0;
            var c = 0,
                c2 = 0,
                c3 = 0; // jshint ignore:line
            while (i < utftext.length) {
                c = utftext.charCodeAt(i);
                if (c < 128) {
                    string += String.fromCharCode(c);
                    i++;
                } else if (c > 191 && c < 224) {
                    c2 = utftext.charCodeAt(i + 1);
                    string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                    i += 2;
                } else {
                    c2 = utftext.charCodeAt(i + 1);
                    c3 = utftext.charCodeAt(i + 2);
                    string += String.fromCharCode(
                        ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
                    );
                    i += 3;
                }
            }
            return string;
        },
    },

    //-------------------------Public native hook member functions--------------------------//

    /**
     * Function to dispatch the call back for "generic" promises. Make sure not to run multiple TBA Wrapper calls at once.
     * @param response - Boolean
     */
    genericCallback(response) {
        if (this.genericCallbackHolder) {
            this.genericCallbackHolder(response);
            // this.genericCallbackHolder = null; //this breaks everything, you Dummy.
        } else {
            console.log("callback not set ", response);
        }
    },




    /* logs start */
    /**
     * Native console logging function; passes the argument to the console of the native system for ouput to the native console
     * @method log
     * @param {String} Message - (required), the message to be echoed
     */
    log(msg) {
        if (this.isiOS) {
            try {
                window.webkit.messageHandlers.log.postMessage(msg);
            } catch (e) {
                window.webkit.messageHandlers.log.postMessage(
                    "TBAWrapper.log [" + typeof msg + "]:" + e
                );
            }
        } else if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.log(this.Base64.encode(msg));
        } else {
            console.log("wrapper log: ", msg);
        }
    },
    /* logs end */


    /**
     * SSO using app server as a secure back channel because the client secret should not be stored in the app if there is an app server
     * Server can use any SSO implementation.
     *
     * @param {string} toUrl url
     * @param {string} returnUrl required when not ios. sent as parameter eg "https://app.server.host/appLogin?param1=foo&returnUrl=[RETURNURL]"
     * @param {webAuthCallbackType} callback required on iOS.
     */
    webAuth(toUrl, returnUrl = "", callback = null) {
        this.webAuthCallback = callback;
        if (this.isiOS) {
            let url = new URL(toUrl);
            url.searchParams.set("returnUrl", "webauth://tba"); // could be done natively but this works for now
            window.webkit.messageHandlers.webAuth.postMessage({
                toUrl: url.href,
            });
        } else if (this.isAndroid) {
            let url = new URL(toUrl);
            url.searchParams.set("returnUrl", "webauth://tba"); // could be done in java but this works for now
            tbaWrapperJ.webAuth(url.href, returnUrl);
        } else {
            let url = new URL(toUrl);
            url.searchParams.set("returnUrl", returnUrl);
            location.href = url.href;
        }
    },
    /**
     * iOS webAuth callback arguments
     * @callback webAuthCallbackType
     * @param {string} returnedUrl url or empty string if error or cancelled
     * @param {string} wrapperError error string or "cancelled" if user cancelled
     */
    webAuthCallback: null,
    webAuthReturned(callbackUrl, error) {
        if (this.webAuthCallback) {
            this.webAuthCallback(callbackUrl, error);
        }
    },
    webAuthAndroidReturnHack(hashRoute) {
        // because `window.location.href='#/sso?result=authorized'` does not work when run from android java
        // it replaces the contents of index.html with the string instead.
        // but it works properly when run from javascript console.
        // unknown if the issue is on the java or js side.
        // this is a hack because it's app specific
        window.router.push(this.ltrim(hashRoute, "#"));
    },

    // todo eventually use async return for all
    // https://stackoverflow.com/questions/65270083/how-does-the-ios-14-api-wkscriptmessagehandlerwithreply-work-for-communicating-w
    async getConfig(callback) {
        this.getConfigCallback = callback;
        if (this.isiOS) {
            window.webkit.messageHandlers.getConfig.postMessage("");
        } else if (this.isAndroid) {
            tbaWrapperJ.getConfig();
        } else {
            let module = await import("./webConfig");
            callback(module.default);
        }
    },
    getConfigReturned(config) {
        this.getConfigCallback(config);
    },
    pGetConfig() {
        return new Promise((resolve) => {
            tbaWrapper.getConfig((config) => {
                console.log("pGetConfig resolving", config);
                resolve(config);
            });
        });
    },

    ltrim(str, charlist) {
        //  discuss at: https://locutus.io/php/ltrim/
        // original by: Kevin van Zonneveld (https://kvz.io)
        //    input by: Erkekjetter
        // improved by: Kevin van Zonneveld (https://kvz.io)
        // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
        //   example 1: ltrim('    Kevin van Zonneveld    ')
        //   returns 1: 'Kevin van Zonneveld    '
        charlist = !charlist
            ? " \\s\u00A0"
            : (charlist + "").replace(/([[\]().?/*{}+$^:])/g, "$1");
        const re = new RegExp("^[" + charlist + "]+", "g");
        return (str + "").replace(re, "");
    },




    /**
     * Light weight, internal convenience method for creating JSON strings from Objects or from Arrays.
     * @method array2json
     * @param {Object} arr - (required), An Object or an Array to turn into a JSON string
     * @return {String} - The JSON string representing the Object or Array that was parsed as an argument
     */
    array2json(arr) {
        var parts = [];
        var is_list = Object.prototype.toString.apply(arr) === "[object Array]";

        for (var key in arr) {
            var value = arr[key];
            if (typeof value == "object") {
                //Custom handling for arrays
                if (is_list)
                    parts.push(this.array2json(value)); /* :RECURSION: */
                else
                    parts.push(
                        '"' + key + '":' + this.array2json(value)
                    ); /* :RECURSION: */
                //else parts[key] = array2json(value); /* :RECURSION: */
            } else {
                var str = "";
                if (!is_list) str = '"' + key + '":';

                //Custom handling for multiple data types
                if (typeof value == "number") str += value; //Numbers
                else if (value === false) str += "false"; //The booleans
                else if (value === true) str += "true";
                // eslint-disable-next-line no-useless-escape
                else str += '"' + value.replace(/"/g, '\\"') + '"'; //All other things
                // :TODO: Is there any more datatype we should be in the lookout for? (Functions?)

                parts.push(str);
            }
        }
        var json = parts.join(",");

        if (is_list) return "[" + json + "]"; //Return numerical JSON
        return "{" + json + "}"; //Return associative JSON
    },


    /* logs start */
    showLog: function () {
        this.getroot(function (root) {
            // eslint-disable-next-line no-useless-escape
            var timestamp = JSON.stringify(new Date())
                .split("T")[0]
                .replace(/[\"-]/g, "");
            document.location =
                "file://" + root + "/console." + timestamp + ".log";
        });
    },

    /* logs end */


    getBuildVersion(callback) {
        if (this.isiOS) {
            this.getBuildVersionCallback = callback;
            window.webkit.messageHandlers.getBuildVersion.postMessage("");
        } else {
            callback("0.0");
        }
    },


    /**
     * Callback for getFirebaseInstanceId()
     *
     * @callback getFirebaseInstanceIdCallback
     * @param {string} error - hex app instance id or '' on Failure
     * @param {string} error - error message or '' on Success
     */
    getFirebaseInstanceIdCallback: null,
    getFirebaseInstanceIdIsRuning: false,
    // private cache.
    _firebaseInstanceId: null,
    /**
     * 32 char hex string required for GA4 Measurement Protocol when using firebase
     */
    getFirebaseInstanceId(callback) {
        if (this.getFirebaseInstanceIdIsRuning) {
            callback("", "getFirebaseInstanceId() is running");
        } else if (this._firebaseInstanceId) {
            callback(this._firebaseInstanceId);
        } else if (this.isAndroid) {
            this.getFirebaseInstanceIdIsRuning = true;
            this.getFirebaseInstanceIdCallback = callback;
            tbaWrapperJ.getFirebaseInstanceId();
        } else if (this.isiOS) {
            this.getFirebaseInstanceIdIsRuning = true;
            this.getFirebaseInstanceIdCallback = callback;
            window.webkit.messageHandlers.getFirebaseInstanceId.postMessage("");
        } else {
            callback(
                "",
                "getFirebaseInstanceId() is not implemented for current platform."
            );
        }
    },
    getFirebaseInstanceIdReturned(id, error) {
        this._firebaseInstanceId = id;
        this.getFirebaseInstanceIdCallback(id, error);
        this.getFirebaseInstanceIdIsRuning = false;
    },
    // iOS/Android need to use firebase instead of GA4 Measurement Protocol for Geographic information
    // seems ok to use Measurement Protocol with gtag on web though.  todo confirm
    firebaseLogEvent(event) {
        if (this.isAndroid) {
            tbaWrapperJ.firebaseLogEvent(
                event.name,
                JSON.stringify(event.params)
            );
        } else if (this.isiOS) {
            window.webkit.messageHandlers.firebaseLogEvent.postMessage(event);
        } else {
            console.error(
                "firebaseLogEvent() not implemented on current platform"
            );
        }
    },
    firebaseSetAnalyticsEnabled(enabled) {
        if (this.isAndroid) {
            tbaWrapperJ.firebaseSetAnalyticsEnabled(enabled);
        } else if (this.isiOS) {
            if (enabled)
                window.webkit.messageHandlers.firebaseAnalyticsEnable.postMessage(
                    {}
                );
            else
                window.webkit.messageHandlers.firebaseAnalyticsDisable.postMessage(
                    {}
                );
        } else {
            console.error(
                "firebaseSetAnalyticsEnabled() not implemented on current platform"
            );
        }
    },
    /**
     * hook android back button like this:
     * tbaWrapper.onBackPressed = MyFunction;
     * this exists for documentation
     * @returns {undefined}
     */
    onBackPressed: () => undefined,
};

/* logs start */
if (!globalThis.nativeLog && globalThis.consoleToFile && globalThis.console) {
    globalThis.nativeLog = globalThis.console.log;
    globalThis.console.log = function (p1, p2, p3) {
        globalThis.nativeLog(p1, p2, p3);
        if (arguments.length == 1) tbaWrapper.log(p1);
        else tbaWrapper.log([arguments[0], arguments[1]]);
    };
}
/* logs end */

// executes only once even if multiple includes
globalThis.tbaWrapper = tbaWrapper;
export default globalThis.tbaWrapper;
