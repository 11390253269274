<template>
    <StatusBar />
    <div id="main">
        <router-view />
    </div>
    <BottomBar />
    <ModalSync />
    <AppModals />
    <TestComponent />
</template>
<script>
import AppModals from "./components/AppModals.vue";
import BottomBar from "./components/BottomBar.vue";
import ModalSync from "./components/ModalSync.vue";
import StatusBar from "./components/StatusBar.vue";
import TestComponent from "./components/TestComponent.vue";
export default {
    // data(){
    //     return {
    //         lastState: "", // test if anything actually changed
    //     };
    // },
    components: {
        TestComponent,
        BottomBar,
        StatusBar,
        ModalSync,
        AppModals,
    },
    beforeCreate() {
        this.$store.dispatch("initialiseStore");
        if (this.$isWeb) document.body.classList.add("is-web");
    },
    created() {
        // this runs whether or not anything changed
        // tested with setCurrentStatusViewing mutation
        // if it doesn't alter state, getters are not triggered but this is
        // todo skip if no change without keeping a copy of state
        this.$store.subscribe((mutation, state) => {
            // todo save apiData separately because it's heavy and only updates on sync
            console.log("saving store");
            localStorage.setItem("store", JSON.stringify(state));
            // var s;
            // localStorage.setItem("store", s= JSON.stringify(state));
            // if (s != this.lastState) console.log("state Actually Changed");
            // else console.log("no change");
            // this.lastState = s;
        });
    },
};
</script>
<style lang="scss"></style>
