<template>
    <div class="bar-graph-labels">
        <div v-for="wire in wires" :key="wire.id" class="label">
            <div class="brand-type-name">
                <div class="brand-type">
                    {{ `${wire.maker} ${wire.category}` }}
                </div>
                <div class="name" v-html="wire.name"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["wires"],
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
.bar-graph-labels {
    width: vw(150px);
    padding-top: vw(20px);
    display: flex;
    flex-direction: column;
    @include gap-compat-1(vw(30px), column);
    .label {
        display: flex;
        height: vw(40px);
        align-items: center;
    }
    .brand-type-name {
        flex: 1;
        text-align: right;
        color: $abbott-dark-grey;
    }
    .brand-type {
        font: normal normal bold vw(11px) Calibri;
        line-height: vw(13px);
        text-transform: uppercase;
    }
    .name {
        font: normal normal normal vw(14px) Calibri;
        line-height: vw(17px);
    }
}
</style>
