<template>
    <div
        id="product-types-view"
        class="screenshot-expand scroll-y"
        v-dragscroll="$isWeb"
    >
        <ScrollForMore :forId="'product-types-view'" />
        <div class="product-types">
            <div class="type coronary">
                <div class="heading">CORONARY</div>
                <button
                    @click="$router.push(`/products/coronary/${currentMaker}`)"
                >
                    <img
                        src="@/assets/images/Icon_Products_White.svg"
                        class="products"
                    />
                    &nbsp;
                    <span>VIEW GUIDE WIRES</span>
                </button>
            </div>
            <div class="type peripheral">
                <div class="heading">PERIPHERAL</div>
                <button
                    @click="
                        $router.push(`/products/peripheral/${currentMaker}`)
                    "
                >
                    <img
                        src="@/assets/images/Icon_Products_White.svg"
                        class="products"
                    />
                    &nbsp;
                    <span>VIEW GUIDE WIRES</span>
                </button>
            </div>
        </div>
        <hr />
        <div class="footnote" v-html="getFooter" @click.prevent="$root.linkNative($event)"></div>
    </div>
</template>

<script>
import ScrollForMore from "@/components/ScrollForMore.vue";
import { mapMutations, mapGetters, mapState } from "vuex";
export default {
    components: { ScrollForMore },
    methods: {
        ...mapMutations(["setCurrentSection"]),
    },
    computed: {
        ...mapGetters(["getFooter"]),
        ...mapState(["currentMaker"]),
    },
    mounted() {
        this.setCurrentSection("products");
    },
};
</script>

<style lang="scss"></style>
