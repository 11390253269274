// sqlite.js
export default {
    /* eslint-disable */
    isExecuting: false,
    queryQueue: [],
    /**
     * Executes an sql query to interface with sqlite - consult http://sqlite.org/docs.html docs for syntax specifics.  Subsequent queries are queued until the first one completes
     * @method executeSQLQuery
     * @param {String} query - (required), the query to be executed in SQL format
     * @param {Array} bindings - (optional), an array of values to bind to any question marks in the query; this method uses prepared statements and automatically escapes strings so as to avoid SQL injection; e.g.: insert into foo(bar) values(?); could be a query, which would require a binding array with one value to bind to the ? place holder
     * @param {Function} callBack - (optional), the function to be executed when the query result is retured; Function should receive only one argument; an array of objects representing the resultset.
     */
    executeSQLQuery(query, bindings, callBack) {
        this.queryQueue.push({
            query: query,
            bindings: bindings,
            callBack: callBack,
        });
        if (this.queryQueue.length == 1) {
            this.nextQuery();
        }
    },

    nextQuery() {
        var query = this.queryQueue[0].query;
        var bindings = this.queryQueue[0].bindings;
        if (!this.isExecuting) {
            this.isExecuting = true;
            if (this.isDesktop) {
                // eslint-disable-next-line no-undef
                brackets.app.executeSQLQuery(
                    query,
                    bindings ? this.array2json(bindings) : "",
                    this.sqlQueryReturned
                );
            } else if (this.isAndroid) {
                setTimeout(
                    'tbaWrapperJ.executeSQLQuery("' +
                        this.Base64.encode(query) +
                        '","' +
                        this.Base64.encode(this.array2json(bindings)) +
                        '");',
                    1
                );
            } else {
                window.webkit.messageHandlers.sqlquery.postMessage({
                    query: query,
                    bindings: bindings,
                });
            }
        } else {
            this.log(
                "Error; you cannot run a query while another is executing"
            );
        }
    },

    /**
     * Returns the JSON fromt the currently executing SQL Query
     * @param result - A JSON Array string; each array item will be a JSON Object representing a row of the results returned
     *          if the query wasn't a select statement; an empty array will be returned; All error information is printed through the xcode / terminal console...
     */
    sqlQueryReturned(result) {
        this.isExecuting = false;
        if (this.queryQueue[0].callBack) {
            this.queryQueue[0].callBack(
                this.isAndroid ? JSON.parse(result) : result
            );
        }
        this.queryQueue.shift();
        if (this.queryQueue.length > 0) {
            this.nextQuery();
        }
    },
    /**
     * Begins an SQLite transaction; wrapping a series of inserts / updates in a transaction has significant performance increases for sqlite; don't forget to end your transaction.
     * @method beginTransaction
     */
    beginTransaction(callBack) {
        if (callBack) {
            this.genericCallbackHolder = callBack;
        } else {
            this.genericCallbackHolder = null;
        }
        if (this.isDesktop || this.isAndroid) {
            setTimeout("tbaWrapperJ.beginTransaction();", 1); // jshint ignore:line
        } else {
            window.webkit.messageHandlers.begintransaction.postMessage(
                "begin transaction"
            );
            // window.location = "begintransaction://foo/bar?sid="+Math.random();
        }
    },

    /**
     * Begins an SQLite transaction; wrapping a series of inserts / updates in a transaction has significant performance increases for sqlite; you must begin a transaction first
     * @method endTransaction
     */
    endTransaction(callBack) {
        if (callBack) {
            this.genericCallbackHolder = callBack;
        } else {
            this.genericCallbackHolder = null;
        }
        if (this.isDesktop || this.isAndroid) {
            setTimeout("tbaWrapperJ.endTransaction();", 1); // jshint ignore:line
        } else {
            window.webkit.messageHandlers.endtransaction.postMessage(
                "begin transaction"
            );
            // window.location = "endtransaction://foo/bar?sid="+Math.random();
        }
    },
};
