// ComparePdfHelper.js
import { jsPDF } from "jspdf";
import "@/helpers/jspdfAddFonts";
// used fontconverter.html from jspdf repo
// requires ttf. used online converter
// import "@/assets/fonts/jspdf/Calibri-bold";

// import "@/assets/fonts/jspdf/MercuryTextG1-Roman-normal"; // has issues
// import "@/assets/fonts/jspdf/MercuryTextG1-Semibold-normal";

import html2canvas from "html2canvas";
import { injectFont } from "@/helpers/PdfSvgFontHelper";
import store from "@/store";
import vm from "@/main";
import { PDFDocument } from "pdf-lib";
import ThrottleInterval from "@/helpers/ThrottleInterval";
import tbaWrapper from "@/helpers/tbaWrapper";

var throttleIos = new ThrottleInterval(600);

export async function webDownloadPdfPage() {
    (await generatePDF()).save(); // for ComparePDFDevView
}
// uses store.state.pdf.comparisonProperties
export async function generatePdfDoc() {
    return await concatPdfs(await generatePdfs());
}
// array of arraybuffer to pdf-lib PDFDocument
async function concatPdfs(binaryPdfs = []) {
    var pdfDoc = await PDFDocument.create();
    for (const pdf of binaryPdfs) {
        await appendPdf(pdfDoc, await PDFDocument.load(pdf));
    }
    return pdfDoc;
}
async function appendPdf(pdfDoc, appendPdfDoc, firstPageOnly = true) {
    let appendPages = await pdfDoc.copyPages(
        appendPdfDoc,
        appendPdfDoc.getPageIndices()
    );
    // quick fix for extra blank pages AVGW-223
    if (firstPageOnly) pdfDoc.addPage(appendPages[0]);
    else
        for (const p of appendPages) {
            pdfDoc.addPage(p);
        }
}

// const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// return array of binary pdfData - one page per pdf
async function generatePdfs() {
    var pdfs = [];
    // page 1
    tbaWrapper.log("Compare page 1");
    store.commit("setComparisonPageNum", "1");
    await vm.$nextTick();
    pdfs.push((await generatePDF()).output("arraybuffer"));

    // graph pages. shouldn't have been called page 2
    tbaWrapper.log("Compare page 2---------------------------------------");
    store.commit("setComparisonPageNum", "2");
    // save current property. - this should use a separate var.
    let savedComparisonProperty = store.state.comparisonProperty;
    // save and clear highlight
    let savedHighlightId = store.state.comparisonHighlightId;
    store.commit("comparisonHighlightIdSet", 0);
    // save/clear showing state
    showingStateSave();
    showAllWires();
    for (const property of store.state.pdf.comparisonProperties) {
        tbaWrapper.log("property", property);
        store.commit("setComparisonProperty", property);
        // graph not showing intermittently on Android
        await vm.$nextTick();
        await vm.$nextTick();
        // console.log("DELAYING====================================");
        // await delay(500);
        pdfs.push((await generatePDF()).output("arraybuffer"));
    }
    // restore current property
    store.commit("setComparisonProperty", savedComparisonProperty);
    // restore highlight
    store.commit("comparisonHighlightIdSet", savedHighlightId);
    showingStateRestore();

    store.commit("setComparisonPageNum", 0);
    return pdfs;
}
var savedHiddenIds = [];
function showingStateSave() {
    savedHiddenIds = [];
    for (const [id, vals] of Object.entries(store.state.comparisonById)) {
        if (!vals.isShowing) savedHiddenIds.push(id);
    }
}
function showingStateRestore() {
    // assumes all are showing
    for (const id of savedHiddenIds)
        store.commit("comparisonIsShowingToggle", id);
}
function showAllWires() {
    for (const [id, vals] of Object.entries(store.state.comparisonById)) {
        if (!vals.isShowing) store.commit("comparisonIsShowingToggle", id);
    }
}
async function generatePDF() {
    // return new jsPDF
    injectFont(document.querySelector("svg.graph"));
    await htmlToJpg();
    var page = document.getElementById("compare-pdf-page");
    var doc = new jsPDF(pdfOptions(page));
    throttleIos.startInterval();
    await pHtmlToPdf(page, doc);
    if (tbaWrapper.isiOS) await throttleIos.endInterval();
    return doc;
}
function pdfOptions(element) {
    var width = element.offsetWidth,
        height = element.offsetHeight;
    console.log("width", width);
    console.log("height", height);
    return {
        orientation: width > height ? "landscape" : "portrait",
        hotfixes: ["px_scaling"],
        unit: "px",
        // + 0.1 because something causes overflow and generate blank page - web chrome
        format: [width, height + 0.1],
    };
}
function pHtmlToPdf(element, jsPdf) {
    return new Promise((resolve) => {
        jsPdf.html(element, {
            callback: () => resolve(),
        });
    });
}
async function htmlToJpg() {
    // https://stackoverflow.com/questions/69493988/error-loading-svg-data-with-html2canvas-and-jspdf#:~:text=Convert%20svg%20to%20jpg%20first%2C%20then%20use%20jspdf.
    // let elements = document.getElementsByClassName("convert-to-jpg");
    let el;
    // can't use querySelectorAll() because it's a copy and doesn't work
    // can't use getElementsByClass because it changes dynamically and svg is skipped in the iteration even though it's there at the end
    // starts with 4 and ends with 2 elements including the svg but svg was skipped
    while ((el = document.querySelector(".convert-to-jpg"))) {
        console.log("=======rasterizing", el);
        if (isHidden(el)) {
            console.log("skipping hidden element");
            return;
        }
        throttleIos.startInterval();
        var width = el.offsetWidth;
        let canvas = await html2canvas(el, {
            // scale: 2480 / screenWidth, // 2480px - size for A4 paper, 300 dpi
        });
        var img = canvas.toDataURL("image/jpeg");
        el.innerHTML = `<img src="${img}" class="img" width="${width}">`;
        el.classList.remove("convert-to-jpg");
        el.style = "background-image: none;";
        console.log("should now have style");
        if (tbaWrapper.isiOS) await throttleIos.endInterval();
    }
}
function isHidden(el) {
    // https://stackoverflow.com/questions/19669786/check-if-element-is-visible-in-dom
    var style = window.getComputedStyle(el);
    return style.display === "none";
}
// async htmlToJpg() {
//     // attempt to convert only svg causes
//     // Uncaught (in promise) Unable to find element in cloned iframe
//     // in calling function
//     var el = document.querySelector(".convert-to-jpg");
//     var svg = el.querySelector("svg.graph");
//     var width = svg.offsetWidth;
//     let canvas = await html2canvas(svg, {
//         // scale: 2480 / screenWidth, // 2480px - size for A4 paper, 300 dpi
//     });
//     var img = canvas.toDataURL("image/jpeg");
//     await delay(1000);
//     el.innerHTML = `<img src="${img}" class="img" width="${width}">`;
// },

// // does not show data lines and font doesn't work
// svg2pdf() {
//     // would be nice to have vector
//     // https://www.npmjs.com/package/svg2pdf.js
//     const element = document.querySelector("svg.graph");
//     this.fontHack(element);
//     // const element = document.getElementById('testcircle');
//     var page = document.getElementById("compare-pdf-page");
//     const doc = new jsPDF(this.pdfOptions(page));
//     var x = 0,
//         y = 0,
//         width = 880,
//         height = 482;
//     doc.svg(element, {
//         x,
//         y,
//         width,
//         height,
//     }).then(() => {
//         // save the created pdf
//         doc.save("myPDF.pdf");
//     });
// },
// addSvgParams() {
//     const element = document.querySelector("svg.graph");
//     console.log(element);
//     // const svgData = element.outerHTML;
//     const svgData = document.getElementById("testcircle").outerHTML;
//     const domRect = element.getBoundingClientRect();
//     const x = domRect.x;
//     const y = domRect.y;
//     const width = domRect.width;
//     const height = domRect.height;
//     // (SVG-Data, x, y, width, height, alias, compression, rotation)
//     //return [svgData,x,y,width,height];
//     const args = [svgData, x, y, width, height, undefined, "FAST"];
//     console.log("arguments: ", args);
//     return args;
// },
