<template>
    <div
        id="modal-generate-pdf"
        class="modal"
        v-if="isGeneratingPdf"
        @click.self="modalReviewClose"
        data-html2canvas-ignore
    >
        <ModalProgress heading="Generating PDF" />
    </div>
    <ModalPdfView
        :pdf-source="pdfSource"
        @close="closePdfViewer"
        :name="pdfFilename"
        :scale="scale"
    />
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import ModalPdfView from "./ModalPdfView.vue";
import ModalProgress from "./ModalProgress.vue";
import { screenshot } from "@/helpers/ScreenshotHelper";
// pdf-lib for concatenating existing pdfs.
import { PDFDocument } from "pdf-lib";
import * as file from "@/helpers/FileHelper";
import tbaWrapper from "@/helpers/tbaWrapper";
import { generatePdfDoc as genComparisonPdfDoc } from "@/helpers/ComparePdfHelper";
import ThrottleInterval from "@/helpers/ThrottleInterval";
import { COMPARISON_PROPERTIES } from "@/constants";
import util from "@/helpers/util";

export default {
    components: { ModalPdfView, ModalProgress },
    data() {
        return {
            screenshotWidth: 1024,
            screenshotHeight: 768, // changes with page content
            pdfSource: "",
            // mat to paths
            matInfo: [
                {
                    filter: { category: "Lobby" },
                    paths: ["/lobby"],
                },
                {
                    filter: { category: "Landing" },
                    paths: ["/disclaimer", "/", "/product-types"],
                },
                {
                    filter: { category: "Coronary", maker: "Abbott" },
                    paths: [
                        "/products/coronary/Abbott",
                        "/product/*", // * = wire_id that matches filter
                    ],
                    replaceWireIds: true,
                },
                {
                    filter: { category: "Coronary", maker: "Other" },
                    paths: [
                        "/products/coronary/Other",
                        "/product/*", // * = wire_id that matches filter
                    ],
                    replaceWireIds: true,
                },
                {
                    filter: { category: "Peripheral", maker: "Abbott" },
                    paths: [
                        "/products/peripheral/Abbott",
                        "/product/*", // * = wire_id that matches filter
                    ],
                    replaceWireIds: true,
                },
                {
                    filter: { category: "Peripheral", maker: "Other" },
                    paths: [
                        "/products/peripheral/Other",
                        "/product/*", // * = wire_id that matches filter
                    ],
                    replaceWireIds: true,
                },
                {
                    filter: { category: "Compare" },
                    paths: [
                        "/compare/tipLoad",
                        "/compare/supportLevel",
                        "/compare/penetrationPower",
                    ],
                    requireCompareIds: true,
                },
                {
                    filter: { category: "Resource" },
                    paths: ["/resources"],
                },
                {
                    filter: { category: "Compare PDF" },
                    compareWires: true,
                    // no screenshots to take
                    paths: [],
                    // pdf pages to be generated
                    appendComparisonPdf: true,
                    requireCompareIds: true,
                },
            ],
            savediewingStatus: null,
            // mats to include in pdf see setMats()
            mats: [],
            // set at start of generatePdf()
            isComparePdf: null,
        };
    },
    watch: {
        // TRIGGERED HERE
        isGeneratingPdf() {
            if (this.isGeneratingPdf) this.generatePdf();
        },
    },
    methods: {
        ...mapActions([
            "generatePdfDone",
            "compareWireIdsPush",
            "comparisonRemoveAll",
        ]),
        ...mapMutations([
            "setCurrentStatusViewing",
            "setPdfComparisonProperties",
            "setCurrentPdfMatIndex",
            "setCurrentPathIndex",
            "setSavedViewingStatus",
            "setHasTemporaryComparisonIds",
        ]),
        setScreenshotHeight() {
            let overflow = 0;
            for (let e of document.getElementsByClassName(
                "screenshot-expand"
            )) {
                overflow += e.scrollHeight - e.clientHeight;
                e.classList.remove("scroll-for-more");
            }
            let minAspect = 4 / 3;
            this.screenshotHeight = this.screenshotWidth / minAspect + overflow;
        },
        // triggered by isGeneratingPdf - seems lame
        // other options:
        //      move to helper - can't use computed
        //      move to store - probably best but see notes in generatePdf action
        async generatePdf() {
            this.setIsComparePdf();
            tbaWrapper.isiOS ? this.iosGeneratePdf() : this._generatePdf();
        },
        // todo move these to actions
        getNextMat() {
            // crash proof mat iterator
            let nextIndex =
                this.currentPdfMatIndex == undefined
                    ? 0
                    : this.currentPdfMatIndex + 1 >= this.mats.length
                    ? undefined
                    : this.currentPdfMatIndex + 1;
            this.setCurrentPdfMatIndex(nextIndex);
            return this.mats[nextIndex];
        },
        getNextPath(paths) {
            // crash proof iterator
            let nextIndex =
                this.currentPathIndex == undefined
                    ? 0 // start
                    : this.currentPathIndex + 1 >= paths.length
                    ? undefined // end
                    : this.currentPathIndex + 1;
            this.setCurrentPathIndex(nextIndex);
            return paths[nextIndex];
        },
        async iosGeneratePdf() {
            tbaWrapper.log("iOS generating pdf");
            tbaWrapper.disableSleep();
            document.body.classList.add("no-animate");
            this.saveViewingStatus();

            var paths, matInfo;
            this.setMats();
            let throttle = new ThrottleInterval(600);
            var lastStatus = "";
            var i = 0; // doesn't matter if this is reset. just for logs
            var mat;
            var path;
            while ((mat = this.getNextMat())) {
                tbaWrapper.log("MAT ---", mat);
                if (mat.status != lastStatus) {
                    lastStatus = mat.status;
                    this.setCurrentStatusViewing(mat.status);
                }
                paths = this.getMatPaths(mat);
                console.log("paths----------", paths);
                matInfo = this.getMatInfo(mat);
                if (matInfo.requireCompareIds) this.requireCompareIds();

                while ((path = this.getNextPath(paths))) {
                    throttle.startInterval();
                    // ----------------------------------------------------
                    await this.$router.push(path);
                    this.setScreenshotHeight();
                    tbaWrapper.log(
                        `${++i} capturing: ${path} @ ${this.screenshotWidth}x${
                            this.screenshotHeight
                        }`
                    );
                    await this.screenToFile(path);
                    // ----------------------------------------------------
                    // iOS throttle for: "SecurityError: Attempt to use history.replaceState() more than 100 times per 30 seconds"
                    // actually requires almost double the stated time.
                    await throttle.endInterval();
                }
                throttle.logStats();
                this.removeTempCompareIds();
                // comparison PDF added later
            }

            var pdfDoc = await this.assembleFromFiles();

            document.body.classList.remove("no-animate");
            this.restoreViewingStatus();

            this.pdfSource = await pdfDoc.save(); // Uint8Array

            tbaWrapper.enableSleep();
            this.generatePdfDone();
        },
        async assembleFromFiles() {
            var pdfDoc = await PDFDocument.create();
            var matInfo;
            for (const mat of this.mats) {
                for (const path of this.getMatPaths(mat)) {
                    await this.fileToPdfAndRemove(path, pdfDoc);
                }
                // this is not a file
                matInfo = this.getMatInfo(mat);
                if (matInfo.appendComparisonPdf) {
                    this.requireCompareIds();
                    for (const pdf of await this.getComparisonPdfDocs(mat)) {
                        await this.appendPdf(pdfDoc, pdf);
                    }
                    this.removeTempCompareIds();
                }
            }
            this.numberPages(pdfDoc);
            return pdfDoc;
        },
        pathTempFile: (path) => (`temp${path}.png`),
        async fileToPdfAndRemove(pagePath, pdfDoc) {
            let filePath = this.pathTempFile(pagePath);
            let meta = await util.pImageMeta(file.localSrc(filePath));
            let pageSize = Object.values(meta);
            let pdfImage = await pdfDoc.embedPng(
                await file.pFetchLocal(filePath)
            );
            let page = pdfDoc.addPage(pageSize);
            page.drawImage(pdfImage, pdfImage.scaleToFit(...pageSize));
            // remove file
            await tbaWrapper.pRm(filePath);
        },
        async _generatePdf() {
            var pdfDoc = await PDFDocument.create();
            document.body.classList.add("no-animate");
            this.saveViewingStatus();

            var paths, matInfo;
            this.setMats();

            var lastStatus = "";
            var i = 0;
            for (const mat of this.mats) {
                tbaWrapper.log("MAT ---", mat);
                if (mat.status != lastStatus) {
                    lastStatus = mat.status;
                    this.setCurrentStatusViewing(mat.status);
                }
                paths = this.getMatPaths(mat);
                console.log("paths----------", paths);
                matInfo = this.getMatInfo(mat);
                if (matInfo.requireCompareIds) this.requireCompareIds();
                for (const path of paths) {
                    // ----------------------------------------------------
                    await this.$router.push(path);
                    this.setScreenshotHeight();
                    tbaWrapper.log(
                        `${++i} capturing: ${path} @ ${this.screenshotWidth}x${
                            this.screenshotHeight
                        }`
                    );
                    await this.screenToPdf(pdfDoc);
                    // ----------------------------------------------------
                }
                this.removeTempCompareIds();
                if (matInfo.appendComparisonPdf) {
                    for (const pdf of await this.getComparisonPdfDocs(mat)) {
                        await this.appendPdf(pdfDoc, pdf);
                    }
                }
            }
            this.numberPages(pdfDoc);

            document.body.classList.remove("no-animate");
            await this.restoreViewingStatus();
            this.pdfSource = await pdfDoc.save(); // Uint8Array
            this.generatePdfDone();
        },
        async numberPages(pdfDoc) {
            const pages = pdfDoc.getPages();
            const pageCount = pages.length;
            let i = 1;
            for (const page of pages) {
                let width = page.getWidth();
                let height = page.getHeight();
            console.log(`width: ${width}, height: ${height}`);
                let scale = width / 1024;
                let pageStr = `${i++} / ${pageCount}`;
                // 0,0 is bottom left
                page.drawText(pageStr, {
                    x: width - 90 * scale, // right
                    y: height - 17 * scale, // top
                    // y: 25 * scale, // bottom

                    size: 13 * scale,
                    lineHeight: 24 * scale,
                });
            }
            // see also this. never got it to work though
            // https://github.com/Hopding/pdf-lib/issues/464#issuecomment-643834048
        },

        async getComparisonPdfDocs(mat) {
            // returns array of pdf-lib documents
            var pdfDocs = [];
            // status sets which mat is used
            this.setCurrentStatusViewing(mat.status);
            this.requireCompareIds();
            if (this.isReviewOpen)
                this.setPdfComparisonProperties(COMPARISON_PROPERTIES);
            var comparisonPdf = await genComparisonPdfDoc(); // todo matNum, comparisonProperty
            pdfDocs.push(comparisonPdf);
            this.removeTempCompareIds();
            // console.log("comparisonPdf-----------------------------------------");
            // console.log(comparisonPdf);
            // load and add the apropriate isi pdf
            var isiPdfDoc = await this.getIsiPdfDoc(mat);
            if (isiPdfDoc) pdfDocs.push(isiPdfDoc);
            return pdfDocs;
        },
        async pPdfDocSave(fileName, pdfDoc) {
            // android/ios
            if (window.tbaWrapper.platform)
                await file.pFilePutContents(
                    fileName,
                    await pdfDoc.saveAsBase64()
                );
            // web
            else file.downloadBlob(new Blob([await pdfDoc.save()]), fileName);
        },
        // addHeadingPage(pdfDoc, pageStr, status) {
        //     let page = pdfDoc.addPage([1024, 768]);
        //     // todo dry
        //     page.drawText(pageStr, {
        //         x: 4,
        //         y: 768 - 15,
        //         size: 12,
        //         lineHeight: 24,
        //     });
        //     page.drawText(status, {
        //         x: 100,
        //         y: 768 / 2,
        //         size: 48,
        //     });
        // },
        dataUrlToB64(uri) {
            return uri.substr(uri.indexOf(",") + 1);
        },
        async screenToFile(path) {
            let dataUrl = await this.screenshot();
            let b64 = this.dataUrlToB64(dataUrl);
            await file.pFilePutContents(this.pathTempFile(path), b64);
        },
        async screenToPdf(pdfDoc) {
            let pageSize = [this.screenshotWidth, this.screenshotHeight];

            // await this.$nextTick();
            let pdfImage = await pdfDoc.embedPng(await this.screenshot());
            let page = pdfDoc.addPage(pageSize);
            page.drawImage(pdfImage, pdfImage.scaleToFit(...pageSize));
        },
        async getIsiPdfDoc(mat) {
            // return pdf-lib document
            const isiPdfData = await this.isiData(mat);
            if (!isiPdfData) return null;
            return await PDFDocument.load(isiPdfData);
        },
        async appendPdf(pdfDoc, isiPdfDoc) {
            let isiPages = await pdfDoc.copyPages(
                isiPdfDoc,
                isiPdfDoc.getPageIndices()
            );
            for (const p of isiPages) {
                pdfDoc.addPage(p);
            }
        },
        async isiData(mat) {
            let isiUrl = this.getIsiUrl(mat);
            if (!isiUrl) return null;
            let isiSrc = file.src(isiUrl);
            console.log("isiSrc", isiSrc);
            if (window.tbaWrapper.isAndroid)
                return await tbaWrapper.pFileGetContents(
                    file.relativeBasePath(isiUrl)
                );
            else return await fetch(isiUrl).then((res) => res.arrayBuffer());
        },
        getMatPaths(mat) {
            let info = this.getMatInfo(mat);
            if (info.replaceWireIds) {
                // count the correct status -- no need to run the heavy getter(s) if we simply filtered by mat_id
                this.setCurrentStatusViewing(mat.status);
                return this.pathsWithWireIds(info);
                // return this.pathsWithFirstWireId(info);
            }
            return info.paths;
        },
        // testing
        // pathsWithFirstWireId(info) {
        //     return info.paths.map((path) =>
        //         path.replace(
        //             "*",
        //             this.getCategoryMakerFirstWireId(
        //                 info.filter.category,
        //                 info.filter.maker
        //             )
        //         )
        //     );
        // },
        pathsWithWireIds(info) {
            var paths = [];
            // would have been simpler to filter by mat_id
            var ids = this.getCategoryMakerWireIds(
                info.filter.category,
                info.filter.maker
            );
            for (const path of info.paths) {
                if (path.includes("*")) {
                    paths = [
                        ...paths,
                        ...ids.map((id) => path.replace("*", id)),
                    ];
                } else paths = [...paths, path];
            }
            return paths;
        },
        requireCompareIds() {
            if (this.compareWireIds.length == 0) {
                this.setHasTemporaryComparisonIds(true);
                var testIds = this.getTestWireIds;
                console.log("adding ids: ", testIds);
                for (const id of this.getTestWireIds) {
                    console.log("adding id", id);
                    this.compareWireIdsPush(id);
                }
            }
        },
        removeTempCompareIds() {
            if (this.hasTemporaryComparisonIds) {
                this.comparisonRemoveAll();
                this.setHasTemporaryComparisonIds(false);
            }
        },
        getMatInfo(mat) {
            for (const info of this.matInfo) {
                if ([mat].filter(this.filterFn(info.filter)).length)
                    return info;
            }
            return {};
        },
        filterFn(params) {
            // console.log("params:", params);
            return (i) => {
                for (const [k, v] of Object.entries(params)) {
                    if (i[k] != v) return false;
                }
                return true;
            };
        },
        async screenshot() {
            // wip increasing resolution
            // crashes. was working on reducing threshold (-100000)
            // const iosMaxCanvasPixels = 16777216;
            // let w = this.screenshotWidth;
            // let h = this.screenshotHeight;
            // let scale =
            //     tbaWrapper.isiOS && w * h > iosMaxCanvasPixels - 100000 ? 1 : 2;
            // if (w * h > iosMaxCanvasPixels)
            //     console.error(
            //         "big page ==================================================================",
            //         w,
            //         h
            //     );
            let scale = 1;
            return await screenshot(
                this.screenshotWidth,
                this.screenshotHeight,
                scale
            );
        },
        // still need these even though pdf only has one status now
        // because can create a pdf of status that we are not currently viewing
        // todo save current path as well
        saveViewingStatus() {
            if (this.savedViewingStatus == undefined) {
                this.setSavedViewingStatus(this.currentStatusViewing);
            }
        },
        async restoreViewingStatus() {
            console.log("restoreViewingStatus(): restoring");
            this.setCurrentStatusViewing(this.savedViewingStatus);
            console.log("restoreViewingStatus(): clear saved");
            this.setSavedViewingStatus(undefined);
        },
        // for dev. don't do all wires
        getCategoryMakerFirstWireId(category, maker) {
            var segmentWires =
                this.makerCategorySegmentWires?.[maker]?.[category] ?? {};
            return segmentWires[Object.keys(segmentWires)?.at(0)]?.at(0)?.id;
        },
        getCategoryMakerWireIds(category, maker) {
            var segmentWires =
                this.makerCategorySegmentWires?.[maker]?.[category] ?? {};
            var ids = [];
            var descending = (a, b) => (a <= b ? -1 : 1);
            var segments = Object.keys(segmentWires).sort(descending);
            for (const segment of segments) {
                console.log("SEGMENT:", segment);
                ids = [
                    ...ids,
                    ...segmentWires[segment]
                        .sort(util.by_column("name"))
                        .map((w) => w.id),
                ];
            }
            return ids;
        },
        getIsiUrl(mat) {
            // todo this could probably use the new getter
            let resId =
                this.$store.state.apiData.templates
                    .filter(
                        (t) =>
                            t.mat_id == mat.id &&
                            // t.region == this.mat.region &&
                            t.name == "Compare PDF Printout"
                    )
                    .at(0)?.content?.wipca ?? 0;
            console.log("isiUrl() resId", resId);
            if (!resId) {
                console.error(`no ISI pdf for mat id ${mat.id}`, mat);
            }
            return this.getResourcesById[resId]?.filename;
        },
        setMats() {
            // special case for compare pdf view or selected mats
            this.mats = this.isComparePdf
                ? [this.getCurrentMatObject]
                : this.getPdfMats;
        },
        closePdfViewer() {
            this.pdfSource = "";
            if (this.isComparePdf) {
                // if the user closed and opened the app, there's no history
                // so go back to an arbitrary (the first) comparison page
                if (window.history.length < 2)
                    this.$router.push("/compare/supportLevel");
                else this.$router.back();
            }
        },
        setIsComparePdf() {
            // moved from computed because currentRoute changes.
            // using store to be reactive. not router -- doesn't matter anymore
            this.isComparePdf =
                this.$store.state.currentRoute.fullPath == "/compare-pdf" && !this.isReviewOpen
        },
    },
    computed: {
        ...mapState([
            "currentStatusViewing",
            "compareWireIds",
            "isReviewOpen",
            "hasTemporaryComparisonIds",
        ]),
        ...mapState({
            isGeneratingPdf: (state) => state.pdf.isGeneratingPdf,
            selectedMatIdsPdf: (state) => state.pdf.selectedMatIdsPdf,
            currentPdfMatIndex: (state) => state.pdf.currentPdfMatIndex,
            currentPathIndex: (state) => state.pdf.currentPathIndex,
            savedViewingStatus: (state) => state.pdf.savedViewingStatus,
        }),
        ...mapGetters([
            "getPdfMats",
            "getCurrentMatObject",
            "makerCategorySegmentWires",
            "getTestWireIds",
            "getResourcesById",
        ]),
        pdfOptions() {
            return {
                orientation:
                    this.screenshotWidth > this.screenshotHeight
                        ? "landscape"
                        : "portrait",
                hotfixes: ["px_scaling"],
                unit: "px",
                format: [this.screenshotWidth, this.screenshotHeight],
            };
        },
        pdfPageArgs() {
            return [this.pdfOptions.format, this.pdfOptions.orientation];
        },
        hasBigPage() {
            return (
                this.mats.filter(
                    (m) =>
                        m.category == "Coronary" || m.category == "Peripheral"
                ).length > 0
            );
        },
        canViewerHandleResolution() {
            return !tbaWrapper.isiOS || !this.isReviewOpen || !this.hasBigPage;
        },
        scale() {
            return this.canViewerHandleResolution ? undefined : 4 / 3;
        },
        pdfFilename() {
            return this.isReviewOpen
                ? "GuideWire App Review.pdf"
                : "GuideWire Comparison.pdf";
        },
    },
};
</script>

<style>
#modal-generate-pdf.modal {
    background-color: white;
}
</style>
