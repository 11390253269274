// export const IS_LOCAL_DEV = !!window.webpackHotUpdate;
// export const HOST = IS_LOCAL_DEV ? "http://dev.avgwdc.com" : "";
// in wrapper. todo handle this automatically

// default environment set by the build script. (can change at runtime)
var ENVIRONMENT = "DEV";
// revision set by build script
export const REVISION = "2022-11-25 f114238";

export const OLD_API = false; // todo rename to IS_OLD_API

// todo rename to api_base_url or something
export var HOST;
export function SETENV(env) {
    ENVIRONMENT = env ? env : ENVIRONMENT;
    HOST = OLD_API
        ? "https://dev.avgwdc.com"
        : {
              DEV: "https://avgwdc-dev.tbadigital-proofing.com",
              QA: "https://avgwdc-qa.tbadigital-proofing.com",
              UAT: "https://avgwdc-uat.tbadigital-proofing.com",
              PEN: "https://avgwdc-pen.tbadigital-proofing.com",
              PROD: "https://avgwdc-prod.tbadigital-proofing.com",
          }[ENVIRONMENT];
    return ENVIRONMENT;
}
// this doesn't run early enough or something
SETENV();
export const GETENV = SETENV;
export const REGIONS = ["US", "OUS"];
export const OLD_API_REGION_IDS = {
    US: 1,
    OUS: 5,
};
// Analytics
export const FIREBASE = {
    android: {
        api_secret: "aAP5GjxJQV6X8M5vZHFORQ",
        firebase_app_id: "1:1092697788803:android:16d8f7f22e48cbfcf4a6b6",
    },
    ios: {
        api_secret: "GDu4-6x5TZqUAt6fA0To2w",
        firebase_app_id: "1:1092697788803:ios:127e7140762f59faf4a6b6",
    },
};
// web uses gtag.js
export const GTAG = {
    measurement_id: "G-7SFXWFNV5R",
    api_secret: "djNx576QSrqKSqSrzyVzxA",
};

export const COMPARISON_PROPERTY_NAMES = {
    // order is preserved for non-integer keys
    tipLoad: "Tip Load",
    supportLevel: "Device Support Level",
    penetrationPower: "Penetration Power",
};
export const COMPARISON_PROPERTIES = Object.keys(COMPARISON_PROPERTY_NAMES);

// ordering, grouping and labelling of Mats on Review Options
// also used for pdf generation to have same order
export const CONTENT_TYPES = {
    lobby: {
        label: "Lobby",
        matCategories: ["Lobby"],
    },
    coronary: {
        label: "Coronary Pages",
        subLabel: "maker", // eg mat.maker
        matCategories: ["Coronary"],
    },
    peripheral: {
        label: "Peripheral Pages",
        subLabel: "maker",
        matCategories: ["Peripheral"],
    },
    landingOther: {
        label: "Landing & Other Pages",
        subLabel: "category",
        matCategories: ["Landing", "Compare", "Resource"],
    },
    comparisonPrintout: {
        label: "Comparison Printout",
        matCategories: ["Compare PDF"],
    },
};
var matCategories = [];
for (const meta of Object.values(CONTENT_TYPES)) {
    matCategories = [...matCategories, ...meta.matCategories];
}
export const MAT_CATEGORY_ORDER = matCategories;

export const MAT_ROUTE_BY_CATEGORYMAKER = {
    CoronaryAbbott: "/products/coronary/Abbott",
    CoronaryOther: "/products/coronary/Other",
    PeripheralAbbott: "/products/peripheral/Abbott",
    PeripheralOther: "/products/peripheral/Other",
    Resource: "/resources",
    Lobby: "/lobby",
    Landing: "/disclaimer",
    Compare: "/compare/supportLevel",
    "Compare PDF": "/compare-pdf",
};
export const ROUTE_TO_CATEGORYMAKER = {
    "/lobby": "Lobby",
    "/disclaimer": "Landing",
    "/": "Landing",
    "/product-types": "Landing",
    "/products/coronary/Abbott": "CoronaryAbbott",
    "/products/coronary/Other": "CoronaryOther",
    "/products/peripheral/Abbott": "PeripheralAbbott",
    "/products/peripheral/Other": "PeripheralOther",
    "/compare/supportLevel": "Compare",
    "/compare/penetrationPower": "Compare",
    "/compare/tipLoad": "Compare",
    "/compare-big/supportLevel": "Compare",
    "/compare-big/penetrationPower": "Compare",
    "/compare-big/tipLoad": "Compare",
    "/resources": "Resource",
    "compare-pdf": "Compare PDF",
    // "/product/*":"look up by wire id",
    // "/graph/*":"look up by wire id",
};

export const WIRE_PROPERTY_UNITS = {
    tip_load: " g",
    tip_diameter: '"',
    penetration_power: " kg/in<sup>2</sup>",
};
