<template>
    <div class="compare-pdf-page1">
        <table id="compare-pdf-table">
            <thead>
                <tr>
                    <th>NAME</th>
                    <th>CORE</th>
                    <th>COVER</th>
                    <th>COATING</th>
                    <th>TIP STYLE</th>
                    <th>TIP DIAMETER</th>
                    <th>RADIOPACITY</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="w in comparisonWires" :key="w.id">
                    <tr>
                        <td>
                            {{ w.category?.toUpperCase() }},
                            <span
                                v-html="actualBrand(w)?.toUpperCase() ?? ''"
                            ></span>
                            <div class="name" v-html="w.name"></div>
                        </td>
                        <td>{{ notAvailable(w.core_material) }}</td>
                        <td>{{ notAvailable(w.cover) }}</td>
                        <td>{{ notAvailable(w.coating) }}</td>
                        <td>{{ notAvailable(w.tip_style) }}</td>
                        <td>{{ notAvailable(w.tip_diameter, '"') }}</td>
                        <td>{{ notAvailable(w.radiopacity) }}</td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Logo_Abbott2_dataUri from "@/assets/images/Logo_Abbott2_dataUri";
export default {
    data() {
        return {
            Logo_Abbott2_dataUri,
        };
    },
    computed: {
        ...mapGetters(["comparisonWires"]),
    },
    methods: {
        actualBrand(wire) {
            return wire.maker == "Abbott" ? "Abbott" : wire.brand;
        },
        notAvailable(value, append="") {
            return value ? value + append : "Not Available";
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
.compare-pdf-page1 {
}

#compare-pdf-table {
    color: $abbott-dark-grey;
    margin-left: vwp(45px);
    font-size: vwp(10px);
    line-height: vwp(14.4px);
    font-weight: bold;
    width: 88.5vw;
    border-collapse: collapse;
    text-align: left;
    th {
        border-bottom: vwp(1px) solid #e1e1e1;
        vertical-align: bottom; // not working. fix me
    }
    td {
        vertical-align: top;
    }
    th,
    td {
        padding: vwp(10px);
        &:not(:first-child) {
            border-left: vwp(1px) solid #e1e1e1;
        }
    }
    tr:last-child td {
        border-bottom: vwp(1px) solid #e1e1e1;
    }
    .name {
        font-size: vwp(12px);
    }
}
</style>
