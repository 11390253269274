<template>
    <div class="wire-graph-legend">
        <h3>LEGEND</h3>
        <hr />
        <div
            v-for="wire in wires"
            v-show="isShowing(wire.id)"
            :key="wire.id"
            class="item"
            @click="setHighlight(wire.id)"
            :class="{ highlight: isHighlighted(wire.id) }"
        >
            <div :class="`color-dot bgcolor-${colorIndex(wire.id)}`"></div>
            <div class="brand-type-name">
                <div class="brand-type">
                    {{ `${wire.maker} ${wire.category}` }}
                </div>
                <div class="name" v-html="wire.name"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
    computed: {
        ...mapState(["comparisonById", "comparisonHighlightId"]),
        ...mapGetters({
            wires: "comparisonWires",
        }),
    },
    methods: {
        ...mapMutations([
            "comparisonIsShowingToggle",
            "comparisonHighlightIdSet",
        ]),
        isShowing(id) {
            return this.comparisonById[id].isShowing;
        },
        colorIndex(id) {
            return this.comparisonById[id].colorIndex;
        },
        isHighlighted(id) {
            return this.comparisonHighlightId == id;
        },
        setHighlight(id) {
            this.comparisonHighlightIdSet(this.isHighlighted(id) ? 0 : id);
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
.wire-graph-legend {
    width: vw(225px);
    h3 {
        font: normal normal bold vw(11px) BrandonGrotesque;
        line-height: vw(16px);
    }
    hr {
        border: vw(1px) solid $table-lines;
        margin-top: vw(8px);
        margin-bottom: vw(6px);
    }
    .item {
        display: flex;
        min-height: vw(50px);
        padding: vw(5px) vw(10px);
        @include gap-compat-1(vw(5px));
        align-items: center;
        &.highlight {
            background-color: #efefef;
            border-radius: vw(5px);
        }
    }
    .color-dot {
        @include round(vw(10px));
    }
    .brand-type {
        font: normal normal bold vw(11px) Calibri;
        line-height: vw(13px);
        text-transform: uppercase;
    }
    .name {
        font: normal normal normal vw(14px) Calibri;
        line-height: vw(17px);
    }
}
</style>
