<template>
    <div class="bar-graph-container">
        <div class="graph">
            <template v-for="(g, i) in graduations" :key="i">
                <div
                    v-if="g.value"
                    class="vertical-line"
                    :style="{ left: g.percent }"
                ></div>
            </template>

            <template v-for="(bar, i) in bars" :key="i">
                <div
                    v-show="bar.isShowing"
                    class="bar"
                    :class="bar.colorClass"
                    :style="bar.style"
                >
                    <div class="value" :class="bar.valueClass">
                        {{ bar.value }}
                    </div>
                </div>
            </template>
        </div>
        <div class="graduation-labels">
            <div
                v-for="(g, i) in graduations"
                :key="i"
                class="grad-label"
                :style="{ left: g.percent }"
            >
                {{ g.value }}
            </div>
        </div>
        <div class="units-label" v-html="unitsLabelHtml"></div>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    props: ["wires", "property"],
    data() {
        return {
            toWireProperty: {
                tipLoad: "tip_load",
                penetrationPower: "penetration_power",
            },
            toUnitsLabelHtml: {
                tipLoad: "TIP LOAD (g)",
                penetrationPower: "PENETRATION POWER (kg / in<sup>2</sup>)",
            },
            numGraduations: 4,
        };
    },
    methods: {
        // also fixes precision issue that caused eg. 0.30000000000000004
        noTrailingZeros: (n) => parseFloat(n.toFixed(4)),
    },
    computed: {
        ...mapState(["comparisonById"]),
        wireProperty() {
            return this.toWireProperty[this.property];
        },
        unitsLabelHtml() {
            return this.toUnitsLabelHtml[this.property];
        },
        maxValue() {
            let _this = this;
            let max = this.wires.reduce(function (max, wire) {
                let val = Number(wire[_this.wireProperty]);
                return val > max ? val : max;
            }, 0);
            if (!max) return 1;
            return max;
        },
        bars() {
            let bars = [],
                value,
                widthPercent;
            this.wires.forEach(function (wire) {
                value = Number(wire[this.wireProperty]);
                widthPercent = (value * 100) / this.graphMax;
                bars.push({
                    value: value ? value : "Not Available",
                    style: { width: `${widthPercent}%` },
                    colorClass: `color-${
                        this.comparisonById[wire.id].colorIndex
                    }`,
                    valueClass: value
                        ? widthPercent < 25
                            ? "after"
                            : ""
                        : "after not-available",
                    isShowing: this.comparisonById[wire.id].isShowing,
                });
            }, this);
            return bars;
        },
        graduationIncrement() {
            const [base, exp] = (this.maxValue / this.numGraduations)
                .toExponential()
                .split("e")
                .map((s) => Number(s));
            // rount up to 2.5 or any int
            let incBase = base > 2 && base <= 2.5 ? 2.5 : Math.ceil(base);
            // decided to allow base being 6..9 because visualisation is better with max bar as big as possible.
            // if (incBase > 5 && incBase < 10) incBase = 10;
            return incBase * Math.pow(10, exp);
        },
        graphMax() {
            return this.numGraduations * this.graduationIncrement;
        },
        graduations() {
            let grads = [];
            for (let i = 0; i <= this.numGraduations; i++) {
                let value = this.noTrailingZeros(i * this.graduationIncrement);
                grads.push({
                    value: value,
                    percent: (value * 100) / this.graphMax + "%",
                });
            }
            return grads;
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
.bar-graph-container {
    // height: 380px;
    display: flex;
    flex-direction: column;
    // justify-content: flex-end;
    .graph {
        border: vw(1px) solid $table-lines;
        border-top: none;
        border-right: none;
        display: flex;
        flex-direction: column;
        @include gap-compat-1(vw(30px), column);
        padding: vw(20px) 0;
        position: relative;
        .vertical-line {
            position: absolute;
            top: 0;
            bottom: 0;
            width: vw(1px);
            // left to be set inline
            background-color: $table-lines;
            // avoid margin from gap-compat-1
            margin-bottom: 0 !important;
        }

        .bar {
            height: vw(40px);
            z-index: 1;
            position: relative;
            .value {
                height: 100%;
                color: white;
                font-weight: bold;
                white-space: nowrap;
                display: flex;
                align-items: center;
                justify-content: center;
                font: normal normal bold vw(14px) BrandonGrotesque;
                &.after {
                    position: relative;
                    left: 100%;
                    justify-content: left;
                    padding-left: vw(10px);
                    color: black;
                    &.not-available {
                        color: inherit;
                        text-transform: uppercase;
                    }
                }
            }
            @each $i, $color in $comparison-colors {
                &.color-#{$i} {
                    background-color: $color;
                    color: $color;
                }
            }
        }
    }
    .graduation-labels {
        position: relative;
        height: vw(16px);
        margin-top: vw(8px);
        margin-bottom: vw(5px);
        .grad-label {
            position: absolute;
            top: 0;
            width: vw(40px);
            height: vw(16px);
            margin-left: vw(-20px);
            text-align: center;
            font: normal normal bold vw(11px) BrandonGrotesque;
            color: black;
        }
    }
    .units-label {
        font: normal normal bold vw(12px) BrandonGrotesque;
        height: vw(18px);
        line-height: vw(18px);
        text-align: center;
        color: $abbott-blue;
    }
}
</style>
