<template>
    <div class="modal-compare-list-print">
        <transition name="fade">
            <div class="modal" v-if="show" @click.self="$emit('cancel')">
                <div class="popup">
                    <h2 class="title">
                        <span>PRINT COMPARISON LIST</span>
                        <span
                            class="numbers-blue"
                            :class="{ greyed: comparisonIsEmpty }"
                            >{{ compareCount }} / 8</span
                        >
                    </h2>
                    <p class="message">
                        Which comparison charts would you like added to your
                        PDF?
                    </p>
                    <hr class="blue" />
                    <AppCheckboxArray
                        v-model="selectedValues"
                        :valueLabels="options"
                    />
                    <hr />
                    <div class="btns">
                        <button
                            :disabled="selectedValues.length == 0"
                            class="ok"
                            @click="viewPdf"
                        >
                            PRINT LIST
                        </button>
                        <button class="cancel" @click="$emit('cancel')">
                            CANCEL
                        </button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import AppCheckboxArray from "./AppCheckboxArray.vue";
import { COMPARISON_PROPERTY_NAMES, COMPARISON_PROPERTIES } from "@/constants";
import { mapGetters, mapMutations } from "vuex";
export default {
    components: { AppCheckboxArray },
    props: ["show"],
    data() {
        return {
            options: COMPARISON_PROPERTY_NAMES,
            selectedValues: COMPARISON_PROPERTIES,
        };
    },
    computed: {
        ...mapGetters(["compareCount", "comparisonIsEmpty"]),
    },
    methods: {
        ...mapMutations(["setPdfComparisonProperties"]),
        viewPdf() {
            const orderedProperties = COMPARISON_PROPERTIES.filter(value => this.selectedValues.includes(value));
            this.setPdfComparisonProperties(orderedProperties);
            this.$router.push("/compare-pdf");
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
.modal-compare-list-print {
    // copy of ModalConfirm. todo remove duplication
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
    .modal {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }
    .popup {
        background: #fff;
        border-radius: vw(5px);
        box-shadow: vw(2px) vw(4px) vw(8px) rgba(0, 0, 0, 0.2);
        text-align: center;
        width: vw(470px);
        display: flex;
        flex-direction: column;
        @include gap-compat-1(vw(20px), column);
    }

    .title {
        font: normal normal bold vw(24px) BrandonGrotesque;
        line-height: vw(34px);
        padding: vw(40px) vw(20px) 0 vw(20px);
    }
    .message {
        font: normal normal normal vw(16px) MercuryTextG1;
        line-height: vw(20px);
        padding: 0 vw(40px);
    }
    hr {
        margin: 0;
        border: vw(1px) solid #f2f2f2;
    }
    .btns {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0 vw(20px) vw(20px);
        @include gap-compat-1(vw(20px));
        button {
            padding: 0 vw(36px);
        }
        .ok-btn {
            color: red;
            text-decoration: underline;
            line-height: 2.5rem;
            cursor: pointer;
        }
        .cancel-btn {
            background-color: $color-alert;
            color: white;
            border: vw(2px) solid #0ec5a4;
            border-radius: vw(5px);
            font-weight: bold;
            font-size: vw(16px);
            text-transform: uppercase;
            cursor: pointer;
        }
    }
    button {
        cursor: pointer;
        height: vw(45px);
        min-width: vw(130px);
        font: normal normal bold vw(14px) BrandonGrotesque;
        &.ok {
            border: none;
            background-color: $color-alert;
            color: white;
        }
        &.cancel {
            border: vw(2px) solid $light-grey;
            color: $abbott-dark-grey;
        }
    }
    // end duplicated stuff
    // specific below
    h2 > * {
        vertical-align: middle;
    }
    // todo dry
    .numbers-blue {
        display: inline-block;
        background-color: $abbott-blue;
        &.greyed {
            background-color: $light-grey;
        }
        color: white;
        width: vw(50px);
        font: normal normal normal vw(14px) BrandonGrotesque;
        height: vw(30px);
        line-height: vw(30px);
        text-align: center;
        vertical-align: middle;
        margin-left: vw(20px);
    }
    .message {
        padding: 0 vw(46px);
    }
    hr.blue {
        border: none;
        border-bottom: vw(1px) solid $abbott-blue;
        margin-left: vw(20px);
        margin-right: vw(20px);
    }
    .app-checkbox {
        padding-left: vw(123px);
        .checkbox {
            width: vw(30px);
            height: vw(30px);
        }
    }
    button {
        &.ok {
            background-color: $abbott-dark-grey;
        }
        &:disabled {
            cursor: not-allowed;
            background-color: $light-grey;
        }
    }
}
</style>
