import base64Brandon from "@/assets/fonts/b64.js/Brandon_bld.otf.b64";

export function injectFont(svg) {
    if (!svg) return;
    // https://w3coded.com/questions/518071/how-to-convert-svg-text-element-with-font-face-to-canvas-using-html2canvas-js
    var styleString =
        "@font-face { font-family: 'BrandonGrotesque'; src: url(" +
        fontDataBrandon() +
        "); font-weight:bold; }";
    var style = document.createElement("style");
    style.textContent = styleString;
    svg.append(style);
}
function fontDataBrandon() {
    const mime = "font/otf";
    return `data:${mime};base64,${base64Brandon}`;
}