<template>
    <button class="add-product" @click="$router.push('/product-types')">
        <span class="valign">ADD GUIDEWIRE</span
        ><img class="plus-blue" src="@/assets/images/Icon_Plus_Blue.svg" />
    </button>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
button.add-product {
    width: vw(170px);
    height: vw(40px);
    line-height: vw(40px);
    margin: 0 auto;
    display: block;
    border: vw(2px) solid #cccccc;
    font: normal normal bold vw(14px) BrandonGrotesque;
    color: $abbott-dark-grey;
    > * {
        vertical-align: middle;
    }
    img.plus-blue {
        margin-left: vw(10px);
        width: vw(16px);
    }
}
</style>
