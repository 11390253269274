<!--
 /compare-pdf-dev/supportLevel/1
 /compare-pdf-dev/supportLevel/2
 /compare-pdf-dev/penetrationPower/2
 /compare-pdf-dev/tipLoad/2
 -->
<template>
    <div id="compare-pdf-dev-view" @click="go">Generate PDF</div>
</template>

<script>
import { webDownloadPdfPage } from "@/helpers/ComparePdfHelper";
import { mapMutations } from "vuex";

export default {
    props: ["comparisonProperty", "pageNum"],
    beforeCreate() {
        document.body.classList.add("no-animate");
    },
    beforeMount() {
        this.setComparisonProperty(this.comparisonProperty);
        this.setComparisonPageNum(this.pageNum);
    },
    beforeUnmount() {
        this.setComparisonPageNum(0);
        document.body.classList.remove("no-animate");
    },
    methods: {
        go() {
            webDownloadPdfPage();
        },
        ...mapMutations(["setComparisonProperty", "setComparisonPageNum"]),
    },
    watch: {
        comparisonProperty() {
            this.setComparisonProperty(this.comparisonProperty);
        },
        pageNum() {
            this.setComparisonPageNum(this.pageNum);
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
#compare-pdf-dev-view {
    // foreground for click
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #00000080;
    padding: vw(20px);
    cursor: pointer;
    font-weight: bold;
}
</style>
