// createpdf.js
export default {
    createPDFCallBack: null,
    /**
     * Turns the current on screen visuals into PDF document
     * @method createPDF
     * @param {String} path - (required), The path to the file that is to be created that resides within the documents directory where '/' is the root of the documents directory
     * @param {Function} callBack - (required), The function to call when the PDF has finished creating.  Shouldn't have any arguments
     */
    createPDF(path, callBack) {
        if (callBack) {
            this.createPDFCallBack = callBack;
        }
        if (this.isiOS) {
            window.webkit.messageHandlers.createpdf.postMessage(path);
            // window.location = "createpdf://foo/bar?sid="+Math.random()+"&path="+this.Base64.encode(path);
        }
    },
    didCreatePDF() {
        this.createPDFCallBack();
        this.createPDFCallBack = null;
    },

};
