<template>
    <div
        class="lobby-view landing screenshot-expand scroll-y"
        v-dragscroll="$isWeb"
    >
        <div class="landing-main">
            <!-- <img class="landing-wires" src="@/assets/images/Landing-Wires.png" /> -->
            <template v-if="template">
                <div
                    class="welcome"
                    v-html="template.subheading"
                    @click.prevent="$root.linkNative($event)"
                ></div>
                <div
                    class="leading-the-way"
                    v-html="template.title"
                    @click.prevent="$root.linkNative($event)"
                ></div>
            </template>
            <!-- content when never connected to api -->
            <template v-else>
                <div class="welcome">WELCOME TO THE GUIDE WIRE DATA CENTER</div>
                <div class="leading-the-way">
                    LEADING THE WAY IN GUIDE WIRE TECHNOLOGY
                </div>
            </template>
            <div class="choose-your-region">Choose Your Region</div>
            <AppDropdown
                class="dropdown-region"
                :options="regions"
                :selected="currentRegion"
                @change="setCurrentRegion"
            />
            <button class="next" @click="next">NEXT</button>
            <div v-if="bottomContent" class="footnote" v-html="bottomContent" @click.prevent="$root.linkNative($event)"></div>
            <div v-else class="footnote">
                <a href="https://www.cardiovascular.abbott">www.cardiovascular.abbott</a><br />
                ©2022 Abbott. All rights reserved.
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import AppDropdown from "@/components/AppDropdown.vue";
import { REGIONS } from "@/constants";
export default {
    name: "LobbyView",
    components: {
        AppDropdown,
    },
    data() {
        return {
            // todo how does user know current region? only by coming here. display elsewhere.
            regions: REGIONS,
        };
    },
    methods: {
        ...mapActions(["setCurrentRegion", "apiLobby", "doSso"]),
        next() {
            this.$router.push("/disclaimer");
        },
    },
    computed: {
        ...mapState(["currentRegion", "isLoggedIn", "apiData", "isReviewApp"]),
        ...mapGetters(["getPageTemplate", "getFooter"]),
        template() {
            return this.getPageTemplate?.content;
        },
        bottomContent() {
            let d = (this.template?.disclaimer ?? "").trim();
            let f = this.getFooter.trim();
            return [...(d ? [d] : []), ...(f ? [f] : [])].join("<br /><br />");
        },
    },
    mounted() {
        // bootstrap some minimal template data on first run.
        // should be ok to display some default stuff before this comes in. it might not if offline.
        if (!this.apiData) this.apiLobby();
        // review app sso on startup
        if (this.isReviewApp && !this.isLoggedIn) this.doSso();
    },
    watch: {
        isReviewApp() {
            // tester switched to Review App with this page already mounted
            if (this.isReviewApp && !this.isLoggedIn) this.doSso();
        },
    },
};
</script>

<style lang="scss" scoped></style>
