<template>
    <div
        class="modal"
        id="modal-menu"
        @click.self="menuClose"
        :class="{ open: isMenuOpen }"
    >
        <div class="menu-wrap" :class="{ open: isMenuOpen }">
            <div class="menu">
                <div class="top">
                    <header>
                        <div class="title" @click="tap('T')">
                            GUIDE WIRE DATA CENTER
                        </div>
                        <div class="version" @click="tap('V')">
                            <b>Version:</b> {{ REVISION }}
                        </div>
                    </header>
                    <template v-if="!isLoggedIn && hasVW">
                        <div
                            v-if="isRecordingSession"
                            class="item"
                            @click="sessionRecordEnd"
                        >
                            END SESSION <button class="stop-blue"></button>
                        </div>
                        <div v-else class="item" @click="sessionRecordStart">
                            START SESSION <button class="play-blue"></button>
                        </div>
                    </template>
                    <div class="item" @click="change_region">CHANGE REGION</div>
                    <div class="item" @click="sync">CHECK FOR UPDATES</div>
                </div>
                <div class="bottom">
                    <ModalMenuUser v-if="isLoggedIn" />
                    <div
                        v-if="isReviewApp && !isLoggedIn"
                        class="item login"
                        @click="doAuth"
                    >
                        ADMIN / REVIEW LOGIN
                    </div>
                    <div class="item close" @click="close">
                        CLOSE MENU
                        <img class="x" src="@/assets/images/Icon_X_Blue.svg" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import ModalMenuUser from "./ModalMenuUser.vue";
import appSSO from "@/helpers/AppSSO";
import { OLD_API, REVISION } from "../constants";
export default {
    components: { ModalMenuUser },
    data() {
        return { REVISION, tapSequence: "" };
    },
    methods: {
        ...mapMutations(["menuClose", "setTestComponentIsShowing"]),
        ...mapActions([
            "login",
            "apiSync",
            "popupInfoBar",
            "sessionRecordStart",
            "sessionRecordEnd",
            "modalConfirm",
        ]),
        sync() {
            this.menuClose();
            this.apiSync();
        },
        change_region() {
            this.$router.push("/lobby");
            this.menuClose();
        },
        doAuth() {
            if (OLD_API) {
                this.popupInfoBar({
                    type: "warning",
                    message: "login not implemented on old api",
                });
                return;
            }
            this.menuClose();
            appSSO.authenticate();
        },
        tap(which) {
            this.tapSequence += which;
        },
        close() {
            if (this.tapSequence == "TTTTVV")
                this.setTestComponentIsShowing(true);
            this.menuClose();
        },
    },
    computed: {
        ...mapState([
            "isLoggedIn",
            "isRecordingSession",
            "isMenuOpen",
            "isReviewApp",
        ]),
        ...mapGetters([
            "getCurrentMatNum",
            "hasViewingWires",
            "guideWiresById",
        ]),
        hasVW() {
            // kludge for getter
            return Object.keys(this.guideWiresById ?? {}).length != 0;
        },
    },
    watch:{
        isMenuOpen(){
            // because can be closed externally onBackPressed
            this.tapSequence = "";
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
#modal-menu {
    visibility: hidden;
    &.open {
        visibility: visible;
    }
    &.modal {
        background-color: #00000026; // black 0.15
    }
    .menu-wrap {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: vw(230px); // +10 for shadow;
        background-color: #00000019; // black, 0.1
        // normal offscreen right
        transform: translateX(100%);
        transition: 0.1s;
        &.open {
            transform: translateX(0);
        }
    }
    .menu {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: vw(10px);
        color: $dark-grey;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
        .top,
        .bottom {
            display: flex;
            flex-direction: column;
            align-items: stretch;
        }
        header {
            min-height: vw(127px);
            padding: vw(20px);
            border-bottom: vw(1px) solid $table-lines;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            @include gap-compat-1(vw(5px), column);
        }
        .item {
            padding-left: vw(30px);
            padding-right: vw(27px);
            min-height: vw(50px);
            border: vw(1px) solid $table-lines;
            border-left: none;
            font: normal normal bold vw(11px) BrandonGrotesque;
            line-height: vw(12px);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &.close {
                height: vw(60px);
                padding-right: vw(19.75px);
            }
        }
        img.x {
            width: vw(15px);
            height: vw(15px);
        }
        .title {
            font: normal normal bold vw(18px) BrandonGrotesque;
            line-height: vw(22px);
        }
        .version {
            font: normal normal normal vw(11px) Calibri;
            line-height: vw(13px);
            color: $abbott-dark-grey;
        }
    }
}
</style>
