<template>
    <div class="compare-view">
        <main
            v-dragscroll="$isWeb"
            id="compare-main"
            class="screenshot-expand scroll-y"
        >
            <ScrollForMore :forId="'compare-main'" />
            <header>
                <h1>
                    <span class="heading">COMPARE GUIDE WIRES</span>
                    <span class="numbers-blue" :class="{ greyed: isEmpty }"
                        >{{ compareCount }} / 8</span
                    >
                </h1>

                <div class="buttons" v-show="!isEmpty">
                    <button class="compare">
                        <label>
                            CLEAR<br />
                            COMPARE LIST
                        </label>
                        <div class="btn trash" @click="removeAll"></div>
                    </button>
                    <button class="pdf" @click="showPrintList = true"></button>
                </div>
                <AppTabs
                    :options="comparisonProperties"
                    :selected="comparisonProperty"
                    @change="$router.push(`/compare/${$event}`)"
                    :class="{ greyed: isEmpty }"
                />
            </header>
            <div class="compare-sides">
                <div class="compare-left" :class="{ empty: isEmpty }">
                    <CompareList />
                    <div v-if="isEmpty" class="empty-message">
                        You have no guide wires in your Comparison List.
                    </div>
                    <CompareButtonAddProduct v-if="!isFull" />
                </div>
                <WireGraph
                    v-if="comparisonProperty == 'supportLevel'"
                    :wires="comparisonWiresWithGraph"
                    width="650"
                    height="482"
                    :isComparison="true"
                />
                <BarGraph
                    v-else-if="!isEmpty"
                    :wires="comparisonWires"
                    :property="comparisonProperty"
                />
                <button
                    v-if="!isEmpty"
                    class="enlarge"
                    @click="$router.push(`/compare-big/${comparisonProperty}`)"
                ></button>
            </div>
            <hr />
            <div
                class="disclaimer"
                v-html="bottomContent"
                @click.prevent="$root.linkNative($event)"
            ></div>
        </main>
    </div>
    <ModalCompareListPrint
        :show="showPrintList"
        @cancel="showPrintList = false"
    />
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import CompareList from "@/components/CompareList.vue";
import AppTabs from "@/components/AppTabs.vue";
import WireGraph from "@/components/WireGraph.vue";
import BarGraph from "@/components/BarGraph.vue";
import ScrollForMore from "@/components/ScrollForMore.vue";
import { COMPARISON_PROPERTY_NAMES } from "@/constants";
import CompareButtonAddProduct from "@/components/CompareButtonAddProduct.vue";
import ModalCompareListPrint from "@/components/ModalCompareListPrint.vue";
export default {
    props: ["comparisonProperty"],
    components: {
        CompareList,
        WireGraph,
        AppTabs,
        BarGraph,
        ScrollForMore,
        CompareButtonAddProduct,
        ModalCompareListPrint,
    },
    data() {
        return {
            comparisonProperties: COMPARISON_PROPERTY_NAMES,
            showPrintList: false,
        };
    },
    methods: {
        ...mapMutations(["setCurrentSection", "setComparisonProperty"]),
        ...mapActions(["comparisonRemoveAll", "modalConfirm"]),
        async removeAll() {
            if (
                await this.modalConfirm({
                    title: "CLEAR COMPARISON LIST",
                    message:
                        "Are you sure want to clear all guide wires from the comparison list?",
                    okButton: "CLEAR ALL",
                })
            ) {
                this.comparisonRemoveAll();
                this.$router.push("/compare/supportLevel");
            }
        },
    },
    computed: {
        ...mapGetters([
            "comparisonWires",
            "comparisonWiresWithGraph",
            "compareCount",
            "getPageTemplate",
            "getFooter",
        ]),
        ...mapGetters({
            isEmpty: "comparisonIsEmpty",
            isFull: "comparisonIsFull",
        }),
        ...mapState(["compareWireIds"]),
        template() {
            return this.getPageTemplate?.content;
        },
        bottomContent() {
            let d = (this.template?.disclaimer ?? "").trim();
            let f = this.getFooter.trim();
            return [...(d ? [d] : []), ...(f ? [f] : [])].join("<br /><br />");
        },
    },
    mounted() {
        this.setCurrentSection("compare");
        this.setComparisonProperty(this.comparisonProperty);
    },
    watch: {
        comparisonProperty() {
            this.setComparisonProperty(this.comparisonProperty);
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
.compare-view {
    .compare-sides {
        display: flex;
        margin-top: vw(20px);
        position: relative;
        .compare-left {
            width: vw(276.5px);
            height: vw(532px);
            border-right: vw(1px) solid $table-lines;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: vw(20px);
            @include gap-compat-1(vw(20px), column);
            &.empty {
                justify-content: center;
            }
        }
        .bar-graph-container {
            flex: 1;
            margin-left: vw(50px);
            margin-right: vw(100px);
            align-self: center;
        }
        button.enlarge {
            position: absolute;
            top: vw(10px);
            right: vw(50px);
        }
    }
    hr {
        margin: vw(10px) 0;
        border: none;
        height: vw(1px);
        background-color: $table-lines;
    }
    .disclaimer {
        font: normal normal normal vw(14px) Calibri;
        line-height: vw(18px);
        color: $abbott-dark-grey;
        background-color: #fafafa;
        border-radius: vw(5px);
        padding: vw(20px);
        padding-bottom: vw(18px);
        margin: vw(20px) vw(50px);
    }
    .empty-message {
        color: $abbott-dark-grey;
        font: normal normal normal vw(16px) MercuryTextG1;
        line-height: vw(22px);
        margin-left: vw(50px);
        margin-right: vw(20px);
    }
}
</style>
