<template>
    <label class="app-radio">
        <input
            type="radio"
            :value="value"
            :checked="isChecked"
            @input="$emit('update:modelValue', $event.target.value)"
        />
        <span class="radio-button"></span>
        <span class="label" v-html="label"></span>
    </label>
</template>

<script>
export default {
    name: "AppRadio",
    props: {
        modelValue: { default: "" },
        value: String,
        label: String,
    },
    emits: ["update:modelValue"],
    computed: {
        isChecked() {
            return this.modelValue == this.value;
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
.app-radio {
    display: block;
    margin-bottom: vw(15px);
    cursor: pointer;
    font-size: vw(16px);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    @include gap-compat-1(vw(10px));
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    input:checked ~ .radio-button:after {
        display: block;
    }
    .radio-button {
        @include round(vw(20px));
        border: vw(1px) solid $lightblue-border;
        display: flex;
        justify-content: center;
        align-items: center;
        &:after {
            content: "\200b"; // zero width space
            display: none;
            background-color: $abbott-blue;
            @include round(vw(10px));
        }
    }
    .label {
        font: normal normal normal vw(16px) Calibri;
        line-height: vw(18px);
        vertical-align: middle;
    }
}
</style>
