// ziparchive.js
export default {
    unzipCallback: null,
    /**
     * Unzips a zip file to the same directory that the zip file resides in
     * @method unzip
     * @param {String} path - (required), the path to the zip file; path must begin with '/' which maps to the documents directory within this application.  Only one unzip process can be underway at any given point in time.
     * @param {Function} callBack - (optional), a function to call when the unzipping is complete that accepts no arguments
     */
    unzip(path, callBack) {
        this.unzipCallback = callBack;
        if (this.isDesktop) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.unzip(this.Base64.encode(path));
        } else {
            window.webkit.messageHandlers.unzip.postMessage(path);
            // window.location = "unzip://foo/bar?sid="+Math.random()+"&path="+this.Base64.encode(path);
        }
    },

    /**
     * Event listener method to inform you that the current unzip process has completed.
     */
    unzipCompleted(response) {
        if (this.unzipCallback) {
            this.unzipCallback(response);
        }
    },
};
