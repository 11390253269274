<template>
    <div class="graph-view">
        <main
            id="graph-main"
            class="screenshot-expand scroll-y"
            v-dragscroll="$isWeb"
        >
            <ScrollForMore forId="graph-main" />
            <header>
                <hr class="gradient" :class="gradientClass" />
                <div class="heading-device-support-level">
                    DEVICE SUPPORT LEVEL
                </div>
                <!-- todo look up go vs push effect on history -->
                <h1>
                    <img
                        class="back-link"
                        @click="$router.push(`/product/${wireId}`)"
                        src="@/assets/images/Icon_Arrow_Back.svg"
                    />
                    <span v-html="wire.name"></span>
                </h1>

                <div class="buttons">
                    <button class="compare" @click="toggleCompare">
                        <label
                            >{{ isSelected ? "REMOVE FROM" : "ADD TO" }}<br />
                            COMPARE LIST</label
                        >
                        <div
                            class="btn add"
                            :class="{ selected: isSelected }"
                        ></div>
                    </button>
                    <!-- <button class="pdf"></button> -->
                </div>
            </header>
            <div class="graph-area">
                <WireGraph
                    :wires="[wire]"
                    width="882"
                    height="534"
                    v-if="hasGraph"
                />
                <div v-else class="no-graph"><h2>Not Available</h2></div>
                <button
                    class="zoom-out"
                    @click="$router.push(`/product/${wireId}`)"
                ></button>
            </div>
            <div v-if="wire.disclaimer" class="disclaimer" v-html="wire.disclaimer"></div>
            <div
                class="footnote"
                v-html="getFooter"
                @click.prevent="$root.linkNative($event)"
            ></div>
        </main>
    </div>
</template>

<script>
import WireGraph from "@/components/WireGraph.vue";
import ScrollForMore from "@/components/ScrollForMore.vue";
import { mapState, mapMutations, mapGetters } from "vuex";
import strtolower from "locutus/php/strings/strtolower";
export default {
    components: { WireGraph, ScrollForMore },
    props: ["wireId"],
    data() {
        return {};
    },
    computed: {
        wire() {
            return this.guideWiresById[this.wireId];
        },

        ...mapState(["compareWireIds"]),
        ...mapGetters(["getFooter","guideWiresById"]),
        isSelected() {
            return this.compareWireIds.includes(parseInt(this.wireId));
        },
        gradientClass() {
            return strtolower(this.wire.category);
        },
        hasGraph() {
            return !!this.wire.graph && !!Object.keys(this.wire.graph).length;
        },
    },
    methods: {
        ...mapMutations([
            "setCurrentSection",
            "setCurrentMaker",
            "setCurrentCategory",
        ]),
        toggleCompare() {
            // todo dry
            let action = this.isSelected
                ? "compareWireIdsRemove"
                : "compareWireIdsPush";
            this.$store.dispatch(action, this.wireId);
        },
        init() {
            this.setCurrentMaker(this.wire.maker);
            this.setCurrentCategory(this.wire.category);
        },
    },
    mounted() {
        this.setCurrentSection("products");
        this.init();
    },
    watch: {
        // no graph to graph link but you never know.
        wireId() {
            // todo route instead?
            this.init();
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
.graph-view .no-graph {
    width: vw(882px);
    height: vw(300px);
}
</style>
