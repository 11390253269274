// appSSO.js starts authentication. response received at sso view
import tbaWrapper from "@/helpers/tbaWrapper";
import router from "../router";
import store from "../store/index.js";
import { HOST } from "../constants";

const appSSO = {
    // cmsSsoUrl: "https://apps.tbadigital.com/abv1937/test", // todo store in .env
    cmsSsoUrl: `${HOST}/login?apilogin=1`,
    authenticate() {
        store.commit("setIsAuthenticating", true); // to be cleared after the next navigation
        tbaWrapper.webAuth(this.cmsSsoUrl, this.returnUrl, this.iosCallback);
    },
    returnUrl:
        location.origin +
        location.pathname +
        router.resolve({ name: "sso" }).href,

    iosCallback: tbaWrapper.isiOS
        ? function (returnedUrl = "", wrapperError = "") {
              const query = new URL(returnedUrl).searchParams; // todo fix Typeerror here when user cancels
              query.set("error", query.get("error") ?? "" + wrapperError);
              query.set("result", query.get("result") ?? "");
              router.push("/sso/?" + query.toString());
          }
        : null,
};
export default appSSO;
