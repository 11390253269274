<template>
    <div class="status-bar"></div>
</template>
<style lang="scss" scoped>
@import "@/assets/scss/base/variables";
.status-bar {
    height: $status-bar-height;
    border-bottom: vw(1px) solid $table-lines;
}
</style>
