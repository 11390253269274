// orientation.js
export default {
    isPortraitCallBack: null,
    /**
     * iOS only
     * Function to get the current orientation of the device; helps in iOS landscape where the javascript screen.width/height still returns the portrait dimensions even if landscape
     * If you want to listen for orientation changes; you are encouraged to use an event listener like the one below in conjunction with this method:<br />
        window.onresize = function(event) {<br />
            &nbsp;&nbsp;tbaWrapper.currentOrientation(function(r){some change handler code...});<br />
        };<br />
     * @method currentOrientation
     * @param {Function} callBack - Function that accepts one String argument; Portrait, PortraitUpsideDown, LandscapeLeft, LandscapeRight
     */
    currentOrientation(callBack) {
        if (callBack) {
            this.isPortraitCallBack = callBack;
        }
        if (this.isiOS) {
            window.webkit.messageHandlers.currentorientation.postMessage("");
            // window.location = "currentorientation://://foo/bar?sid="+Math.random();
        }
    },

    /**
     * Function to dispatch the call back for the isPortrait function
     * @param response - Boolean
     */
    currentOrientationResponse(response) {
        if (this.isPortraitCallBack) {
            this.isPortraitCallBack(response);
            this.isPortraitCallBack = null;
        }
    },
    /**
     * Set the current device orientation.
     * @method setOrientation
     * @param {String} orientation - The orientation to set the app to (landscape or portrait)
     */
    setOrientation(orientation) {
        if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.setOrientation(orientation);
        }
        if (this.isiOS) {
            window.webkit.messageHandlers.setOrientation.postMessage(
                orientation
            );
        }
    },
};
