<template>
    <ModalProgress :heading="heading" v-show="isSyncing" />
</template>

<script>
import { mapState } from "vuex";
import ModalProgress from "./ModalProgress.vue";
export default {
    components: { ModalProgress },
    computed: {
        ...mapState(["isSyncing", "apiData"]),
        heading() {
            return this.apiData ? "CHECKING FOR UPDATES" : "DOWNLOADING DATA";
        },
    },
};
</script>
