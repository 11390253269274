// uploadfile.js
export default {
    uploadFilesCallBackProgress: null,
    uploadFilesCallBackError: null,
    /**
     * iOS only
     * Function to upload one or more files to a server.  If a current upload batch is under way; you cannot start another one until its completed. Only supported on iOS
     * Sample PHP code to receive the file upload:<br />
     * <?php<br />
     * set_time_limit(2000);<br />
     * if(isset($_FILES['userfile'])) {<br />
     *    &nbsp;&nbsp;$directory = $_SERVER['DOCUMENT_ROOT'] . '/upload/';//where you want to store your file?<br />
     *    &nbsp;&nbsp;$file = basename($_FILES['userfile']['name']);<br />
     *    &nbsp;&nbsp;$uploadfile = $directory . $file;<br />
     *    &nbsp;&nbsp;error_log('uptest: file=' . $file);<br />
     *    &nbsp;&nbsp;if (move_uploaded_file($_FILES['userfile']['tmp_name'], $uploadfile)) {<br />
     *        &nbsp;&nbsp;&nbsp;&nbsp;echo '{json: "to return to app"}';<br />
     *        &nbsp;&nbsp;&nbsp;&nbsp;error_log('uptest: successful');<br />
     *    &nbsp;&nbsp;} else {<br />
     *        &nbsp;&nbsp;&nbsp;&nbsp;error_log('uptest: unsuccessful');<br />
     *    &nbsp;&nbsp;}<br />
     * } else {<br />
     *    &nbsp;&nbsp;error_log('uptest: empty file data');<br />
     * }<br />
     * ?>
     * @method uploadFiles
     * @param {Array} paths - (required), of Strings representing the local files that need to be uploaded.
     *                        Paths all reference the local documents directory as their base; the root of which is accessed by '/'.
     *                        [NEW]: Each element could be an array of objects with path, name and additional values { path: '/mydocument.pdf', name:'userfile', myownparameter: 'myownvalue' }
     * @param {String} url - (required), the URL to upload to
     * @param {Array} headers - (optional) Array of objects.  Each object must have one key / value pair which will be used as HTTP headers to be sent to the server with the upload request
     * @param {Function} callBackProgress - (optional), the call back that will tell you when each file in the group has completed its upload. The function should accept three arguments;
     * first is number of files completed; the second is the total number of files in this batch; the third is a string returned from the server upload page
     * @param {Function} callBackError - (optoinal), the call back to be executed if there is an error with this upload batch; should take no arguments; once an error is encountered the batch is automatically cancelled.
     */
    uploadFiles(paths, url, headers, callBackProgress, callBackError) {
        if (callBackProgress) {
            this.uploadFilesCallBackProgress = callBackProgress;
        }
        if (callBackError) {
            this.uploadFilesCallBackError = callBackError;
        }
        if (this.isiOS) {
            window.webkit.messageHandlers.uploadfiles.postMessage({
                paths: paths,
                url: url,
                headers: headers,
            });
        }
    },

    /**
     * Function to dispatch callback for file upload errors
     */
    uploadFileError() {
        if (this.uploadFilesCallBackError) {
            this.uploadFilesCallBackError();
            this.uploadFilesCallBackError = null;
        }
    },

    /**
     * Function to dispatch callback for file upload progress
     */
    uploadFileProgress(completed, total, response) {
        if (this.uploadFilesCallBackProgress) {
            this.uploadFilesCallBackProgress(
                completed,
                total,
                this.Base64.decode(response)
            );
            if (completed == total) {
                this.uploadFilesCallBackProgress = null;
            }
        }
    },
};
