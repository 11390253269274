<template>
    <div class="app-dropdown noselect">
        <div class="button" :class="{ open: isOpen }" @click="isOpen ^= true">
            {{ _options[selected] }}
        </div>
        <div class="options" :class="{ open: isOpen }">
            <div
                v-for="(option, i) in _options"
                :key="i"
                class="option"
                :class="{ selected: i == selected }"
                @click="
                    $emit('change', i);
                    isOpen = false;
                "
                v-html="option"
            ></div>
        </div>
    </div>
</template>

<script>
import array_combine from "locutus/php/array/array_combine";
export default {
    name: "AppDropdown",
    // options can be array or key value object
    props: ["options", "selected"],
    data() {
        return {
            isOpen: false,
        };
    },
    methods: {
        clickAwayClose(e) {
            if (!this.$el.contains(e.target)) this.isOpen = false;
        },
    },
    computed: {
        _options() {
            let options = this.options;
            return Array.isArray(options)
                ? array_combine(options, options)
                : options;
        },
    },
    mounted() {
        document.addEventListener("click", this.clickAwayClose);
    },
    beforeUnmount() {
        document.removeEventListener("click", this.clickAwayClose);
    },
};
</script>
