<template>
    <div
        id="popup-info-bar"
        v-if="popupInfoBar.isVisible"
        :class="popupInfoBar.type"
    >
        <div class="icon-area">
            <div class="icon"></div>
        </div>
        <div class="message">{{ popupInfoBar.message }}</div>
        <div class="close" @click="popupInfoBarClose"></div>
    </div>
</template>

<script>
// todo close after timeout or on next navigation
import { mapState, mapActions } from "vuex";
export default {
    computed: mapState(["popupInfoBar"]),
    methods: mapActions(["popupInfoBarClose"]),
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
#popup-info-bar {
    position: fixed;
    top: vw(40px);
    left: vw(50px);
    width: vw(926px);
    min-height: vw(50px);
    border-radius: vw(5px);
    // &.success is default
    background-color: $color-success;
    &.warning {
        background-color: $color-warning;
        .icon-area {
            // svg circle with transparent exclamation
            background-image: url(@/assets/images/Icon_Warning.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: vw(16px);
            .icon {
                display: none;
            }
        }
    }
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    .icon-area {
        width: vw(50px);
        min-height: vw(50px);
        height: 100%;
        background-color: #ffffff29; // white opacity 0.16
        display: flex;
        justify-content: center;
        align-items: center;
        // css circle with svg checkmark
        .icon {
            @include round(vw(16px));
            background-color: white;
            background-image: url(@/assets/images/Icon_Check_Green.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: vw(9.18px);
        }
    }
    .message {
        color: white;
        font: normal normal normal vw(16px) BrandonGrotesque;
        line-height: vw(24px);
        display: flex;
        align-items: center;
    }
    .close {
        width: vw(56px);
        background: url("@/assets/images/Icon_X_White.svg.png") center no-repeat;
        background-size: vw(15.5px);
        cursor: pointer;
    }
}
</style>
