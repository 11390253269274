import { createRouter, createWebHashHistory } from "vue-router";
import LobbyView from "../views/LobbyView.vue";
import SsoView from "../views/SsoView.vue";
import DisclaimerView from "../views/DisclaimerView.vue";
import HomeView from "../views/HomeView.vue";
import ProductTypesView from "../views/ProductTypesView.vue";
import ProductsView from "../views/ProductsView.vue";
import ProductView from "../views/ProductView.vue";
import GraphView from "../views/GraphView.vue";
import CompareView from "../views/CompareView.vue";
import CompareBigView from "../views/CompareBigView.vue";
import ResourcesView from "../views/ResourcesView.vue";
import ComparePDF from "../views/ComparePDFView.vue";
import ComparePDFDev from "../views/ComparePDFDevView.vue";
import store from "../store/index.js";
import { page_view } from "@/helpers/AnalyticsHelper";
import * as sessionRecord from "@/helpers/SessionRecordingHelper";
import { ROUTE_TO_CATEGORYMAKER } from "@/constants";
import basename from "locutus/php/filesystem/basename";
import * as analyticsCustom from "@/helpers/AnalyticsCustomHelper";

const routes = [
    {
        path: "/lobby",
        name: "lobby",
        component: LobbyView,
        meta: {
            template: "Lobby",
            templateHasNoRegion: true,
        },
    },
    {
        path: "/sso",
        name: "sso",
        component: SsoView,
    },
    {
        path: "/disclaimer",
        name: "disclaimer",
        component: DisclaimerView,
        meta: {
            template: "Login Disclaimer",
        },
    },
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {
            template: "Home",
        },
    },
    {
        path: "/product-types",
        name: "product-types",
        component: ProductTypesView,
        meta: {
            template: "Home",
        },
    },
    {
        path: "/products/:type/:maker",
        name: "products",
        component: ProductsView,
        props: true,
        meta: {
            hasNoTemplate: true,
        },
    },
    {
        path: "/product/:wireId",
        name: "product",
        component: ProductView,
        props: true,
        meta: {
            hasNoTemplate: true,
        },
    },
    {
        path: "/graph/:wireId",
        name: "graph",
        component: GraphView,
        props: true,
        meta: {
            hasNoTemplate: true,
        },
    },
    {
        path: "/compare/:comparisonProperty",
        name: "compare",
        component: CompareView,
        props: true,
        meta: {
            template: "Compare In-App",
        },
    },
    {
        path: "/compare-big/:comparisonProperty",
        name: "compare-big",
        component: CompareBigView,
        props: true,
        beforeEnter() {
            // can't normally get here when empty.
            if (store.getters.comparisonIsEmpty)
                return { path: "/compare/supportLevel" };
        },
        meta: {
            template: "Compare In-App",
        },
    },
    {
        path: "/compare-pdf",
        name: "compare-pdf",
        component: ComparePDF,
        props: true,
        beforeEnter() {
            // can't normally get here when empty.
            if (store.getters.comparisonIsEmpty)
                return { path: "/compare/supportLevel" };
        },
        meta: {
            template: "Compare PDF Printout",
        },
    },
    // todo merge with above, above to use ModalGeneratePdf
    {
        path: "/compare-pdf-dev/:comparisonProperty/:pageNum",
        name: "compare-pdf-dev",
        component: ComparePDFDev,
        props: true,
        beforeEnter() {
            // can't normally get here when empty.
            if (store.getters.comparisonIsEmpty)
                return { path: "/compare/supportLevel" };
        },
        meta: {
            template: "Compare PDF Printout",
        },
    },
    {
        path: "/resources",
        name: "resources",
        component: ResourcesView,
        meta: {
            template: "Resources",
        },
    },
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to) => {
    // re-enable restore current page after restart
    if (store.state.isAuthenticating)
        store.commit("setIsAuthenticating", false);
    // todo this might be a good place to clear the logged in info bar too
    // if page requires data and no data --> send to lobby
    if (
        !store.state.apiData?.guide_wires &&
        !["lobby", "disclaimer", "sso"].includes(to.name)
    ) {
        console.log("routing to lobby");
        return { name: "lobby" };
    }
    // nothing requires login right now
    if (!store.state.isLoggedIn && [].includes(to.name)) {
        console.log("cancelling navigation");
        // cancel navigation
        return false;
    }

    if (store.state.isReviewingMat && navWouldChangeMat(to)) {
        if (await confirmExitReview()) {
            store.commit("setIsReviewingMat", false);
        } else {
            return false;
        }
    }

    // save route in store for getPageTemplate() getter and startup restore
    // fullPath for restore (includes query string eg /path?this=that)
    // meta needs to be in store for getPageTemplate to be reactive
    // https://javascript.tutorialink.com/vue-js-get-current-route-in-vuex-module/
    let subset = ({ fullPath, meta }) => ({ fullPath, meta });
    store.commit("setcurrentRoute", subset(to));
});

async function confirmExitReview() {
    return await store.dispatch("modalConfirm", {
        title: "EXIT CONTENT REVIEW",
        message:
            "The content you are attempting to access is outside of the MAT number content you have been reviewing",
        okButton: "YES, EXIT REVIEW",
        okBtnStyle: "background-color:black;",
    });
}
function navWouldChangeMat(route) {
    return store.getters.getCurrentMatObject.id != getRouteMatId(route.path);
}
function getRouteMatId(route) {
    var cm = ROUTE_TO_CATEGORYMAKER[route];
    return (
        (cm
            ? store.getters.getViewingMatsByCategoryMaker[cm]?.id
            : store.getters.guideWiresById[parseInt(basename(route))]
                  ?.mat_id) ?? 0
        //) close bracket would be here for clarity but autoformatter
        // ?? 0
    );
}

router.afterEach(async () => {
    // skip if generating pdf
    if (store.state.pdf.isGeneratingPdf) return;
    page_view();
    analyticsCustom.pageView();
    sessionRecord.pageView();
});

export default router;
