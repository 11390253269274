<template>
    <div
        class="compare-list-item"
        :class="{ highlight: isHighlighted }"
        @click="setHighlight"
    >
        <button class="delete" @click.stop="$emit('remove')"></button>
        <div class="labels">
            <div class="brand-type">{{ `${wire.maker} ${wire.category}` }}</div>
            <div class="name" v-html="wire.name"></div>
        </div>
        <div
            :class="{ checkbox: 1, checked: isShowing }"
            @click.stop="toggleIsShowing"
        ></div>
        <div :class="`color-dot bgcolor-${colorIndex}`"></div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
    props: ["wire"],
    data() {
        return {};
    },
    computed: {
        ...mapState(["comparisonById", "comparisonHighlightId"]),
        colorIndex() {
            return this.comparisonById[this.wire.id].colorIndex;
        },
        isShowing() {
            return this.comparisonById[this.wire.id].isShowing;
        },
        isHighlighted() {
            return this.comparisonHighlightId == this.wire.id;
        },
    },
    methods: {
        ...mapActions(["compareWireIdsRemove"]),
        ...mapMutations([
            "comparisonIsShowingToggle",
            "comparisonHighlightIdSet",
        ]),
        toggleIsShowing() {
            this.comparisonIsShowingToggle(this.wire.id);
        },
        setHighlight() {
            this.comparisonHighlightIdSet(
                this.isHighlighted ? 0 : this.wire.id
            );
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
@import "@/assets/scss/base/button";
.compare-list-item {
    min-height: vw(50px);
    width: vw(266px);
    margin: 0 auto;
    display: flex;
    align-items: center;
    &.highlight {
        background-color: #efefef;
        border-radius: vw(5px);
    }
    button.delete {
        @include round-30;
        background-color: black;
        color: white;
        margin-left: vw(13px);
        margin-right: vw(7px);
        background-image: url("@/assets/images/Icon_X_White.svg.png");
        background-size: vw(14px);
    }
    .labels {
        flex: 1;
        color: $abbott-dark-grey;
    }
    .brand-type {
        font: normal normal bold vw(11px) Calibri;
        line-height: vw(12px);
        text-transform: uppercase;
    }
    .name {
        font: normal normal bold vw(14px) Calibri;
        line-height: vw(18px);
    }
    .checkbox {
        width: vw(30px);
        height: vw(30px);
        border: vw(1px) solid $lightblue-border;
        &.checked {
            background: url("@/assets/images/Icon_Check_Blue.svg.png") center
                no-repeat;
            background-size: vw(16px);
        }
    }
    .color-dot {
        @include round(vw(10px));
        margin: vw(11px);
    }
}
</style>
