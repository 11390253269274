import { jsPDF } from "jspdf"
// requires ttf. used https://onlinefontconverter.com/
import Brandon_Bold             from "@/assets/fonts/b64.js/Brandon_Bold.ttf.b64.js";
import Brandon_BoldItalic       from "@/assets/fonts/b64.js/Brandon_BoldItalic.ttf.b64.js";
import Brandon_Medium           from "@/assets/fonts/b64.js/Brandon_Medium.ttf.b64.js";
import Brandon_MediumItalic     from "@/assets/fonts/b64.js/Brandon_MediumItalic.ttf.b64.js";
// import Brandon_Regular          from "@/assets/fonts/b64.js/Brandon_Regular.ttf.b64.js";
// import Brandon_RegularItalic    from "@/assets/fonts/b64.js/Brandon_RegularItalic.ttf.b64.js";

// import mercury from "@/assets/fonts/b64.js/MercuryTextG1_Roman.ttf.b64";
// import baskervaldADFStd from "@/assets/fonts/b64.js/BaskervaldADFStd.ttf.b64";
// import georgia from "@/assets/fonts/b64.js/Georgia.ttf.b64";
import georgia from "@/assets/fonts/b64.js/Georgia_Regular.ttf.b64";
// import georgia from "@/assets/fonts/b64.js/GEORGIA4.TTF.b64";
import georgiaB from "@/assets/fonts/b64.js/georgia_bold.ttf.b64";
import georgiaBI from "@/assets/fonts/b64.js/georgia_bold_italic.ttf.b64";
import georgiaI from "@/assets/fonts/b64.js/georgia_italic.ttf.b64";

var addFont = function (font, name, id, style, weight) {
    var callAddFont = function(){
        // could also do everything in here and call push once.
        this.addFileToVFS(name, font);
        this.addFont(name, id, style, weight);
    }
    jsPDF.API.events.push(['addFonts', callAddFont]);
};

addFont(Brandon_Bold,          "Brandon_Bold.ttf",          "BrandonGrotesque", "normal", "bold");
addFont(Brandon_BoldItalic,    "Brandon_BoldItalic.ttf",    "BrandonGrotesque", "italic", "bold");
addFont(Brandon_Medium,        "Brandon_Medium.ttf",        "BrandonGrotesque", "normal", "normal");
addFont(Brandon_MediumItalic,  "Brandon_MediumItalic.ttf",  "BrandonGrotesque", "italic", "normal");

// addFont(Brandon_Regular,       "Brandon_Regular.ttf",       "BrandonGrotesque", "normal", "normal");
// addFont(Brandon_RegularItalic, "Brandon_RegularItalic.ttf", "BrandonGrotesque", "italic", "normal");

// addFont(mercury, 'MercuryTextG1-normal.ttf', 'MercuryTextG1', 'normal', 'normal');
// MercuryTextG1 Replacement because jspdf has issues with the font.
// addFont(baskervaldADFStd,  "BaskervaldADFStd.ttf", "MercuryTextG1", "normal","normal");
addFont(georgia,  "Georgia.ttf", "Georgia", "normal","normal");
addFont(georgiaB,  "Georgia.ttf", "Georgia", "normal","bold");
addFont(georgiaBI,  "Georgia.ttf", "Georgia", "italic","bold");
addFont(georgiaI,  "Georgia.ttf", "Georgia", "italic","normal");
