import store from "../store";
import util from "./util";
import pLimit from "p-limit";
import tbaWrapper from "@/helpers/tbaWrapper";
import basename from "locutus/php/filesystem/basename";
import dirname from "locutus/php/filesystem/dirname";
import { OLD_API } from "@/constants";
import ltrim from "locutus/php/strings/ltrim";

export async function sync() {
    console.log("downloading wire images");
    await downloadWires();
    console.log("downloading resources");
    await downloadResources();
    console.log("downloads done");
}
export function src(apiFilename) {
    if (OLD_API) return "";
    return `${store.getters.getFileBasePath}/${relativeBasePath(apiFilename)}`;
}
export function localSrc(path) {
    return `${store.getters.getFileBasePath}/${path}`;
}
export function wireImgSrc(wire) {
    let apiFilename = OLD_API ? wire.image : wire.image_filename;
    if (!apiFilename) return "";
    return `${store.getters.getFileBasePath}/${relativeBasePath(apiFilename)}`;
}
async function downloadWires() {
    // let apiFilenames = store.state.apiData.guide_wires.map((w) =>
    //     OLD_API ? w.image : w.image_filename
    // );
    var apiFilenames = [];
    for (const w of store.state.apiData.guide_wires) {
        if (w.image_filename) apiFilenames.push(w.image_filename);
        else console.error("wire.image_filename is missing", w);
    }
    let withPath = await relativePaths(apiFilenames);
    // apiFilenames = store.state.apiData.guide_wires.map(
    //     (w) => w.support_filename
    // );
    // console.log("support apiFilenames", apiFilenames)
    // let support = await relativePaths(apiFilenames);
    // console.log('support paths', support);

    return downloadFiles(withPath);
}
async function downloadResources() {
    let apiFilenames = definedValsWithLog(
        store.state.apiData.resources.map((r) => r.filename),
        "resourceFile"
    );
    let withPath = await relativePaths(apiFilenames);
    if (!OLD_API) {
        let apiFilenames = definedValsWithLog(
            store.state.apiData.resources.map((r) => r.thumbnail),
            "thumbnail"
        );
        withPath = withPath.concat(await relativePaths(apiFilenames));
    }
    return downloadFiles(withPath);
}
function definedValsWithLog(a, itemName) {
    logUndefined(a, `undefined ${itemName}(s)`);
    return a.filter((x) => x);
}
function logUndefined(a, errorMessage) {
    let undef = a.filter((x) => !x);
    if (undef.length) console.error(errorMessage);
}
function relativeBaseDir(apiFilename) {
    return dirname(relativeBasePath(apiFilename));
}
export function relativeBasePath(apiFilename) {
    return OLD_API
        ? apiFilename.slice("/assets/".length)
        : relativeDownloadPath(apiFilename);
}
async function relativePaths(apiFilenames) {
    console.log("first filename", apiFilenames[0]);
    let relativeBase = relativeBaseDir(apiFilenames[0] ?? "");
    console.log("relativeBase", relativeBase);
    let files = uniqueBasenames(apiFilenames);
    console.log("files", files);
    return await filesToGet(relativeBase, files);
}
function uniqueBasenames(apiFilenames) {
    let names = new Set();
    for (const apiFile of apiFilenames) {
        names.add(basename(apiFile));
    }
    return [...names];
}

function relativeDownloadPath(downloadUrl) {
    // no leading slash
    return downloadUrl?.slice(store.getters.getDownloadBaseUrl.length + 1);
}
// returns relative paths of files not downloaded
async function filesToGet(relativeBaseDir, fileBasenames) {
    const haveFiles = await pLsWorkaround(`/${relativeBaseDir}/`);
    const filesToGet = util.arrayDiff(fileBasenames, haveFiles);

    console.log("relativeBaseDir", relativeBaseDir);
    console.log("files.length", fileBasenames.length);
    console.log("haveFiles.length", haveFiles.length);
    console.log("filesToGet.length", filesToGet.length);
    if (haveFiles.length > fileBasenames.length) {
        const extraFiles = util.arrayDiff(haveFiles, fileBasenames);
        console.log("extraFiles", extraFiles);
    }

    let withPath = filesToGet.map((f) => `${relativeBaseDir}/${ltrim(f, "/")}`);
    return withPath;
}
// todo catch all errors and display them all at the end with filenames eg: 403, 404
async function downloadFiles(filePaths) {
    const writeLimit = pLimit(1);
    var downloads = [];
    // No need to limit downloads because browser limits to 6 concurrent
    // Wow! actually not true. from S3 they all download at once.
    // only need to limit file writes to 1 at a time
    for (const file of filePaths) {
        downloads.push(
            new Promise((resolve) => {
                let url = `${store.getters.getDownloadBaseUrl}/${file}`;
                console.log("getting url", url);
                // todo fix 403 to error out instead of writing file with xml
                util.downloadBase64(url, (b64Data) => {
                    let localFilePath = file;
                    resolve(
                        writeLimit(() =>
                            pFilePutContents(localFilePath, b64Data)
                        )
                    );
                });
            })
        );
    }
    return Promise.all(downloads);
}
// for testing. does not work on iOS
// https://dev.to/nombrekeff/download-file-from-blob-21ho
export function downloadBlob(blobOrDataUri, name = "file.txt") {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl =
        blobOrDataUri instanceof Blob
            ? URL.createObjectURL(blobOrDataUri)
            : blobOrDataUri;

    // Create a link element
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = name;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
        new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window,
        })
    );

    // Remove link from body
    document.body.removeChild(link);
}
// not for Android. todo integrate this into wrapper pFilePutContents
export async function pFetchLocal(path) {
    return await fetch(localSrc(path)).then((res) => res.arrayBuffer());
}
// wrapper stuff -------------------------------------------------------------
// todo move to wrapper
// (filename, base64Data)
export function pFilePutContents(...args) {
    return new Promise((resolve) => {
        tbaWrapper.filePutContents(...args, (error) => {
            if (error) console.error(error);
            resolve(error);
        });
    });
}
/**
 * promise version of wrapper.ls todo move to wrapper
 * @param {string} path
 * @returns
 */
function pLs(path, onlyNames = false) {
    return new Promise((resolve) => {
        tbaWrapper.ls(path, (lsResult) => {
            resolve(onlyNames ? lsResult.map((f) => f.name) : lsResult);
        });
    });
}
// todo move these to wrapper
async function pLsWorkaround(path) {
    // for android limitation
    if (!(await pLocalPathExists(path))) return [];
    return pLs(path, true);
}

async function pLocalPathExists(path) {
    // android wrapper barfs if trying to ls a path that does not exist.
    // noted todo in wrapper. this avoids it for now.
    if (path == "/") return true;
    let dirs = path.split("/").filter((a) => a != "");
    var testPath = "/";
    for (const dir of dirs) {
        let ls = await pLs(testPath, true);
        if (!ls.includes(dir)) return false;
        testPath += `${dir}/`;
    }
    return true;
}
