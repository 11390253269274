// filedownload.js
export default {
    downloadImageCallBack: null,
    downloadCallBack: null,
    downloadProgressCallBack: null,
    downloadErrorCallBack: null,
    fileName: null,
    /**
     * Download to Photos - now works with android.  checks and requests permissions from user if needed.
     * these files will be available for upload selection with choosePhoto()
     * on android it could take a couple minutes for it to show up in the selection
     * todo merge this with downloadFile. only diff should be where it goes.
     * this should have all the callbacks that downloadFile has. they can be re-used because only one file download can happen at a time.
     * @method downloadImage
     * @param {String} url - (required), the absolute address of the external file to download
     * @param {String} destination - filename (can be null)
     * @param {Function} completeCallBack - (optional), a function with one string argument representing the file name; to execute if the file download completes
     */
    downloadImage(url, destination, completeCallBack) {
        var f =
            new Date().getTime() +
            "_" +
            Math.round(Math.random() * 1000000) +
            url.substring(url.lastIndexOf("."));
        this.downloadImageCallBack = completeCallBack;
        this.fileName = destination;
        if (!destination) {
            this.fileName = f;
        }

        if (this.isDesktop || this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.downloadImage(this.Base64.encode(this.fileName), url);
        } else {
            window.webkit.messageHandlers.dwnldimg.postMessage({
                handle: this.fileName,
                url: url,
            });
        }
        return f;
    },

    /**
     * Downloads a file from the internet asynchronously per the specified argument. Progress updates are sent to a call back function.  Only 1 file can be downloading at a time
     * @method downloadFile
     * @param {String} url - (required), the absolute address of the external file to download
     * @param {Function} progressCallBack - (optional), a function with one decimal argument representing progress, and one string argument representing the file name; to execute when progress updates are received
     * @param {Function} completeCallBack - (optional), a function with one string argument representing the file name; to execute if the file download completes
     * @param {Function} errorCallBack - (optional), a function with two string arguments - one representing the file name; to execute if the download fails.  The other the status code for the failure - 0 means that the download failed after the initial response from the server.
     * @return {String} - A unique local file name with the same filetype extension of the file specified in the URL.
     */
    downloadFile(
        url,
        destination,
        progressCallBack,
        completeCallBack,
        errorCallBack
    ) {
        var f =
            new Date().getTime() +
            "_" +
            Math.round(Math.random() * 1000000) +
            url.substring(url.lastIndexOf("."));
        this.downloadProgressCallBack = progressCallBack;
        this.downloadCallBack = completeCallBack;
        this.downloadErrorCallBack = errorCallBack;
        this.fileName = destination;
        if (!destination) {
            this.fileName = f;
        }
        if (this.isDesktop || this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.downloadFile(
                this.Base64.encode(this.fileName),
                this.Base64.encode(url)
            );
        } else {
            window.webkit.messageHandlers.dwnld.postMessage({
                handle: this.fileName,
                url: url,
            });
            // window.location = "dwnld://foo/bar?handle="+this.Base64.encode(destination)+"&url="+this.Base64.encode(url);
        }
        return f;
    },

    /**
     * Downloads a file from the internet asynchronously per the specified argument. Also sends cookies from webview (required for apps that need security tokens).  Only 1 file can be downloading at a time
     * @method downloadWithCookies
     * @param {String} url - (required), the absolute address of the external file to download * @param {Function} completeCallBack - (optional), a function with one string argument representing the file name; to execute if the file download completes
     * @param {Function} errorCallBack - (optional), a function with two string arguments - one representing the file name; to execute if the download fails.  The other the status code for the failure - 0 means that the download failed after the initial response from the server.
     * @return {String} - A unique local file name with the same filetype extension of the file specified in the URL.
     */
    downloadWithCookies(url, destination, completeCallBack, errorCallBack) {
        var f =
            new Date().getTime() +
            "_" +
            Math.round(Math.random() * 1000000) +
            url.substring(url.lastIndexOf("."));
        this.downloadCallBack = completeCallBack;
        this.downloadErrorCallBack = errorCallBack;
        this.fileName = f;
        if (this.isDesktop || this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.downloadPDF(
                this.Base64.encode(destination),
                this.Base64.encode(url)
            );
        } else {
            console.log("Not supported in iOS yet");
        }
        return f;
    },
    /**
     * Can be used to update your interface with file download progress
     * @param progress - Number, decimal representing the fraction of currently downloaded data for the file currently downloading
     */
    didReceiveFileProgressUpdate(progress) {
        if (this.downloadProgressCallBack) {
            this.downloadProgressCallBack(progress, this.fileName);
        }
    },

    /**
     * Listener to notify if a file has finished downloading
     * @param fileID - String, the string returned form the downloadFile funciton
     */
    fileImageDidCompleteDownload() {
        if (this.downloadImageCallBack) {
            this.downloadImageCallBack(this.fileName);
            this.fileName = null;
        }
    },
    /**
     * Listener to notify if a file has finished downloading
     * @param fileID - String, the string returned form the downloadFile funciton
     */
    fileDidCompleteDownload() {
        if (this.downloadCallBack) {
            this.downloadCallBack(this.fileName);
            this.fileName = null;
        }
    },
    /**
     * Listener to notify if a file download failed
     * @param fileID - String, the string returned form the downloadFile funciton
     * @param status - int, the HTTP status code if available; otherwise 0  zero would probably denote that the datastream failed mid connection, rather than after the initial response from the server.
     */
    fileDownloadFailed(fileID, status) {
        if (this.downloadErrorCallBack) {
            this.downloadErrorCallBack(this.fileName, status);
        }
    },
};
