<template>
    <div class="modal" id="modal-progress">
        <div class="popup-progress">
            <div class="heading">{{ heading }}</div>
            <div class="progress-bar"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["heading"],
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
#modal-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    .popup-progress {
        background-color: white;
        box-shadow: vw(0px) vw(3px) vw(6px) #00000029;
        border-radius: vw(5px);
        padding: vw(40px) vw(23px) vw(30px);
        display: flex;
        flex-direction: column;
        @include gap-compat-1(vw(20px), column);
    }
    .heading {
        text-align: center;
        font: normal normal bold vw(24px) BrandonGrotesque;
        line-height: vw(34px);
    }
    .progress-bar {
        width: vw(424px);
        height: vw(12px);
        border: vw(1px) solid $light-grey;
        background-image: url(@/assets/images/ProgressBarSection.svg);
        background-size: vw(50px);
        background-position-x: 0;
        background-position-y: center;
        background-repeat: repeat-x;
        animation: progress 1s linear infinite;
    }
    @keyframes progress {
        0% {
            background-position-x: 0;
        }
        100% {
            background-position-x: vw(50px);
        }
    }
}
</style>
