<template>
    <ComparePDFPage />
    <PopupInfoBar />
    <ModalConfirm />
    <ModalGeneratePdf />
    <ModalStartupWhite />
</template>

<script>
import ComparePDFPage from "./ComparePDFPage.vue";
import ModalConfirm from "./ModalConfirm.vue";
import ModalGeneratePdf from "./ModalGeneratePdf.vue";
import ModalStartupWhite from "./ModalStartupWhite.vue";
import PopupInfoBar from "./PopupInfoBar.vue";
export default {
    components: {
        ModalConfirm,
        PopupInfoBar,
        ModalGeneratePdf,
        ComparePDFPage,
        ModalStartupWhite,
    },
};
</script>
