<template>
    <div
        id="modal-review"
        class="modal"
        @click.self="modalReviewClose"
        data-html2canvas-ignore
    >
        <div class="popup-review">
            <h2>REVIEW OPTIONS</h2>
            <AppTabs
                :options="tabOptions"
                :selected="currentTab"
                @change="currentTab = $event"
            />
            <div class="review-main scroll-y" v-dragscroll="$isWeb">
                <div class="columns">
                    <div class="column left">
                        <h3>REGION</h3>
                        <AppDropdown
                            class="dropdown-region"
                            :options="regions"
                            :selected="currentRegion"
                            @change="regionChanged"
                        />

                        <h3>STATUS</h3>
                        <AppRadio
                            v-show="isInApp"
                            v-for="(label, status) in statusOptionsInApp"
                            :key="status"
                            :value="status"
                            v-model="statusViewing"
                            :label="label"
                        />
                        <AppRadio
                            v-show="isPdf"
                            v-for="(label, status) in statusOptionsPdf"
                            :key="status"
                            :value="status"
                            v-model="statusPdf"
                            :label="label"
                        />
                        <!-- 
                        <h3>CONTENT TYPE</h3>
                        <AppCheckboxArray
                            v-model="selectedContentTypes"
                            :valueLabels="contentTypes"
                        /> -->

                        <div
                            class="lightgrey-box"
                            v-show="isInApp && statusViewing == 'For Review'"
                        >
                            <b>NOTE:</b> You can review a single
                            <i>For Review</i> MAT number in-app
                        </div>
                        <div class="lightgrey-box" v-show="isPdf">
                            <b>NOTE:</b> Selecting <i>All Content</i> for your
                            PDF will output all <i>For Review</i> and
                            <i>Published</i> content
                        </div>
                    </div>
                    <div class="column right">
                        <h3>MAT NUMBERS</h3>
                        <ModalReviewMatRadios
                            v-show="isInApp"
                            :status="statusViewing"
                            v-model="selectedMatIdPreview"
                        />
                        <!-- <ModalReviewMatSelect
                            v-show="isInApp"
                            :status="statusViewing"
                        /> -->
                        <ModalReviewMatSelect
                            v-show="isPdf"
                            :status="currentStatusPdf"
                            v-model="selectedMatIdsPdf"
                        />
                    </div>
                </div>
            </div>
            <div class="review-buttons">
                <button class="back" @click="modalReviewClose">
                    <span>BACK</span
                    ><img class="x" src="@/assets/images/Icon_X_Blue.svg" />
                </button>
                <button
                    v-show="isInApp"
                    class="review-content"
                    @click="review"
                    :disabled="!selectedMatIdPreview"
                >
                    REVIEW
                </button>
                <button
                    v-show="isPdf"
                    class="generate-pdf"
                    @click="generatePdf"
                    :disabled="!selectedMatIdsPdf.length"
                >
                    GENERATE PDF
                </button>
            </div>
        </div>
        <div class="shadow"></div>
    </div>
</template>

<script>
// todo split out the screenshot code
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
// import AppCheckboxArray from "./AppCheckboxArray.vue";
import AppDropdown from "./AppDropdown.vue";
import AppRadio from "./AppRadio.vue";
import ModalReviewMatSelect from "./ModalReviewMatSelect.vue";
import { REGIONS } from "@/constants";
import AppTabs from "./AppTabs.vue";
import ModalReviewMatRadios from "./ModalReviewMatRadios.vue";
import { MAT_ROUTE_BY_CATEGORYMAKER } from "@/constants";

const redDot = '<div class="dot red"></div>',
    greenDot = '<div class="dot green"></div>';

export default {
    components: {
        AppDropdown,
        AppRadio,
        // AppCheckboxArray,
        ModalReviewMatSelect,
        AppTabs,
        ModalReviewMatRadios,
    },
    data() {
        return {
            tabOptions: {
                inApp: "IN APP PREVIEW",
                pdf: "GENERATE PDF",
            },
            currentTab: "inApp",
            statusOptionsInApp: {
                "For Review": `${redDot} For Review`,
                Published: `${greenDot} Published`,
            },
            statusOptionsPdf: {
                // ALL: "All Content",
                "For Review": `${redDot} For Review`,
                Published: `${greenDot} Published`,
            },
            regions: REGIONS,
            selectedMatIdPreview: 0,
            // viewing: "all",
            // contentTypes: {
            //     lobby: "Lobby",
            //     coronary: "Coronary Pages",
            //     peripheral: "Peripheral Pages",
            //     landingOther: "Landing & Other Pages",
            //     comparisonPrintout: "Comparison Printout",
            // },
            // selectedContentTypes: ["lobby", "peripheral"],
        };
    },
    methods: {
        ...mapActions([
            "setCurrentRegion",
            "modalReviewClose",
            "generatePdf",
            "apiSync",
            "popupInfoBar",
        ]),
        ...mapMutations([
            "setCurrentStatusViewing",
            "setCurrentStatusPdf",
            "setIsReviewingMat",
        ]),
        regionChanged(region) {
            this.setCurrentRegion(region);
            //this.apiSync(); // api/review now gets all regions
            // maybe send back to disclaimer?
        },
        async review() {
            this.modalReviewClose();
            const mat = this.getMatById[this.selectedMatIdPreview];
            await this.$router.push(
                MAT_ROUTE_BY_CATEGORYMAKER[`${mat.category}${mat.maker ?? ""}`]
            );
            let message = `You are currently reviewing content for MAT# ${mat.code} v${mat.version.toFixed(1)}`;
            this.popupInfoBar({ message });
            this.setIsReviewingMat(true);
        },
    },
    computed: {
        ...mapState(["currentRegion", "currentStatusViewing"]),
        ...mapState({
            currentStatusPdf: (state) => state.pdf.currentStatusPdf,
        }),
        ...mapGetters(["getMatById"]),
        isInApp() {
            return this.currentTab == "inApp";
        },
        isPdf() {
            return this.currentTab == "pdf";
        },
        // two way binding of store properties for use with v-model
        statusViewing: {
            get() {
                return this.currentStatusViewing;
            },
            set(status) {
                this.setCurrentStatusViewing(status);
            },
        },
        statusPdf: {
            get() {
                return this.currentStatusPdf;
            },
            set(status) {
                this.setCurrentStatusPdf(status);
            },
        },
        selectedMatIdsPdf: {
            get() {
                return this.$store.state.pdf.selectedMatIdsPdf;
            },
            set(ids) {
                this.$store.commit("setSelectedMatIdsPdf", ids);
            },
        },
    },
    created() {
        console.log("ModalReviewOptions");
        console.log("currentRegion", this.currentRegion);
        console.log("regions", this.regions);
        console.log("REGIONS", REGIONS);
    },
    watch: {
        statusViewing() {
            this.selectedMatIdPreview = 0;
        },
        currentRegion() {
            this.selectedMatIdPreview = 0;
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
// todo dry with modalMenu
#modal-review {
    z-index: 2; // for scroll-for-more
    $review-width: vw(620px);
    &.modal {
        background-color: #00000026; // black 0.15
    }
    h2 {
        margin-top: vw(40px);
        margin-left: vw(40px);
        font: normal normal bold vw(18px) BrandonGrotesque;
        line-height: vw(26px);
        color: $dark-grey;
    }
    .app-tabs {
        padding-left: vw(40px);
        & > div {
            width: vw(260px);
        }
    }
    .dot {
        @include round(vw(8px));
        display: inline-block;
        &.red {
            background-color: $color-alert;
        }
        &.green {
            background-color: $color-success;
        }
    }
    .popup-review {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: $review-width;
        color: $dark-grey;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
    }
    .review-main {
        flex: 1 1;
        min-height: 0;
        padding: vw(40px);
        padding-top: vw(20px);
        padding-bottom: vw(25px);
    }
    .review-buttons {
        flex: 0 0 vw(90px);
        border-top: vw(2px) solid #e4e4e4;
        padding-left: vw(40px);
        padding-right: vw(50px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        button {
            height: vw(45px);
            padding-left: vw(39px);
            padding-right: vw(39px);
            font: normal normal bold vw(14px) BrandonGrotesque;
            background-color: black;
            color: white;
            &:disabled {
                background-color: $light-grey;
            }
            &.back {
                border: vw(2px) solid $light-grey;
                padding-right: vw(30px);
                background-color: white;
                color: $abbott-dark-grey;
                display: flex;
                align-items: center;
                @include gap-compat-1(vw(10px));
            }
        }
    }
    img.x {
        width: vw(15px);
        height: vw(15px);
    }
    .columns {
        height: 100%;
        display: flex;
        @include gap-compat-n(2, vw(20px));
        .column {
            height: 100%;
        }
        .left {
            width: vw(200px);
            display: flex;
            flex-direction: column;
        }
        .right {
            flex: 1;
            display: flex;
            flex-direction: column;
            #modal-review-mat-select {
                flex: 1;
                background-color: #eee;
            }
        }
    }
    p {
        font: normal normal normal vw(16px) MercuryTextG1;
        line-height: vw(18px);
        color: $abbott-dark-grey;
    }
    hr {
        border: 1px solid $abbott-blue;
        opacity: 0.2;
        margin-top: vw(20px);
        margin-bottom: vw(30px);
    }
    h3 {
        font: normal normal bold vw(11px) BrandonGrotesque;
        line-height: vw(16px);
        color: $abbott-dark-grey;
        margin-bottom: vw(5px);
    }
    .dropdown-region {
        margin-bottom: vw(30px);
        .button,
        .option {
            width: vw(200px);
        }
    }
    .lightgrey-box {
        margin-top: auto;
        // calculated $table-lines at 0.2 opacity on white
        background-color: #fafafa;
        border-radius: vw(5px);
        padding: vw(20px);
        color: $abbott-dark-grey;
        font-family: Calibri;
        font-size: vw(14px);
        line-height: vw(18px);
    }
    .shadow {
        position: absolute;
        top: 0;
        bottom: 0;
        right: $review-width;
        width: vw(10px);
        background-color: black;
        opacity: 0.1;
    }
}
</style>
