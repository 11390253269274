// AnalyticsCustomHelper.js
// sends comparison stats to CMS
import vm from "@/main";
import store from "../store";
import { HOST, COMPARISON_PROPERTY_NAMES } from "@/constants";

const DISABLED = false;
const comparelogUrl = `${HOST}/api/comparison`;

export function pageView() {
    switch (vm.$route.name) {
        case "compare":
        case "compare-big":
        case "compare-pdf":
            customComparelog(); // send to Guidewire Server
            break;
    }
}
function customComparelog() {
    if (DISABLED) return;
    let txData = customComparelogData();
    if (!txData) return;
    let opts = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        cache: "no-cache",
        body: JSON.stringify(txData),
    };
    fetch(comparelogUrl, opts);
}
function customComparelogData() {
    // returns data to send or false when should not send
    // not offline but can be
    const time = new Date().getTime();
    const wireIds = store.state.compareWireIds;
    return wireIds && wireIds.length
        ? {
              currentTime: time,
              comparisons: [
                  {
                      comparisonTime: time,
                      region: store.state.currentRegion,
                      page: vm.$route.name,
                      comparisonProperty:
                          vm.$route.name == "compare-pdf"
                              ? undefined
                              : COMPARISON_PROPERTY_NAMES[
                                    vm.$route.params.comparisonProperty
                                ],
                      wireIds: wireIds,
                  },
              ],
          }
        : false;
}
