// AnalyticsHelper.js
// using native firebase for iOS/Android in order to track country
// GA4 Measurement protocol still used for web development version
import { FIREBASE, GTAG } from "../constants";
import tbaWrapper from "@/helpers/tbaWrapper";
import vm from "@/main";
import store from "../store";
import { COMPARISON_PROPERTY_NAMES } from "@/constants";
import ucfirst from "locutus/php/strings/ucfirst";
import api from "./api";

const debug_mode = undefined; // true | undefined (better than false because it does not get sent)

// todo offline

// https://support.google.com/analytics/answer/11403294?hl=en#zippy=%2Cgoogle-analytics-for-firebase-mobile-apps
// https://ga-dev-tools.web.app/ga4/event-builder/
// EVENTS --------------------------------------------------------------------
export async function page_view(params = {}) {
    await checkGAEnabled(); // only waits on first run. returns immediately otherwise.
    if (!store.state.isGAEnabled) return;
    let { pageParams, moreEvents } = page_view_page_params_and_more_events();
    firebaseLogEvents([
        page_view_event({ ...params, ...pageParams }),
        ...moreEvents,
    ]);
}
export function compare_add(wireId) {
    firebaseLogEvents([compare_add_event(wireId)]);
}
export function compare_remove(wireId) {
    firebaseLogEvents([compare_remove_event(wireId)]);
}
export function compare_remove_all() {
    let ids = store.state.compareWireIds;
    if (!ids.length) return;
    let events = [];
    for (const id of ids) {
        events = [...events, compare_remove_event(id)];
    }
    firebaseLogEvents(events);
}
// compare_wire events not called directly. can be sent with page_view
function compare_wire_events(wire_ids, more = {}) {
    if (!wire_ids.length) return [];
    const wiresById = store.getters.guideWiresById;
    const events = [];
    for (const id of wire_ids) {
        events.push(compare_wire_event(wiresById[id], more));
    }
    return events;
}
// EVENT FORMATS -------------------------------------------------------------
function page_view_event(more = {}) {
    return {
        name: "page_view",
        params: {
            region: store.state.currentRegion,
            page_location: vm.$route.path,
            page_title: vm.$route.name,
            ...more,
            debug_mode,
        },
    };
}
function page_view_page_params_and_more_events() {
    // page specific parameters
    // compare pages also send multiple compare_wire events
    var moreEvents = [];
    var pageParams = {};
    var sortedIds = [];
    switch (vm.$route.name) {
        case "product":
        case "graph":
            console.log(vm.$route.params.wireId);
            pageParams = wireParams(
                store.getters.guideWiresById[vm.$route.params.wireId]
            );
            break;
        case "products":
            pageParams = {
                maker: vm.$route.params.maker,
                category: ucfirst(vm.$route.params.type),
            };
            break;
        case "compare":
        case "compare-big":
        case "compare-pdf":
            sortedIds = [...store.state.compareWireIds].sort();
            pageParams = {
                comparison_property:
                    vm.$route.name == "compare-pdf" // avoid fallthrough
                        ? undefined
                        : COMPARISON_PROPERTY_NAMES[
                              vm.$route.params.comparisonProperty
                          ],
                compare_wire_ids: sortedIds.length
                    ? JSON.stringify(sortedIds)
                    : undefined,
                wire_count: sortedIds.length, // for reporting to group by
            };
            moreEvents = compare_wire_events(sortedIds, pageParams);
            break;
    }
    return { pageParams, moreEvents };
}
function compare_add_event(wireId) {
    let wire = store.getters.guideWiresById[wireId];
    return {
        name: "compare_add",
        params: {
            region: store.state.currentRegion,
            ...wireParams(wire),
            debug_mode,
        },
    };
}
function compare_remove_event(wireId) {
    let wire = store.getters.guideWiresById[wireId];
    return {
        name: "compare_remove",
        params: {
            region: store.state.currentRegion,
            ...wireParams(wire),
            debug_mode,
        },
    };
}
function compare_wire_event(wire, more = {}) {
    return {
        name: "compare_wire",
        params: {
            region: store.state.currentRegion,
            ...compareWireParams(wire),
            ...more,
            debug_mode,
        },
    };
}

var compareWireParams = ({ id, name, category, maker }) => ({
    // not sure if these can be differentiated by event name in reporting
    // otherwise it could be wire_id, wire_name
    compare_wire_id: String(id),
    compare_wire_name: name,
    category, // might need rename
    maker, // ""
});
var wireParams = ({ id, name, category, maker }) => ({
    wire_id: String(id),
    wire_name: name,
    category,
    maker,
});
// API CALL ------------------------------------------------------------------
function firebaseLogEvents(events) {
    // todo test
    if (tbaWrapper.platform) {
        for (const event of events) tbaWrapper.firebaseLogEvent(event);
    } else {
        ga4(events);
    }
}
export async function ga4(events) {
    console.log("ga4()");
    let body = {
        // user_id: "0", // todo for logged in users
        timestamp_micros: new Date().getTime() + "000",
        non_personalized_ads: false,
        events: events,
    };
    if (tbaWrapper.platform) {
        body.app_instance_id = await pGetFirebaseInstanceId();
    } else {
        body.client_id = await pGetGtagClientId();
    }
    let opts = {
        method: "POST",
        cache: "no-cache",
        // setting headers could cause fail. either Content-Type or Accept was causing fail
        body: JSON.stringify(body),
    };
    fetch(gaUrl, opts);
}
// local stuff ---------------------------------------------------------------
const queryParams = (platform) => (platform ? FIREBASE[platform] : GTAG);
const gaUrl =
    // "https://www.google-analytics.com/debug/mp/collect?" + // validation server
    "https://www.google-analytics.com/mp/collect?" +
    urlSearchParams(queryParams(tbaWrapper.platform));
function pGetFirebaseInstanceId() {
    return new Promise((resolve, reject) => {
        tbaWrapper.getFirebaseInstanceId((id, error) => {
            if (id) resolve(id);
            else reject(error);
        });
    });
}
function urlSearchParams(obj) {
    // because URLSearchParams(obj).toString() encodes ':' into '%3A' causing it to fail
    // which does not happen here: https://ga-dev-tools.web.app/ga4/event-builder/
    let assignments = [];
    for (const [key, value] of Object.entries(obj)) {
        assignments.push(`${key}=${value}`);
    }
    return assignments.join("&");
}
function pGetGtagClientId() {
    return new Promise((resolve) => {
        window.gtag &&
            window.gtag("get", GTAG.measurement_id, "client_id", resolve);
    });
}
// limit check to once a minute
const enableCheckInterval = 60000;
var timeLastCheckEnabled = 0;
async function checkGAEnabled() {
    // on first run, wait for the check
    if (!timeLastCheckEnabled) await _checkGAEnabled();
    // check without waiting if enough time passed since last check
    else if (timeLastCheckEnabled + enableCheckInterval < Date.now())
        _checkGAEnabled();
}
async function _checkGAEnabled() {
    let response = await api.analytics();
    let isEnabled = !!response.enableGoogleAnalytics;
    // isEnabled = false; // test
    store.commit("setIsGAEnabled", isEnabled);
    if (tbaWrapper.platform) {
        tbaWrapper.firebaseSetAnalyticsEnabled(isEnabled);
    }
    timeLastCheckEnabled = Date.now();
}
