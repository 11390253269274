import dataFormat from "@/helpers/dataFormat.js";
import { HOST, OLD_API } from "../constants";
import util from "@/helpers/util.js";
import dirname from "locutus/php/filesystem/dirname";
export default {
    brandTypes(state) {
        // from old api
        console.log("getter: brandTypes"); // check how often this runs
        if (!OLD_API) return {};
        return state.apiData ? dataFormat.brandTypes(state.apiData) : false;
    },
    // old api
    // currentBrandType(state, getters) {
    //     if (!state.apiData || !state.currentBrand || !state.currentWireType)
    //         return false;
    //     // old brandtype index from curent brand / type
    //     let brand_type_id = {
    //         abbott_coronary: 1,
    //         other_coronary: 2,
    //         abbott_peripheral: 3,
    //         other_peripheral: 4,
    //     }[`${state.currentBrand}_${state.currentWireType}`];
    //     console.log("brand_type_id", brand_type_id);
    //     if (!brand_type_id) return false;
    //     return getters.brandTypes[brand_type_id];
    // },
    // todo rename this and all getters to get*()
    guideWiresById(state, getters) {
        // this runs too often. why isn't it caching?
        console.log("getter: guideWiresById");
        return util.index_by_id(
            state.apiData?.guide_wires?.filter((w) =>
                getters.getViewingMatIds.includes(w.mat_id)
            ) ?? []
        );
        // temp translation from old api
        // var a = state.apiData.guide_wires;
        // var by_id = {};
        // for (var i = 0; i < a.length; i++) {
        //     by_id[String(a[i].id)] = dataFormat.old_to_new_wire(a[i]);
        // }
        // return by_id;
    },
    compareCount(state) {
        return state.compareWireIds.length;
    },
    // parameterized not cached -- this did not work.
    // isSelected: (state) => (wireId) => {
    //     return state.compareWireIds.includes(wireId);
    // },
    comparisonWires(state, getters) {
        return state.compareWireIds.map((id) => getters.guideWiresById[id]);
    },
    comparisonWiresWithGraph(state, getters) {
        return getters.comparisonWires.filter(
            (w) => !!w.graph && !!Object.keys(w.graph).length
        );
    },

    comparisonIsFull(state) {
        return state.compareWireIds.length >= 8;
    },
    comparisonIsEmpty(state) {
        return state.compareWireIds.length == 0;
    },
    // could this be better named?
    // getSegments(state) {
    //     if (OLD_API) return [];
    //     return state.apiData.brands.filter((b) => b.type == "Segment");
    // },
    // new version of brandTypes
    // should run only once per sync. on apiData change (regular users)
    // otherwise also when region, currentStatusViewing update
    // Maker/Category should have been MAT!
    makerCategorySegmentWires(state, getters) {
        var ret = {};
        if (OLD_API) return ret;
        for (const wire of Object.values(getters.guideWiresById) ?? []) {
            let maker = ret[wire.maker] ?? (ret[wire.maker] = {});
            let category = maker[wire.category] ?? (maker[wire.category] = {});
            let segmentName = getters.brandsById[wire.brand_id].name;
            let segmentWires =
                category[segmentName] ?? (category[segmentName] = []);
            segmentWires.push(wire);
        }
        return ret;
    },
    brandsById(state) {
        return util.index_by_id(state.apiData?.brands ?? {});
    },
    getTestWireId(state, getters) {
        return Object.keys(getters.guideWiresById ?? {}).at(0);
    },
    getTestWireIds(state, getters) {
        return Object.keys(getters.guideWiresById ?? {}).slice(0, 4);
    },
    getViewingTemplates(state, getters) {
        return (state.apiData?.templates ?? []).filter((t) =>
            getters.getViewingMatIds.includes(t.mat_id)
        );
    },
    getPageTemplate(state, getters) {
        let meta = state.currentRoute.meta;
        let matches = getters.getViewingTemplates.filter(
            // supposed to be by template_type and category but name will do
            // because it's unique and not editable according to Jason
            (t) => t.name == meta.template
        );
        if (matches?.length > 1)
            console.error("Multiple templates matched", matches);
        return matches?.at(0);
    },
    getMatById(state) {
        return util.index_by_id(state.apiData?.mat_numbers ?? {});
    },
    getRegionMatsSorted(state) {
        // for use by getViewingMatsByCategoryMaker()
        // current region
        // sorted by status "Published" then "For Review"
        return (
            state.apiData?.mat_numbers
                ?.filter((m) => m.region == state.currentRegion || !m.region)
                .sort((a, b) => (a.status > b.status ? -1 : 1)) ?? []
        );
    },
    getViewingMatsByCategoryMaker(state, getters) {
        // based on currentRegion, currentStatusViewing
        //      "Published": only published
        //      "For Review": "Published" will show when no "For Review" exists
        // `${category}${maker}` uniquely identifies type of mat
        var byCM = {};
        for (const mat of getters.getRegionMatsSorted.filter(
            state.currentStatusViewing == "For Review"
                ? () => true // no filter
                : (m) => m.status == state.currentStatusViewing
        )) {
            byCM[`${mat.category ?? ""}${mat.maker ?? ""}`] = mat;
        }
        return byCM;
    },
    getViewingMatIds(state, getters) {
        return Object.values(getters.getViewingMatsByCategoryMaker).map(
            (m) => m.id
        );
    },
    getCurrentMatObject(state, getters) {
        if (state.currentRoute.meta.hasNoTemplate) {
            let categoryMaker = `${state.currentCategory}${state.currentMaker}`;
            let mat = getters.getViewingMatsByCategoryMaker[categoryMaker];
            if (!mat) console.error("no Mat found for '${categoryMaker}'");
            return mat;
        }
        // todo test no mat numbers
        return getters.getMatById[getters.getPageTemplate?.mat_id];
    },
    // the string
    getCurrentMatNum(state, getters) {
        let mat = getters.getCurrentMatObject;
        if (!mat) return "";
        return `${mat.code} v${mat.version.toFixed(1)}`;
    },
    getCurrentMatStatus(state, getters) {
        return getters.getCurrentMatObject?.status ?? "";
    },
    getFooter(state, getters) {
        return getters.getCurrentMatObject?.footer ?? "";
    },
    hasWires(state) {
        return (state.apiData?.guide_wires?.length ?? 0) > 0;
    },
    hasViewingWires(state, getters) {
        return Object.keys(getters.guideWiresById ?? {}).length != 0;
    },
    getResourcesById(state) {
        return util.index_by_id(state.apiData?.resources ?? {});
    },
    getCurrentRegionMATs(state) {
        return (
            state.apiData?.mat_numbers?.filter(
                (m) => !m.region || m.region == state.currentRegion
            ) ?? []
        );
    },
    getViewingResources(state, getters) {
        // if it's "For Review" and there is no "For Review" Resource mat
        // then we show the "Published"
        // if mat_id was included we could filter by that which is what should happen
        return state.apiData.resources.filter(
            (r) =>
                r.status ==
                    getters.getViewingMatsByCategoryMaker["Resource"].status &&
                r.region == state.currentRegion
        );
    },
    getDownloadBaseUrl(state) {
        return OLD_API
            ? `${HOST}/assets`
            : state.apiData?.guide_wires
            ? dirname(
                  dirname(state.apiData.guide_wires[0]?.image_filename ?? "")
              )
            : null;
    },
    getFileBasePath(state, getters) {
        return state.isWeb
            ? getters.getDownloadBaseUrl ?? ""
            : state.localFileBasePath;
    },
};
