<template>
    <div id="home-view" class="landing scroll-y" v-dragscroll="$isWeb">
        <ScrollForMore forId="home-view" />
        <div class="landing-main">
            <!-- <img class="landing-wires" src="@/assets/images/Landing-Wires.png" /> -->
            <div class="welcome" v-html="subheading" @click.prevent="$root.linkNative($event)"></div>
            <div class="leading-the-way" v-html="title" @click.prevent="$root.linkNative($event)"></div>
            <div class="text" @click.prevent="$root.linkNative($event)" v-html="intro"></div>
            <button
                v-if="hasVW"
                class="products"
                @click="$router.push('/product-types')"
            >
                <img
                    src="@/assets/images/Icon_Products_White.svg"
                    class="products"
                />
                &nbsp;
                <span>VIEW GUIDEWIRES</span>
            </button>
            <h2 v-else><br />No Guidewires to view</h2>
            <div class="footnote" v-html="getFooter" @click.prevent="$root.linkNative($event)"></div>
        </div>
    </div>
</template>

<script>
import ScrollForMore from "@/components/ScrollForMore.vue";
import { mapGetters, mapMutations } from "vuex";
export default {
    name: "HomeView",
    components: { ScrollForMore },
    methods: {
        ...mapMutations(["setCurrentSection"]),
        // linkNative: (e) => window.tbaWrapper.linkNative(e),
    },
    computed: {
        ...mapGetters(["getPageTemplate", "guideWiresById", "getFooter"]),
        templateContent() {
            return this.getPageTemplate?.content;
        },
        subheading() {
            return (
                this.templateContent?.subheading ??
                "Welcome to the Guide Wire Data Center"
            );
        },
        title() {
            return (
                this.templateContent?.title ??
                "Leading the way in Guide Wire Technology"
            );
        },
        intro() {
            return (
                this.templateContent?.intro ??
                "Abbott offers a robust portfolio of coronary and peripheral guide wires for a wide range of clinical needs. Abbott’s guide wires are offered in a variety of diameters, lengths, support levels, tip loads, tip tapers, polymer covers, and coatings."
            );
        },
        hasVW() {
            // kludge for getter
            return Object.keys(this.guideWiresById ?? {}).length != 0;
        },
    },
    mounted() {
        this.setCurrentSection("");
        console.log("HomeView---",this);
    },
};
</script>
