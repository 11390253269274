<template>
    <!-- v-if required here now to refresh rasterized gradient -->
    <div class="modal" v-if="pageNum">
        <div id="compare-pdf-page">
            <!-- // hack for pdf fonts. -->
            <component :is="'style'">
                #compare-pdf-page { font-family: BrandonGrotesque; }
            </component>

            <header v-if="pageNum == 1" class="page1">
                <img class="logo" :src="Logo_Abbott2_dataUri" />
                <div class="right">
                    <div class="heading" v-html="title"></div>
                    <hr />
                    <div class="category">
                        {{
                            comparisonCategories
                                .map((c) => c.toUpperCase())
                                .join(" | ")
                        }}
                    </div>
                </div>
            </header>
            <header v-if="pageNum == 2" class="page2">
                <div class="heading" v-html="title"></div>
                <div class="comparison-property-heading">
                    {{ comparisonPropertyValue?.toUpperCase() }}
                </div>
            </header>
            <hr class="gradient convert-to-jpg" :class="gradientClasses" />

            <ComparePDFPage1 v-if="pageNum == 1" />
            <!-- AVGW-93 graph needs a v-if to get re-mounted to be converted again -->
            <ComparePDFPage2
                v-if="pageNum == 2 && comparisonProperty == 'supportLevel'"
                comparisonProperty="supportLevel"
            />
            <ComparePDFPage2
                v-show="
                    pageNum == 2 && comparisonProperty == 'penetrationPower'
                "
                comparisonProperty="penetrationPower"
            />
            <ComparePDFPage2
                v-show="pageNum == 2 && comparisonProperty == 'tipLoad'"
                comparisonProperty="tipLoad"
            />

            <ComparePDFPage99FontTest v-if="pageNum == 99" />
            <footer>
                <div class="copy-mat">
                    <!-- &copy; {{ year }} Abbott. All rights reserved.<br /> -->
                    <div
                        v-if="pageNum == 1 || !getIsLastPdfComparisonProperty"
                        v-html="content?.footer ?? ''"
                    ></div>
                    {{ template.mat_number }}
                </div>
                <img
                    v-if="pageNum == 2"
                    class="logo"
                    :src="Logo_Abbott2_dataUri"
                />
            </footer>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ComparePDFPage1 from "./ComparePDFPage1.vue";
import ComparePDFPage2 from "./ComparePDFPage2.vue";
import ComparePDFPage99FontTest from "./ComparePDFPage99FontTest.vue";
import { COMPARISON_PROPERTY_NAMES } from "@/constants";
import Logo_Abbott2_dataUri from "@/assets/images/Logo_Abbott2_dataUri";
export default {
    components: {
        ComparePDFPage1,
        ComparePDFPage2,
        ComparePDFPage99FontTest,
    },
    data() {
        return {
            Logo_Abbott2_dataUri,
            defaultTitle: "GUIDE WIRE DATA CENTER PRODUCT DATA SHEET",
        };
    },
    computed: {
        ...mapState({
            pageNum: (state) => state.pdf.comparisonPageNum,
            comparisonProperty: "comparisonProperty",
        }),
        ...mapGetters([
            "getComparisonPdfMatNum",
            "comparisonWires",
            "getPageTemplate",
            "getComparisonPdfTemplate",
            "getIsLastPdfComparisonProperty",
        ]),
        comparisonCategories() {
            return this.comparisonWires
                .map((w) => w.category)
                .filter(this.uniqueFilter)
                .sort((a, b) => (a > b ? 1 : -1));
        },
        comparisonPropertyValue() {
            return COMPARISON_PROPERTY_NAMES[this.comparisonProperty];
        },
        gradientClasses() {
            return [
                // coronary, peripheral
                ...this.comparisonCategories.map((c) => c.toLowerCase()),
                ...(this.pageNum == 2 ? ["page2"] : []),
            ];
        },
        year() {
            return new Date().getFullYear();
        },
        template() {
            return this.getComparisonPdfTemplate;
        },
        content() {
            return this.template?.content;
        },
        title() {
            return this.content?.title ? this.content.title : this.defaultTitle;
        },
    },
    methods: {
        uniqueFilter(value, index, self) {
            return self.indexOf(value) === index;
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
#compare-pdf-page {
    background-color: white;
    height: vw(1325px);
    position: relative;
    header.page1 {
        padding-left: vwp(45px);
        padding-right: vwp(45px);
        padding-top: vwp(66px);
        font-size: vwp(17px);
        line-height: vwp(20.4px);
        letter-spacing: vwp(1px);
        color: #63666a;
        text-align: right;
        display: flex;
        justify-content: space-between;
        img.logo {
            width: vwp(85px);
            height: vwp(96.888px);
            margin-top: vwp(2.204px);
        }
        .heading {
            text-transform: uppercase;
            width: vwp(250px);
        }
        hr {
            border: none;
            border-bottom: vwp(1px) solid #e1e1e1;
            margin: vwp(16.5px) 0;
            margin-left: auto;
            width: vwp(102.7px);
        }
        .br {
            font-weight: normal;
        }
        .bm {
            font-weight: medium;
        }
        .bb {
            font-weight: bold;
        }
    }
    header.page2 {
        margin: 0 vwp(45px);
        padding-top: vwp(65px);
        .heading {
            text-transform: uppercase;
            font-size: vwp(15px);
            letter-spacing: vwp(1px);
            line-height: vwp(18px);
            color: #63666a;
            padding-bottom: vwp(2px);
        }
        .comparison-property-heading {
            font-size: vwp(20px);
            letter-spacing: vwp(1px);
            line-height: vwp(24px);
            font-weight: bold;
            color: #221f1f;
        }
    }
    hr.gradient {
        width: vwp(723px);
        height: vwp(10px);
        margin: vwp(43px) 0;
        &.page2 {
            margin: vwp(17px) 0;
        }
    }

    footer {
        position: absolute;
        left: vwp(45px);
        right: vwp(45px);
        bottom: vwp(37px);
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .logo {
            width: vwp(65px);
            height: vwp(74px);
            position: relative;
            bottom: vwp(12px);
        }
        .copy-mat {
            // Mercury doesn't work in pdf
            // font-family: MercuryTextG1;
            // font-family: Times-Roman;
            font-family: Georgia;
            font-weight: normal;
            font-size: vwp(11px);
            line-height: vwp(13.2px);
            text-align: left;
            letter-spacing: normal;
        }
    }
    .img {
        // remove extra space underneath after conversion from svg
        display: block;
    }
}
</style>
