// misc.js
// needs further sorting
// some are too small to justify a module of their own
export default {

    /* sleep start */
    /**
     * prevent device from going into idle sleep mode. iOS only.
     * Android wrapper currently has it turned off.
     * added android functions but they tested unreliable so left sleep off as it already was.
     * see java code for more details -stan
     * @method disableSleep
     */
    disableSleep(callBack) {
        if (callBack) {
            this.genericCallbackHolder = callBack;
        } else {
            this.genericCallbackHolder = null;
        }
        if (this.isiOS) {
            window.webkit.messageHandlers.disablesleep.postMessage(
                "sleep toggle"
            );
        }
        // } else if (this.isAndroid) {
        //     tbaWrapperJ.disableSleep();
        // }
    },

    /**
     * allow device to go into idle sleep mode. iOS only.
     * @method enableSleep
     */
    enableSleep(callBack) {
        if (callBack) {
            this.genericCallbackHolder = callBack;
        } else {
            this.genericCallbackHolder = null;
        }
        if (this.isiOS) {
            window.webkit.messageHandlers.enablesleep.postMessage(
                "sleep toggle"
            );
        }
        // } else if (this.isAndroid) {
        //     tbaWrapperJ.enableSleep();
        // }
    },
    /* sleep end */


    /* createpng start */
    /**
     * Turns the current on screen visuals into PNG image
     * @method createPNG
     * @param {String} path - (required), The path to the file that is to be created that resides within the documents directory where '/' is the root of the documents directory
     * @param {Function} callBack - (required), The function to call when the PNG has finished creating.  Shouldn't have any arguments
     */
    createPNG(path, callBack) {
        if (callBack) {
            this.createPNGCallBack = callBack;
        }
        if (this.isiOS) {
            window.webkit.messageHandlers.createpng.postMessage(path);
            // window.location = "createpng://foo/bar?sid="+Math.random()+"&path="+this.Base64.encode(path);
        }
    },
    didCreatePNG() {
        this.createPNGCallBack();
        this.createPNGCallBack = null;
    },
    /* createpng end */

    /* openpdf start */
    /**
     * Open a PDF in a native viewer
     * @method openPDF
     * @param {String} path - (required), The path to the PDF that resides within the documents directory where '/' is the root of the documents directory
     * @param {Boolean} isMainBundle - (required), Whether this path is for the main bundle or not.  If not the path is for the documents directory.  The main bundle is the list of files that are dragged into the left hand file browser in Xcode and deployed at install time.  Main bundle files are read only, whereas documents directory files can be written, read, and deleted.
     */
    openPDF(path, isMainBundle) {
        if (this.isiOS) {
            window.webkit.messageHandlers.openpdf.postMessage({
                path: path,
                ismainbundle: isMainBundle ? "true" : "false",
            });
        } else if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.viewpdf(this.Base64.encode(path));
        }
    },
    /* openpdf end */

    /* browser start */
    /**
     * Function to launch a web page in the user's default browser rather than inside the wrapper window
     * @method launchNativeDefaultBrowser
     * @param {String} url - (required), the url of the page to load in a native browser
     */
    launchNativeDefaultBrowser(url) {
        if (this.isDesktop) {
            // eslint-disable-next-line no-undef
            nw.Shell.openExternal(url);
        } else if (this.isiOS) {
            window.webkit.messageHandlers.launchbrowser.postMessage(url);
        } else if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.launchNativeDefaultBrowser(url);
        } else {
            // assume web
            window.open(url, "_blank");
        }
    },
    /**
     * capture link clicks and launch native browser
     * @param {*} e - click event
     */
    linkNative(e) {
        e.preventDefault();
        if (e.target.href) this.launchNativeDefaultBrowser(e.target.href);
    },
    /* browser end */

    /* sso start */
    // doSSOAuth method was removed from iOS at least for reason:
    // "Function Call removed due to dependancy on Google GTMOAuth library which relies on deprecated NSURLConnection library refer to SVN rev 162 for details on previous implementation"
    /**
     * Function to authenticate against an oAuth or SAML sso end point
     * @param uid - String, the user id to authenticate with
     * @param pass - String, the password to authenticate with
     * @param idp - String, the URL of the authentication end point to authenticate against
     */
    doSSOAuth(uid, pass, idp) {
        if (this.isDesktop) {
            //tbaWrapperJ.writePNGSToPDF(this.Base64.encode(src),this.Base64.encode(dest));
        } else {
            window.webkit.messageHandlers.ssosignin.postMessage({
                uid: uid,
                pass: pass,
                idp: idp,
            });
            // window.location = "ssosignin://foo/bar?sid="+Math.random()+"&pass="+this.Base64.encode(pass)+"&uid="+this.Base64.encode(uid)+"&idp="+this.Base64.encode(idp);
        }
    },
    /* sso end */

    /* bgnotification start */
    setBackgroundNotification(callback) {
        this.bgNotifyCallBack = callback;
        if (this.isiOS) {
            console.log("Not supported in iOS yet");
        }
    },

    backgroundNotificationCallBack() {
        if (this.bgNotifyCallBack != null) {
            this.bgNotifyCallBack();
        }
    },
    /* bgnotification end */


    /* galderma start */
    /**
     * Function to open the Galderma Market from within the iDash framework.  Most Galderma internal apps need to have an iDash button to link the user back to their main directory; firing this method will open this.  Only supported on iOS
     * Also, for the galderma market to be able to find this app in the other direction - you should add the folowing code to the <dict> node in the info.plist file - where UNIQUEAPPID = your own unique identifier that you can make up:<br />
     *  &lt;key&gt;CFBundleURLTypes&lt;/key&gt;<br />
     *  &lt;array&gt;<br />
     *      &nbsp;&nbsp;&lt;dict&gt;<br />
     *          &nbsp;&nbsp;&nbsp;&nbsp;&lt;key&gt;CFBundleURLName&lt;/key&gt;<br />
     *          &nbsp;&nbsp;&nbsp;&nbsp;&lt;string&gt;&lt;/string&gt;<br />
     *          &nbsp;&nbsp;&nbsp;&nbsp;&lt;key&gt;CFBundleURLSchemes&lt;/key&gt;<br />
     *          &nbsp;&nbsp;&nbsp;&nbsp;&lt;array&gt;<br />
     *              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;string&gt;UINIQUEAPPID&lt;/string&gt;<br />
     *          &nbsp;&nbsp;&nbsp;&nbsp;&lt;/array&gt;<br />
     *      &nbsp;&nbsp;&lt;/dict&gt;<br />
     *  &lt;/array&gt;
     * @method openGaldermaMarket
     */
    openGaldermaMarket() {
        if (this.isiOS) {
            window.webkit.messageHandlers.galdmkt.postMessage(
                "opening galderma"
            );
            // window.location = "galdmkt://foo/bar?sid="+Math.random();
        }
    },
    /* galderma end */

    /* firebase start */
    /*
     *  Function to log Firebase Instance ID, needed for sending messages to test devices
     */
    getInstanceID(callback) {
        this.fbCallBack = callback;
        if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.getFirebaseInstanceID();
        } else if (this.isiOS) {
            window.webkit.messageHandlers.getInstanceID.postMessage("");
        }
    },

    gotInstanceID(logID) {
        this.fbCallBack(logID);
        this.fbCallBack = null;
    },
    /* firebase end */

    /* analytics start */
    /**
     * Function to send analytics event. Each key in data should be prefixed
     *  by it's type ie 'string_name: myname', 'int_id: 2', 'double_latitude: 2.323'
     */
    sendAnalytics(eventName, data) {
        if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.sendAnalytics(
                eventName,
                this.Base64.encode(this.array2json(data))
            );
        } else if (this.isiOS) {
            window.webkit.messageHandlers.sendAnalytics.postMessage(
                JSON.stringify({ eventName: eventName, data: data })
            );
        }
    },
    /* analytics end */

    /* push_notifications start */
    /**
     * Function to customize the push notifications regsitration workflow; this allows you to control when the push notifications request is sent (e.g. not immediately upon app install).  This function still uses all of the other push config options in the config.json file, but allows you to override the postDataString value with a dynamic value passed as the query argument.  The onus is on the developer to ensure that they only call this function once per app install; or at sensible times (e.g. if the app has a settings panel for controlling push notifications outside of the iOS push notification settings view).
     * @method registerForCustomPushNotifications
     * @param query {String} - (required), a URL encoded post query that is to be appended to the URL registration HTTP request as a post query string
     */
    registerForCustomPushNotifications(query) {
        if (this.isiOS) {
            window.webkit.messageHandlers.registerForCustomPushNotifications.postMessage(
                query
            );
            // window.location = "custom://foo/bar?sid="+Math.random()+"&query="+this.Base64.encode(query+"");
        }
    },

    /**
     * Function to receive the device ID from iOS and pass it to your app by calling your callback which is defined by this.setDeviceIDCallback();
     * if the user accepts the request to allow push notifications.
     * the user may or may not allow push notifications.
     * @method didReceiveDeviceID
     * @param did {String} - (required), this is the device ID returned for this app from iOS when the user accepts a request for push notifications
     */
    didReceiveDeviceID(did) {
        console.log("didReceiveDeviceID: " + did);
        //todo: insert your own code here to handle device ID
        if (this.deviceIDCallback) this.deviceIDCallback(did);
    },

    /**
     * set your function to receive deviceID for push notifications
     * @method setDeviceIDCalllback
     * @param deviceIDCallback function - (required), your function which receives the deviceID. your function will be called with one argument: the deviceID
     */
    setDeviceIDCallback(deviceIDCallback) {
        this.deviceIDCallback = deviceIDCallback;
    },
    /* push_notifications end */

    /* email start */

    /**
     * @typedef EmailAttachment
     * @type {object}
     * @property {string} path - location of file e.g. "directory/filename.ext"
     * @property {string} mime - e.g. "application/pdf"
     * @property {number} name - desired file display name e.g. "foo.pdf"
     */

    /**
     * Compose an email with the default email client populating subject, message, etc...
     * iOS 14+ users can now set their preferred default email client
     * iOS has extra options which require the use of iOS Mail
     *      if either attachments or isHtml are specified on iOS, iOS Mail will be used.
     *      then if iOS Mail app is not setup, the user is notified natively.
     * @method openEmailClient
     * @param subject {String} - email subject
     * @param message {String} - email body - use the standard email line separator (CRLF 0x0D0A "\r\n") Gmail app on iOS requires it.
     * @param attachments {EmailAttachment[]} - see above typedef (iOS ONLY)
     * @param isMainBundle {Boolean} - when true, attachment paths are of the main bundle hard coded into the app, not the dynamic docs directory. (iOS ONLY)
     * @param to {String[]} - recipient emails
     * @param cc {String[]} - Carbon Copy recipient emails
     * @param bcc {String[]} - Blind CC recipient emails
     * @param isHTML {Boolean} - (iOS ONLY), true specifies message body is HTML
     */
    openEmailClient(
        subject = "",
        message = "",
        attachments = [], // iOS only
        isMainBundle = false, // iOS only
        to = [],
        cc = [],
        bcc = [],
        isHTML = false // iOS only
    ) {
        let link = mailtoLink();
        console.log(link);
        console.log("require Mail", requiresIosMail ? "yes" : "no");
        if (this.isiOS) {
            if (requiresIosMail())
                window.webkit.messageHandlers.openemail.postMessage({
                    subject: subject,
                    message: message,
                    attachments: JSON.stringify(attachments),
                    ismainbundle: isMainBundle,
                    to: JSON.stringify(to),
                    cc: JSON.stringify(cc),
                    bcc: JSON.stringify(bcc),
                    ishtml: isHTML,
                });
            else {
                console.log("opening link");
                window.location.href = link;
            }
        } else {
            window.open(link, "_blank");
        }
        function requiresIosMail() {
            return attachments.length || isHTML;
            // no need to check isMainBundle
        }
        function mailtoLink() {
            let args = {
                cc: cc.join(","),
                bcc: bcc.join(","),
                subject: subject,
                body: message,
            };
            // remove blanks
            Object.keys(args).forEach((k) => !args[k] && delete args[k]);
            return (
                `mailto:${to.join(",")}?` +
                Object.entries(args)
                    .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
                    // .map(([k, v]) => `${k}=${v}`)
                    .join("&")
            );
        }
    },
    /* email end */

    /* network start */
    isNetworkConnected(callback) {
        if (!callback) {
            console.error("no callback passed to isNetworkConnected");
            return;
        }
        this.isNetworkConnectedCallback = callback;
        if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.isNetworkConnected();
        }
        if (this.isiOS) {
            window.webkit.messageHandlers.isNetworkConnected.postMessage("");
        }
    },
    /* network end */


    /* usernotification start */
    onNotificationOpen(notificationTrigger) {
        console.log("Notification open", notificationTrigger);
    },

    getNotificationTrigger(callback) {
        if (window.notificationTrigger) {
            this.notificationTrigger = window.notificationTrigger;
            window.notificationTrigger = null;
        }
        if (typeof callback == "function") {
            callback(this.notificationTrigger);
            this.onNotificationOpen = callback;
        }
        return this.notificationTrigger;
    },

    resetNotificationTrigger() {
        this.notificationTrigger = null;
        window.notificationTrigger = null;
    },

    /* iOS only - METHODS BELOW ARE IOS ONLY ============================================ */
    requestNotificationPermission(callback) {
        if (this.isiOS) {
            this.requestNotificationPermissionCallback = callback;
            window.webkit.messageHandlers.requestNotificationPermission.postMessage(
                ""
            );
        } else {
            callback(1);
        }
    },

    createNotification(title, message, delaySeconds, userInfo) {
        if (this.isiOS) {
            window.webkit.messageHandlers.createNotification.postMessage({
                title: title,
                message: message,
                delay: delaySeconds ? delaySeconds : 1,
                userInfo: userInfo ? userInfo : {},
            });
        }
    },
    /* usernotification end */

    /* calendar start */
    addToCalendar(date, minutes, title) {
        if (this.isiOS) {
            window.webkit.messageHandlers.addToCalendar.postMessage({
                title: title,
                minutes: minutes,
                date: date,
            });
        }
    },
    /* calendar end */

    /* confirmdialog start */
    confirmDialog(message, callback) {
        if (this.isiOS) {
            this.confirmDialogCallback = callback;
            window.webkit.messageHandlers.confirmDialog.postMessage(message);
        } else {
            callback(confirm(message));
        }
    },
    /* confirmdialog end */
};
