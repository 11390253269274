<template>
    <div v-if="guideWire" class="wire-tile" :class="similar ? 'similar' : ''">
        <img
            class="wire"
            :src="imageSrc"
            alt=""
            onerror="this.onerror=null; this.src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII='"
        />

        <div class="overlay" @click="$router.push(`/product/${guideWire.id}`)">
            <div class="transparent"></div>
            <div
                v-if="!similar"
                :class="['gradient', guideWire?.category?.toLowerCase() ?? '']"
            />
            <div class="bar-white">
                <div>
                    <div class="brand-type">{{ guideWire.brand_type }}</div>
                    <div class="wire-name" v-html="guideWire.name"></div>
                </div>
                <img
                    v-if="similar"
                    class="arrow"
                    src="@/assets/images/Icon_Arrow_Blue.svg"
                />
                <img
                    v-else
                    class="arrow"
                    src="@/assets/images/Icon_Arrow.svg"
                />
            </div>
        </div>
        <div
            class="btn-add"
            :class="{ selected: isSelected }"
            @click.stop="toggleCompare"
        ></div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import * as file from "@/helpers/FileHelper";
export default {
    // name: "WireTile",
    props: ["guideWire", "similar"],
    computed: {
        ...mapState(["compareWireIds"]),
        imageSrc() {
            return file.wireImgSrc(this.guideWire);
        },
        isSelected() {
            return this.compareWireIds.includes(this.guideWire.id);
        },
    },

    methods: {
        toggleCompare() {
            let action = this.isSelected
                ? "compareWireIdsRemove"
                : "compareWireIdsPush";
            this.$store.dispatch(action, this.guideWire.id);
        },
    },
};
</script>
