<template>
    <div class="compare-big-view">
        <main id="compare-big-main" class="scroll-y" v-dragscroll="$isWeb">
            <ScrollForMore :forId="'compare-big-main'" />
            <header>
                <h1>
                    <img
                        class="back-link"
                        src="@/assets/images/Icon_Arrow_Back.svg"
                        @click="$router.push(`/compare/${comparisonProperty}`)"
                    />
                    <span class="heading">COMPARE GUIDE WIRES</span>
                    <span class="numbers-blue">{{ compareCount }} / 8</span>
                </h1>

                <div class="buttons">
                    <button
                        class="pdf"
                        @click="$router.push('/compare-pdf')"
                    ></button>
                </div>
            </header>
            <div class="relative">
                <button
                    class="zoom-out"
                    @click="$router.push(`/compare/${comparisonProperty}`)"
                ></button>
                <!-- ====================================== -->
                <div
                    v-if="comparisonProperty == 'supportLevel'"
                    class="graph-big-container"
                >
                    <WireGraph
                        :wires="comparisonWiresWithGraph"
                        width="770"
                        height="529"
                        :isComparison="true"
                    />
                    <WireGraphLegend />
                </div>
                <div v-else class="bar-graph-area">
                    <BarGraphLabels :wires="comparisonWires" />
                    <BarGraph
                        :wires="comparisonWires"
                        :property="comparisonProperty"
                    />
                </div>
                <!-- ====================================== -->
                <hr />
                <div
                    class="disclaimer"
                    v-html="bottomContent"
                    @click.prevent="$root.linkNative($event)"
                ></div>
                <!-- <div class="disclaimer">
                    ™ indicates a trademark of the Abbott Group of Companies.<br />
                    ‡ Indicates a third-party trademark, which is property of
                    its respective owner.<br />
                    <br />
                    <b><u>www.cardiovascular.abbott</u></b
                    ><br />
                    © 2022 Abbott. All rights reserved.
                </div> -->
            </div>
        </main>
    </div>
</template>

<script>
import WireGraph from "@/components/WireGraph.vue";
import BarGraph from "@/components/BarGraph.vue";
import WireGraphLegend from "@/components/WireGraphLegend.vue";
import BarGraphLabels from "@/components/BarGraphLabels.vue";
import { mapState, mapGetters, mapMutations } from "vuex";
import ScrollForMore from "@/components/ScrollForMore.vue";
import { COMPARISON_PROPERTY_NAMES } from "@/constants";
export default {
    props: ["comparisonProperty"],
    components: {
        WireGraph,
        BarGraph,
        WireGraphLegend,
        BarGraphLabels,
        ScrollForMore,
    },
    data() {
        return {
            comparisonProperties: COMPARISON_PROPERTY_NAMES,
        };
    },
    methods: {
        ...mapMutations(["setCurrentSection", "setComparisonProperty"]),
    },
    computed: {
        ...mapState(["comparisonById", "compareWireIds"]),
        ...mapGetters([
            "comparisonWiresWithGraph",
            "comparisonWires",
            "compareCount",
            "getPageTemplate",
            "getFooter",
        ]),
        template() {
            return this.getPageTemplate?.content;
        },
        bottomContent() {
            let d = (this.template?.disclaimer ?? "").trim();
            let f = this.getFooter.trim();
            return [...(d ? [d] : []), ...(f ? [f] : [])].join("<br /><br />");
        },
    },
    mounted() {
        this.setCurrentSection("compare");
        this.setComparisonProperty(this.comparisonProperty);
    },
    watch: {
        comparisonProperty() {
            this.setComparisonProperty(this.comparisonProperty);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/base/variables";
.compare-big-view {
    .graph-big-container {
        padding-top: vw(32px);
        padding-left: vw(20px);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .bar-graph-area {
        padding-top: vw(32px);
        padding-left: vw(50px);
        padding-bottom: vw(50px);
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        @include gap-compat-1(vw(20px));
        :deep(.bar-graph-container) {
            width: vw(680px);
        }
    }
    hr {
        margin: vw(10px) 0;
        border: none;
        height: vw(1px);
        background-color: $table-lines;
    }
    .disclaimer {
        font: normal normal normal vw(14px) Calibri;
        line-height: vw(18px);
        color: $abbott-dark-grey;
        background-color: #fafafa;
        border-radius: vw(5px);
        padding: vw(20px);
        padding-bottom: vw(18px);
        margin: vw(20px) vw(50px);
    }
}
</style>
