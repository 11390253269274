import tbaWrapper from "@/helpers/tbaWrapper";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
    methods: {
        ...mapActions(["modalReviewClose"]),
        ...mapMutations(["menuClose"]),
        linkNative: (e) => tbaWrapper.linkNative(e),
        onBackPressed() {
            var preventReason, closedModal;
            if ((preventReason = this.backNavPreventReason())) {
                console.log(`onBackPressed(): SKIP because ${preventReason}`);
                return;
            }
            if ((closedModal = this.backNavClosedWhichModal())) {
                console.log(`onBackPressed(): closed ${closedModal}`);
                return;
            }
            console.log("onBackPressed(): from", this.$route.fullPath);
            this.$router.back();
        },
        backNavPreventReason() {
            if (this.$route.name == "compare-pdf") {
                // skip for now but ideally close the pdf and navigate back
                return `route.name ${this.$route.name}`;
            }
            if (this.isReviewOpen) {
                // skip for now
                return "Review Options";
            }
            if (this.isGeneratingPdf) {
                return "Generating PDF";
            }
            if (this.isSyncing) {
                return "Syncing";
            }
            return false;
        },
        backNavClosedWhichModal() {
            if (this.isReviewOpen) {
                // can't get here right now.
                // Skipping this case because could be viewing a pdf
                // ideally close the pdf being viewed for that case.
                // and for compare-pdf too
                this.modalReviewClose();
                return "Review Options";
            }
            if (this.isMenuOpen) {
                this.menuClose();
                return "Menu";
            }
        },
    },
    computed: {
        ...mapState({
            isGeneratingPdf: (state) => state.pdf.isGeneratingPdf,
            isMenuOpen: "isMenuOpen",
            isReviewOpen: "isReviewOpen",
            isSyncing: "isSyncing",
        }),
    },
    mounted() {
        tbaWrapper.onBackPressed = this.onBackPressed;
    },
};
