<template>
    <button id="scroll-for-more" @click="scrollToBottom">
        SCROLL FOR ADDITIONAL INFO
        <img src="@/assets/images/Icon_ArrowDown_Blue.svg" />
    </button>
</template>

<script>
export default {
    props: ["forId"],
    data() {
        return { scroller: false };
    },
    methods: {
        onScroll: function () {
            let el = this.scroller;
            // if scrollable and not scrolled
            if (el.scrollHeight > el.offsetHeight && el.scrollTop == 0) {
                el.classList.add("scroll-for-more");
            } else {
                el.classList.remove("scroll-for-more");
            }
        },
        scrollToBottom() {
            let el = this.scroller,
                swing = (p) => 0.5 - Math.cos(p * Math.PI) / 2,
                finalTop = el.scrollHeight - el.offsetHeight,
                step = 1,
                steps = 10,
                scrollStep = () => {
                    el.scrollTo(0, swing(step / steps) * finalTop);
                    if (step < steps) step++;
                    else clearInterval(scrollJob);
                },
                scrollJob = setInterval(scrollStep, 17);
        },
        onParentMount() {
            // wait for parent mount
            if (!this.$parent.$.isMounted) {
                setTimeout(this.onParentMount, 100);
                return;
            }
            let sc = (this.scroller = document.getElementById(this.forId));
            sc.addEventListener("scroll", this.onScroll);
            window.addEventListener("resize", this.onScroll);
            this.onScroll();
        },
    },
    mounted() {
        this.onParentMount();
    },
    beforeUnmount() {
        this.scroller.removeEventListener("scroll", this.onScroll);
        window.removeEventListener("resize", this.onScroll);
    },
};
</script>

<style lang="scss">
// not scoped because it uses parent
@import "@/assets/scss/base/variables";
button#scroll-for-more {
    background-color: #f3f3f3;
    border-radius: vw(5px);
    width: vw(209px);
    height: vw(35px);
    padding-left: vw(15px);
    padding-right: vw(10px);
    color: $abbott-dark-grey;
    font: normal normal bold vw(11px) BrandonGrotesque;
    line-height: vw(18px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2; // to be able to place as first child. to conveniently match the id and forId attributes.
    // position can be overridden externally if need be
    position: fixed;
    right: vw(50px);
    bottom: vw(70px);
    visibility: hidden;
}
.scroll-for-more button#scroll-for-more {
    visibility: visible;
}
</style>
