// api.js
import { HOST } from "../constants";

export default {
    // NEW
    lobby: async function () {
        return this._fetchJson("/api/lobby");
    },
    content: async function (region) {
        let path = `/api/content/${region}`;
        let opts = {};
        return this._fetchJson(path, opts);
    },
    analytics: async function () {
        let path = `/api/analytics`;
        let opts = {};
        return this._fetchJson(path, opts);
    },
    // todo provide auth token
    review: async function (region) {
        let path = region ? `/api/review/${region}` : "/api/review";
        let opts = {};
        return this._fetchJson(path, opts);
    },
    // OLD
    get_all: async function (regionId, lang = 1) {
        let path = `/api/get_all/${regionId}/${lang}`;
        let opts = {};
        return this._fetchJson(path, opts);
    },
    _fetchJson: async function (path, opts = {}) {
        let url = HOST + path;
        return fetch(url, opts)
            .then(handleErrors)
            .then((response) => {
                console.log("got response", response);
                return response.json();
            })
            .then((data) => {
                console.log("got data", data);
                return data;
            })
            // to be caught by caller. default shows on console error
            // .catch((error) => {
            // });
        function handleErrors(response) {
            // more info here than is available to the caller.
            if (!response.ok) {
                console.error("error response: ", response);
                throw Error(response);
            }
            return response;
        }
    },
};
