<template>
    <div class="product-segment">
        <h3 class="segment-name" v-html="segment"></h3>
        <div class="tiles">
            <WireTile
                v-for="wire in segmentWiresSorted"
                :key="wire.id"
                :guideWire="wire"
            />
        </div>
    </div>
</template>

<script>
import WireTile from "./WireTile.vue";
import util from "@/helpers/util";

export default {
    name: "ProductSegment",
    components: {
        WireTile,
    },
    props: {
        segment: String,
        segmentWires: Array,
    },
    computed: {
        segmentWiresSorted() {
            return [...this.segmentWires].sort(util.by_column("name"));
        },
    },
};
</script>

<style></style>
