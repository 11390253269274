import api from "@/helpers/api.js";
import tbaWrapper from "@/helpers/tbaWrapper";
import dataFormat from "@/helpers/dataFormat.js";
import * as file from "@/helpers/FileHelper";
import {
    GETENV,
    OLD_API,
    OLD_API_REGION_IDS,
    SETENV,
    REVISION,
} from "../constants";
import * as ga4 from "@/helpers/AnalyticsHelper";
import * as sessionRecord from "@/helpers/SessionRecordingHelper";
import router from "@/router";
import appSSO from "@/helpers/AppSSO";

var modalConfirmPromiseResolve = null;
export default {
    async apiLobby({ commit }) {
        commit("setApiData", await api.lobby());
    },
    async apiSync({ commit, state, getters, dispatch }) {
        // could detect online here. too bad iOS doesn't have navigator.online
        commit("setIsSyncing", true);
        try {
            var apiData = state.isLoggedIn
                ? // ? await api.review(state.currentRegion)
                  await api.review()
                : OLD_API
                ? dataFormat.translateOldApiInPlace(
                      await api.get_all(state.currentRegionId)
                  )
                : await api.content(state.currentRegion);
        } catch (error) {
            dispatch("popupInfoBar", {
                type: "warning",
                message: `Error contacting server`,
            });
            commit("setIsSyncing", false);
            return;
        }
        // clear stuff if region changed since last sync
        if (state.currentRegion != state.apiDataRegion) {
            dispatch("comparisonRemoveAll");
            commit("setApiDataRegion", state.currentRegion);
        }
        // this won't be necessary when it's done on login. also sync on login.
        // if (!getters.hasWires) dispatch("comparisonRemoveAll"); // should be after setting apiData if at all
        commit("setApiData", apiData);

        if (!getters.hasWires) {
            dispatch("popupInfoBar", {
                type: "warning",
                message: `${state.currentRegion} has no Wires.`,
            });
        } else {
            // todo change message
            if (["ios", "android"].includes(tbaWrapper.platform)) {
                await file.sync();
            }
        }
        commit("setIsSyncing", false);
    },
    comparisonRemoveAll({ state, commit }) {
        if (state.compareWireIds.length) {
            ga4.compare_remove_all();
            commit("comparisonRemoveAll");
        }
    },
    compareWireIdsPush({ commit, getters, dispatch }, wireId) {
        if (getters.comparisonIsFull) {
            dispatch("popupInfoBar", {
                type: "warning",
                message: "Can't add wire.  Comparison list is full.",
            });
            return;
        }
        wireId = parseInt(wireId);
        commit("compareWireIdsPush", wireId);
        commit("comparisonByIdAdd", wireId);
        dispatch("comparisonSort");
        ga4.compare_add(wireId);
    },
    compareWireIdsRemove({ commit }, wireId) {
        commit("compareWireIdsRemove", wireId);
        commit("comparisonByIdRemove", wireId);
        ga4.compare_remove(wireId);
        sessionRecord.removedComparisonWire();
    },
    comparisonSort({ state, getters }) {
        state.compareWireIds.sort((idA, idB) => {
            let a = getters.guideWiresById[idA].name;
            let b = getters.guideWiresById[idB].name;
            // prettier-ignore
            return (a == b ? 0
                : (a > b) ^ state.comparisonSortAscending ? -1 : 1
            );
        });
    },
    comparisonSortDirectionToggle({ commit, dispatch }) {
        commit("comparisonSortDirectionToggle");
        dispatch("comparisonSort");
    },
    async login({ commit, dispatch }, user) {
        commit("menuClose");
        // todo Analytics login event
        // clear comparison wires if any
        // commit("comparisonRemoveAll");
        dispatch("comparisonRemoveAll");
        // clear data and send to lobby to re-sync
        dispatch("resetApiData");

        router.push("/lobby");
        commit("setUser", user);
        commit("setIsLoggedIn", true);
        dispatch("popupInfoBar", {
            message: "You have successfully logged in",
        });
    },
    logout({ state, commit, dispatch }) {
        if (!state.isLoggedIn) return;
        commit("menuClose");
        // todo don't clear mat_numbers, templates for lobby and disclaimer
        // todo check tests for existence of apiData and update appropriately.
        dispatch("comparisonRemoveAll");
        router.push("/lobby");
        commit("setCurrentStatusViewing", "Published");
        dispatch("resetApiData");
        commit("setUser", false);
        commit("setIsLoggedIn", false);
        dispatch("popupInfoBar", {
            message: "You have been successfully logged out",
        });
    },
    modalConfirm({ commit }, opts) {
        commit("modalConfirmPopulate", opts);
        commit("modalConfirmShow");
        return new Promise((resolve) => (modalConfirmPromiseResolve = resolve));
    },
    modalConfirmResolve({ commit }, ok) {
        // called by the component
        commit("modalConfirmHide");
        modalConfirmPromiseResolve(ok);
    },
    popupInfoBar({ state, commit }, opts) {
        clearTimeout(state.popupInfoBar.timeoutId);
        commit("setPopupInfoBarMessage", opts.message);
        commit("setPopupInfoBarType", opts.type);
        commit("setPopupInfoBarIsVisible", true);
        commit(
            "setPopupInfoBarTimeoutId",
            setTimeout(() => {
                commit("setPopupInfoBarIsVisible", false);
            }, 5000)
        );
    },
    popupInfoBarClose({ state, commit }) {
        // called by component
        clearTimeout(state.popupInfoBar.timeoutId);
        commit("setPopupInfoBarIsVisible", false);
    },
    modalReviewOpen({ commit }) {
        commit("modalReviewOpen");
        commit("menuClose");
    },
    modalReviewClose({ commit }) {
        commit("modalReviewClose");
    },
    testComponentToggle({ state, commit }) {
        commit("setTestComponentIsOpen", !state.testComponentIsOpen);
    },
    setCurrentRegion({ commit, dispatch }, region) {
        dispatch("comparisonRemoveAll");
        commit("setCurrentRegion", region);
        if (OLD_API) commit("setCurrentRegionId", OLD_API_REGION_IDS[region]);
    },
    sessionRecordStart({ commit }) {
        console.log("sessionRecordStart");
        // clear all
        commit("clearSessionWiresById");
        commit("clearSessionWireIdViewCount");
        commit("clearSessionWireIdCompareCount");
        commit("clearSessionUniqueComparisonCount");
        commit("setIsRecordingSession", true);
        // start with current page
        sessionRecord.pageView();
        // old stuff. no need to record everything
        // commit("recordedSessionClear");
        // commit("recordedSessionPush", sessionRecord.start());
    },
    async sessionRecordEnd({ commit, dispatch }) {
        commit("setIsRecordingSession", false);
        if (await dispatch("confirmSendNotes")) {
            sessionRecord.openEmailClient();
        } else {
            if (await dispatch("confirmClearSession")) {
                // don't really need to do this because it's cleared on start
                commit("clearSessionWiresById");
                commit("clearSessionWireIdViewCount");
                commit("clearSessionWireIdCompareCount");
                commit("clearSessionUniqueComparisonCount");
            } else {
                commit("setIsRecordingSession", true);
            }
        }
    },
    confirmSendNotes({ dispatch }) {
        return dispatch("modalConfirm", {
            title: "SESSION COMPLETE",
            message: "Send session notes with your email client?",
            // "Who would you like to send your session notes to?",
            okButton: "OPEN EMAIL CLIENT",
            okBtnStyle: "background-color:black;",
        });
    },
    confirmClearSession({ dispatch }) {
        return dispatch("modalConfirm", {
            title: "CLEAR SESSION?",
            message: "Would you like to clear the current session or continue?",
            // "Who would you like to send your session notes to?",
            okButton: "CLEAR SESSION",
            okBtnStyle: "background-color:black;",
            cancelButton: "CONTINUE",
        });
    },
    changeEnvironment({ state, commit, dispatch }, environment) {
        // reset everything
        commit("setIsRecordingSession", false);
        if (state.isLoggedIn) this.dispatch("logout");
        else {
            router.push("/lobby");
            dispatch("comparisonRemoveAll");
            commit("setCurrentStatusViewing", "Published");
            dispatch("resetApiData");
        }
        commit("setEnvironment", environment);
        SETENV(environment);
    },
    changeIsReviewApp({ commit, dispatch }, isReview) {
        // reset everything
        commit("setIsRecordingSession", false);
        commit("menuClose");
        dispatch("comparisonRemoveAll");
        commit("setCurrentStatusViewing", "Published");
        dispatch("resetApiData");
        commit("setUser", false);
        commit("setIsLoggedIn", false);
        commit("setIsReviewApp", isReview);
        router.push("/lobby");
    },

    resetApiData({ state, commit }) {
        let keep = ({ mat_numbers, templates }) => ({
            mat_numbers,
            templates,
        });
        commit("setApiData", keep(state.apiData));
    },
    async initialiseStore({ state, commit, dispatch}) {
        let savedState = JSON.parse(localStorage.getItem("store") ?? "false");
        if (savedState && savedState.revision == REVISION) {
            tbaWrapper.log("restoring store");
            this.replaceState(Object.assign(state, savedState));
            if (state.environment) SETENV(state.environment);
            // restore current page without breaking SSO login
            if (!state.isAuthenticating) {
                // tbaWrapper.log(`restoring path ${state.currentRoute.fullPath}`);
                router.push(state.currentRoute.fullPath);
            }
            // remove pdf progress - crash recovery
            if (state.pdf.isGeneratingPdf) {
                dispatch("recoverFromCrash");
            }
            // in case of crash
            tbaWrapper.enableSleep();
        } else {
            tbaWrapper.log("factory settings");
            commit("SET_REVISION", REVISION);
            commit("setEnvironment", GETENV());
            let config = await tbaWrapper.pGetConfig();
            commit("setIsReviewApp", !!config.isPreviewApp);
        }
        commit("setLocalFileBasePath", await tbaWrapper.pLocalFileBasePath());
    },
    doSso() {
        appSSO.authenticate();
    },
};
