// cookies.js
// not sure if this is required anymore. and it's small to be in a module of its own -stan
export default {
    /**
     * This is an obnoxious method that WKWebView requires to refresh its pool of cookies.
     * @method refreshcookies
     */
    refreshCookies(callBack) {
        if (callBack) {
            this.genericCallbackHolder = callBack;
        } else {
            this.genericCallbackHolder = null;
        }
        if (this.isDesktop || this.isAndroid) {
            //setTimeout('tbaWrapperJ.refreshCookies();',1);
        } else {
            window.webkit.messageHandlers.refreshcookies.postMessage("testing");
            // window.location = "begintransaction://foo/bar?sid="+Math.random();
        }
    },
};
