import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import { dragscrollNext } from "vue-dragscroll";
import "./assets/scss/index.scss";
import VueGtag from "vue-gtag";
import tbaWrapper from "./helpers/tbaWrapper";
import { GTAG } from "./constants";
import globalMixin from "@/mixins/GlobalMixin";

window.router = router; // webAuthAndroidReturnHack

const isWeb = !tbaWrapper.platform;
// root instance
const app = createApp(App)
    .mixin(globalMixin)
    .use(store)
    .use(router)
    // web not to show scrollbar and screw up dimensions
    .directive("dragscroll", dragscrollNext);

// Register globals in app and store
app.config.globalProperties.$isWeb = !tbaWrapper.platform;
// store.$conf = conf; // eg.

if (isWeb) {
    app.use(VueGtag, { config: { id: GTAG.measurement_id } });
}

// root component
const vm = app.mount("#app");

// for console debug can also use $vm0 https://stackoverflow.com/questions/67193890/vue3-access-component-data-attribute-from-console
window.vm = vm;
export default vm;
