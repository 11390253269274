<template>
    <div class="resources-view">
        <header>
            <h1>RESOURCES</h1>
            <div
                class="text"
                v-html="intro"
                @click.prevent="$root.linkNative($event)"
            ></div>
        </header>
        <main class="screenshot-expand scroll-y" v-dragscroll="$isWeb">
            <div class="resources-list">
                <ResourcesListItem
                    v-for="resource in getViewingResources"
                    :key="resource.id"
                    :resource="resource"
                    @view-pdf="viewPdf"
                />
            </div>
            <div
                class="footnote"
                v-html="bottomContent"
                @click.prevent="$root.linkNative($event)"
            ></div>
        </main>
        <ModalPdfView
            :pdfSource="pdfBytes"
            @close="pdfBytes = null"
            :name="fileName"
        />
    </div>
</template>

<script>
import ResourcesListItem from "@/components/ResourcesListItem.vue";
import ModalPdfView from "@/components/ModalPdfView.vue";
import { mapState, mapMutations, mapGetters } from "vuex";
import * as file from "@/helpers/FileHelper";
import tbaWrapper from "@/helpers/tbaWrapper";

export default {
    components: { ResourcesListItem, ModalPdfView },
    data() {
        return {
            pdfBytes: null,
            fileName: null,
        };
    },
    computed: {
        ...mapState({
            resources: (state) => state.apiData.resources,
        }),
        ...mapGetters([
            "getFooter",
            "getPageTemplate",
            "getViewingResources",
            "getFooter",
        ]),
        template() {
            return this.getPageTemplate?.content;
        },
        intro() {
            return this.template?.intro ?? "";
        },
        bottomContent() {
            let d = (this.template?.disclaimer ?? "").trim();
            let f = this.getFooter.trim();
            return [...(d ? [d] : []), ...(f ? [f] : [])].join("<br /><br />");
        },
    },
    methods: {
        ...mapMutations(["setCurrentSection"]),
        async viewPdf(resource) {
            let apiFilename = resource.filename;
            this.fileName = `${resource.name}.pdf`;
            console.log("fileName", this.fileName);
            if (window.tbaWrapper.isAndroid) {
                this.pdfBytes = await tbaWrapper.pFileGetContents(
                    file.relativeBasePath(apiFilename)
                );
            } else {
                console.log(
                    "fetching non android...",
                    apiFilename,
                    file.src(apiFilename)
                );
                let data = await fetch(file.src(apiFilename)).then((res) =>
                    res.arrayBuffer()
                );
                console.log(
                    "typeof data",
                    typeof data,
                    data.constructor.name,
                    data.byteLength
                );
                this.pdfBytes = new Uint8Array(data);
                console.log(
                    "typeof this.pdfBytes",
                    typeof this.pdfBytes,
                    this.pdfBytes.constructor.name,
                    this.pdfBytes.byteLength
                );
                // const response = await fetch(file.src(apiFilename));
                // this.pdfBytes = new Uint8Array(await response.arrayBuffer());
            }
            // todo page_view
        },
    },
    mounted() {
        this.setCurrentSection("resources");
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/base/variables";
@import "@/assets/scss/base/fonts"; // is this needed? todo find out
.resources-view {
    height: 100%;
    display: flex;
    flex-direction: column;
    header {
        padding: 0 vw(50px);
        border-bottom: vw(1px) solid $table-lines;
        h1 {
            height: vw(34px);
            font: normal normal bold vw(24px) BrandonGrotesque;
            line-height: vw(34px);
            margin: vw(35px) 0 vw(12px);
        }
        .text {
            font: normal normal normal vw(16px) MercuryTextG1;
            line-height: vw(20px);
            width: vw(841px);
            margin-bottom: vw(20px);
        }
    }
    main {
        flex: 1;
        padding: vw(20px) 0;
        .resources-list {
            margin: 0 vw(50px) vw(20px) vw(48px);
            display: flex;
            flex-wrap: wrap;
            @include gap-compat-n(2, vw(10px));
        }
    }
}
</style>
