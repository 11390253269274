<template>
    <div
        id="disclaimer-view"
        class="landing screenshot-expand scroll-y"
        v-dragscroll="$isWeb"
    >
        <ScrollForMore forId="disclaimer-view" />
        <div class="landing-main">
            <div class="heading">DISCLAIMERS</div>
            <div class="hr"></div>
            <div
                v-if="template"
                class="text"
                v-html="template.disclaimer"
                @click.prevent="$root.linkNative($event)"
            ></div>
            <div v-else class="text">
                <b>CAUTION:</b> These products are intended for use by or under
                the direction of a physician.<br />
                Prior to use, reference the Instructions for Use, inside the
                product carton (when available) or<br />
                at www.vascular.eifu.abbott or at
                <u>medical.abbott/manuals</u> for more detailed information
                on<br />
                Indications, Contraindications, Warnings, Precautions and
                Adverse Events.<br />
                <br />
                Illustrations are artist’s representations only and should not
                be considered as engineering<br />
                drawings or photographs.<br />
                <br />
                ™ Indicates a trademark of the Abbott Group of Companies.<br />
                ‡ Indicates a third-party trademark, which is property of its
                respective owner.<br />
                <br />
                <b><u>www.cardiovascular.abbott</u></b
                ><br />
                ©2022 Abbott. All rights reserved.
            </div>
            <div class="lightgrey-box">
                <input type="checkbox" v-model="isCertified" id="certify" />
                <label for="certify">
                    By checking this box I certify that I am an authorized
                    user<br />
                    (e.g. “Sales &amp; Marketing”) of this application
                </label>
                <button class="sync" :disabled="!isCertified" @click="sync">
                    SYNC &amp; ENTER
                </button>
                <button class="back" @click="$router.push('/lobby')">
                    BACK
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import ScrollForMore from "@/components/ScrollForMore.vue";
import { mapState, mapActions, mapGetters } from "vuex";
// import { page_view } from "@/helpers/AnalyticsHelper";
export default {
    components: { ScrollForMore },
    name: "DisclaimerView",
    data() {
        return {
            isCertified: false,
        };
    },
    methods: {
        sync: async function () {
            await this.apiSync();
            if (this.hasWires) this.$router.push("/");
        },
        ...mapActions(["apiSync"]),
    },
    computed: {
        ...mapState(["options"]),
        ...mapGetters(["hasWires", "getPageTemplate"]),
        template() {
            return this.getPageTemplate?.content;
        },
    },
    // mounted: page_view,
};
</script>

<style lang="scss" scoped>
.mytest {
    border: vw(5px) solid red;
    width: 100%;
    height: 100%;
}
</style>
