import { MAT_CATEGORY_ORDER, COMPARISON_PROPERTIES } from "@/constants";
import tbaWrapper from "@/helpers/tbaWrapper";
export default {
    state: {
        // Review Options > Generate pdf Tab > Status
        currentStatusPdf: "Published",
        isGeneratingPdf: false,
        selectedMatIdsPdf: [],
        comparisonPageNum: 0,
        comparisonProperties: COMPARISON_PROPERTIES,
        currentPdfMatIndex: undefined, // set while generating pdf
        savedViewingStatus: undefined, // set while generating pdf
        currentPathIndex: undefined, // set while generating pdf
        hasTemporaryComparisonIds: false, // might be ''
    },
    getters: {
        getPdfMats(state, getters) {
            var mats = state.selectedMatIdsPdf.map(
                (id) => getters.getMatById[id]
            );
            // pdf same order as on screen - except all "For Review" first
            var ordered = [];
            for (const status of ["For Review", "Published"]) {
                for (const category of MAT_CATEGORY_ORDER) {
                    ordered = [
                        ...ordered,
                        ...mats.filter(
                            (m) => m.category == category && m.status == status
                        ),
                    ];
                }
            }
            return ordered;
        },
        getComparisonPdfMatsSorted(state, getters, rootState) {
            // "For Review" then "Published" if both exist
            // current region
            return (
                rootState.apiData?.mat_numbers
                    ?.filter(
                        (m) =>
                            m.category == "Compare PDF" &&
                            m.region == rootState.currentRegion
                    )
                    .sort((a, b) => (a.status <= b.status ? -1 : 1)) ?? []
            );
        },
        getComparisonPdfMat(state, getters, rootState) {
            // based on currentStatusViewing
            // For Review returns Published if not exist
            return getters.getComparisonPdfMatsSorted
                .filter(
                    rootState.currentStatusViewing == "For Review"
                        ? () => true // falls back to Published
                        : (m) => m.status == rootState.currentStatusViewing
                )
                .at(0);
        },
        getComparisonPdfTemplate(state, getters, rootState) {
            return rootState.apiData?.templates
                ?.filter((t) => t.mat_id == getters.getComparisonPdfMat?.id)
                ?.at(0);
        },
        getIsLastPdfComparisonProperty(state, getters, rootState) {
            return (
                rootState.comparisonProperty ==
                state.comparisonProperties.at(-1)
            );
        },
    },
    mutations: {
        setIsGeneratingPdf: (state, is) => (state.isGeneratingPdf = is),
        setSelectedMatIdsPdf: (state, selected) =>
            (state.selectedMatIdsPdf = selected),
        setCurrentStatusPdf: (state, status) =>
            (state.currentStatusPdf = status),
        setComparisonPageNum: (state, pageNum) =>
            (state.comparisonPageNum = pageNum),
        setPdfComparisonProperties: (state, properties) =>
            (state.comparisonProperties = properties),
        setCurrentPdfMatIndex: (state, i) => (state.currentPdfMatIndex = i),
        decrementCurrentPdfMatIndex: (state) =>
            (state.currentPdfMatIndex = state.currentPdfMatIndex
                ? state.currentPdfMatIndex - 1
                : undefined),
        setCurrentPathIndex: (state, i) => (state.currentPathIndex = i),
        decrementCurrentPathIndex: (state) =>
            (state.currentPathIndex = state.currentPathIndex
                ? state.currentPathIndex - 1
                : undefined),
        setSavedViewingStatus: (state, status) =>
            (state.savedViewingStatus = status),
        setHasTemporaryComparisonIds: (state, has) =>
            (state.hasTemporaryComparisonIds = has),
    },
    actions: {
        generatePdf({ commit }) {
            // lame trigger. component watches this and runs
            // could move the function here and return data with promise
            // it might be bad form though..
            // https://stackoverflow.com/questions/40165766/returning-promises-from-vuex-actions#:~:text=My%20views%20on%20the%20Vuex%20cycle%20of%20uni%2Ddirectional%20data%20flow%3A
            // we already return stuff with modelConfirm action
            // also, we don't want to store the pdf file in the state.
            commit("setIsGeneratingPdf", true);
        },
        generatePdfDone({ commit }) {
            // redundant
            commit("setIsGeneratingPdf", false);
        },
        recoverFromCrash({ commit }) {
            tbaWrapper.log("recovering from crash.");
            commit("setIsGeneratingPdf", false);
            // todo decrement indexes and generate pdf
            commit("decrementCurrentPdfMatIndex");
            commit("decrementCurrentPathIndex");
            setTimeout(() => commit("setIsGeneratingPdf", true), 2000);
        },
    },
};
