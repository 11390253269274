<template>
      <div class="screens-nav">
        <h4>Screens</h4>
        <nav>
            <router-link to="/lobby">Lobby</router-link>
            <router-link to="/disclaimer">Disclaimer</router-link>
            <router-link to="/">Home</router-link>
            <router-link to="/product-types">Products(types)</router-link>
            <router-link to="/products/coronary/Abbott">Products - Coronary/Abbott</router-link>
            <router-link to="/products/coronary/Other">Products - Coronary/Other</router-link>
            <router-link to="/products/peripheral/Abbott">Products - Peripheral/Abbott</router-link>
            <router-link to="/products/peripheral/Other">Products - Peripheral/Other</router-link>
            <router-link to="/product/318">Product</router-link>
            <router-link to="/graph/318">Graph</router-link>
            <router-link to="/compare/supportLevel">Compare - Support Level</router-link>
            <router-link to="/compare/penetrationPower">Compare - Penetration Power</router-link>
            <router-link to="/compare/tipLoad">Compare - tipLoad</router-link>
            <router-link to="/compare-big/supportLevel">Compare Big - Support Level</router-link>
            <router-link to="/compare-big/penetrationPower">Compare Big - Penetration Power</router-link>
            <router-link to="/compare-big/tipLoad">Compare Big - tipLoad</router-link>
            <router-link to="/resources">Resources</router-link>
            <router-link to="/compare-pdf-dev/supportLevel/1">DEV Compare PDF page 1</router-link>
            <router-link to="/compare-pdf-dev/supportLevel/2">DEV Compare PDF Support Level</router-link>
            <router-link to="/compare-pdf-dev/penetrationPower/2">DEV Compare PDF Penetration Power</router-link>
            <router-link to="/compare-pdf-dev/tipLoad/2">DEV Compare PDF Tip Load</router-link>
            <router-link to="/compare-pdf-dev/supportLevel/99">DEV Compare PDF FONT TEST</router-link>
        </nav>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.screens-nav{
    // position:absolute;
    // top:0;
    // right:0;
    // background-color:rgba(255,255,255,0.7);
    padding: 10px;
}
nav {
    text-align: left;
    padding: 2px 10px;
    a {
        font-weight: bold;
        color: #2c3e50;
        display: block;
        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>