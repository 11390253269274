<template>
    <div class="bottom-bar">
        <div class="logo-abbott" @click="$router.push('/')"></div>
        <template v-if="hasVW && !isLobby && !isDisclaimer">
            <div
                :class="btnClassProducts"
                @click="$router.push('/product-types')"
            >
                GUIDE WIRES
            </div>
            <div
                :class="btnClassCompare"
                @click="$router.push(`/compare/${comparisonProperty}`)"
            >
                COMPARE
                <span v-if="compareCount" class="badge">{{
                    compareCount
                }}</span>
            </div>
            <div :class="btnClassResources" @click="$router.push('/resources')">
                RESOURCES
            </div>
        </template>
        <div class="mat-status">
            <div class="mat-num">{{ getCurrentMatNum }}</div>
            <div
                v-if="isReviewApp && !isGeneratingPdf"
                class="status"
                :class="
                    getCurrentMatStatus.replace(/\s+/g, '-').toLowerCase() ?? ''
                "
            >
                {{ getCurrentMatStatus }}
            </div>
        </div>
        <div class="icon-hamburger" @click="menuToggle"></div>
    </div>
    <ModalMenu />
    <ModalReviewOptions v-show="isReviewOpen" />
</template>

<script>
import ModalMenu from "./ModalMenu.vue";
import { mapState, mapGetters, mapMutations } from "vuex";
import ModalReviewOptions from "./ModalReviewOptions.vue";
export default {
    components: { ModalMenu, ModalReviewOptions },
    name: "BottomBar",
    data() {
        return { show: false };
    },
    methods: {
        // surprised this worked while not computed
        // btnClass(section) {
        //     var c = {};
        //     c[section] = true;
        //     c["active"] = this.currentSection == section;
        //     return c;
        // },
        ...mapMutations(["menuToggle"]),
    },
    computed: {
        ...mapState([
            "isMenuOpen",
            "isReviewOpen",
            "currentSection",
            "comparisonProperty",
            "isReviewApp",
            "currentRoute",
        ]),
        ...mapState({
            isGeneratingPdf: (state) => state.pdf.isGeneratingPdf,
        }),
        ...mapGetters([
            "compareCount",
            "getCurrentMatNum",
            "getCurrentMatStatus",
            "hasViewingWires",
            "guideWiresById",
        ]),
        // has Viewing Wires
        hasVW() {
            // kludge for getter
            return Object.keys(this.guideWiresById ?? {}).length != 0;
        },
        isLobby(){
            return this.currentRoute.fullPath == "/lobby";
        },
        isDisclaimer(){
            return this.currentRoute.fullPath == "/disclaimer";
        },
        btnClassProducts() {
            return {
                products: true,
                active: this.currentSection == "products",
                fadein: true,
                show: this.hasVW,
                button: true,
            };
        },
        btnClassCompare() {
            return {
                compare: true,
                active: this.currentSection == "compare",
                fadein: true,
                show: this.hasVW,
                button: true,
            };
        },
        btnClassResources() {
            return {
                resources: true,
                active: this.currentSection == "resources",
                fadein: true,
                show: this.hasVW,
                button: true,
            };
        },
    },
};
</script>
