// geofencing.js
export default {
    createGeofenceCallBack: null,
    getGeofenceListCallBack: null,
    /**
     * Function to setup 1 or more geoFence notifications.  Note - as documented here (re: registering) https://developer.android.com/training/location/geofencing#java if the app is re-installed, or the device is rebooted; android geofences need to get added again.  In android; adding the same geofence again will just overwrite the previous one
     * @method createGeofenceNotifications
     * @param data {Array} data - (required), an array containing objects with the following keys (lat, lng, radius, title, message) where lat &amp; lng &amp; radius are of type Number, and title &amp; message are of type text and control the text displayed in the notification popup message.
     * @param callBack {Function} callBack - (required), the call back function to execute; should only accept one argument which will be a string containing the ID for the notification that was created
     */
    createGeofenceNotifications(data, callBack) {
        this.createGeofenceCallBack = callBack;
        if (this.isiOS) {
            window.webkit.messageHandlers.createGeofenceNotifications.postMessage(
                JSON.stringify(data)
            );
        } else if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.createGeofenceNotifications(
                this.Base64.encode(this.array2json(data))
            );
        }
    },

    /**
     * Function to dispatch call back
     */
    createdGeofenceNotification(id) {
        this.createGeofenceCallBack(id);
        this.createGeofenceCallBack = null;
    },

    /*
     * To clear all geofence items added previously
     * callback: function(geofenceId:String)
     */
    deleteGeofence(data, callBack) {
        this.deletedGeofenceCallBack = callBack;
        if (this.isiOS) {
            window.webkit.messageHandlers.deleteGeofence.postMessage(
                JSON.stringify(data)
            );
        } else if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.deleteGeofence(
                this.Base64.encode(this.array2json(data))
            );
        }
    },

    /**
     * Function to dispatch call back
     */
    deletedGeofence(id) {
        this.deletedGeofenceCallBack(id);
        this.deletedGeofenceCallBack = null;
    },

    /*
     * To clear all geofence items added previously
     * callback: function(message:String)
     */
    clearGeofenceList(callBack) {
        this.clearedGeofenceListCallBack = callBack;
        if (this.isiOS) {
            window.webkit.messageHandlers.clearGeofenceList.postMessage("");
        } else if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.clearGeofenceList();
        }
    },

    /**
     * Function to dispatch call back
     */
    clearedGeofenceList(id) {
        this.clearedGeofenceListCallBack(id);
        this.clearedGeofenceListCallBack = null;
    },

    /**
     * Function to get the current geofences loaded into the app
     * @method getGeofenceList
     * @param callBack {Function} callBack - (required), the call back function to execute; should only accept one argument which will be a js array containing objects with keys {lat, lng, message, radius, title}
     */
    getGeofenceList(callBack) {
        this.getGeofenceListCallBack = callBack;
        if (this.isiOS) {
            window.webkit.messageHandlers.getGeofenceList.postMessage("");
        } else if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            tbaWrapperJ.getGeofenceList();
        }
    },

    /**
     * Function to dispatch call back
     */
    gotGeofenceList(ja) {
        this.log(ja);
        this.getGeofenceListCallBack(JSON.parse(ja));
        this.getGeofenceListCallBack = null;
    },

    /**
     * Converts string from display page to format expected by createGeofenceNotification [{key: value},{},{}]
     */
    prepareGeoObject(data) {
        var obj = {};

        for (var i = 0; i < data.length; i++) {
            var split = data[i].split(":");
            var value = isNaN(parseFloat(split[1]))
                ? split[1].trim()
                : parseFloat(split[1]);
            obj[split[0].trim()] = value;
        }

        return Array.of(obj);
    },
};
